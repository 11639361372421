<section class="content home">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Tableau de bord
                    <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a routerLink="/backoffice"><i class="zmdi zmdi-home"></i>
                        Puéricultrice </a>
                    </li>
                    <li class="breadcrumb-item active">Tableau de bord</li>
                </ul>
            </div>
        </div>
    </div>
    <main class="py-6 bg-surface-secondary">
        <div class="container-fluid">
            <div class="row g-6 mb-6">
                <!-- <div class="col-xl-4 col-md-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2" style="color: #f9656b !important; font-size: 18px;font-family: Nunito, sans-serif;font-weight: 500">
                                        Classes
                                    </span>
                                    <br>
                                    <span class="h3 font-bold mb-0" style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600">
                                        {{nbreClasses}}
                                    </span>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-4 col-md-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2" style="color: #f9656b !important; font-size: 18px;font-family: Nunito, sans-serif;font-weight: 500">
                                        Activités
                                    </span>
                                    <br>
                                    <span *ngIf="nbreClasses > 0" class="h3 font-bold mb-0" style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600">
                                        {{nbreMatieres}}
                                    </span>
                                    <span *ngIf="nbreClasses == 0" class="h3 font-bold mb-0" style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600">
                                       0
                                    </span>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-4 col-md-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2" style="color: #f9656b !important; font-size: 18px;font-family: Nunito, sans-serif;font-weight: 500">
                                        Cours
                                    </span>
                                    <br>
                                    <span class="h3 font-bold mb-0" style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600">
                                        {{nbreCours}}
                                    </span>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </main>

</section>

