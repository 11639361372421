import { Component, OnInit, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
declare var jQuery: any;

import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user.model';
import { Role } from '../_models/role';
import { TokenStorageService } from '../_services/token-storage.service';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  user:User;
  error :boolean = false;
  eroorMessage :string;
  
  private currentUserSubject_stay_active: BehaviorSubject<String>;
  public currentUser_stay_active: Observable<String>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public iziToast: Ng2IzitoastService,
    private tokenStorage :TokenStorageService,
  ) {
    this.currentUserSubject_stay_active = new BehaviorSubject(
      JSON.parse(localStorage.getItem('active_pwd_session'))
    );
    this.currentUser_stay_active =
      this.currentUserSubject_stay_active.asObservable();
  }



  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      isChecked: [true],
       
    });

    
    this.user=this.loginForm.value
  }

  get f() {
    return this.loginForm.controls;
  }

  onSignIn() {
    let r,t,status,id;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService
      .login(this.f.email.value, this.f.password.value)
      .subscribe(
        (data) => {
         
          setitem(data)
         
          r=data[0].role.type;
          status =data[0].status;
          if(r == "SuperAdmin" )
          {this.router.navigate(['/Super-Admin']);
          localStorage.setItem('user',data[0]._id)
          }
          
         
            if(r == "Enseignant" )
            {
              if(status == true)
              {
              this.router.navigate(['/puericulteur']);
              this.tokenStorage.saveUser(data);
              localStorage.setItem('user',data[0]._id)
             
              }
            }


            if(r == "Animateur")
            {
              if(status == true)
              {
              this.router.navigate(['/puericulteur']);
              this.tokenStorage.saveUser(data);
              localStorage.setItem('user',data[0]._id)
            
              }
            }

            if(r == "Etudiant" )
            {
              if(status == true)
              {
              this.router.navigate(['/Étudiant',data[0].classe]);
              this.tokenStorage.saveUser(data);
              localStorage.setItem('user',data[0]._id)
             
              }
            }

            if(r == "Directeur" )
            {
              if(status == true)
              {
              this.router.navigate(['/directeur']);
              this.tokenStorage.saveUser(data);
              localStorage.setItem('user',data[0]._id)
              
              }
            }

            if(r == "Parent" )
            {
              if(status == true)
              {
              this.router.navigate(['/parent']);
              this.tokenStorage.saveUser(data);
              localStorage.setItem('user',data[0]._id)
             
              }
            }
          
        },
    
        (error) => {
          
          this.loading = false;
          
          this.error=true
          this.eroorMessage=error.error
          this.iziToast.show({
            message:"Merci de bien vérifier vos informations.",
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
        }
      );
      async function setitem(data) {
          await localStorage.setItem('token',data[1])
      }
        
      }
      forget()
      {
        this.router.navigate(['/réinitialisation-motDePasse']);
      }
  }

