import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule ,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Enfant } from 'src/app/_models/enfant';
import { ClasseService } from 'src/app/_services/classe.service';
import { EnfantService } from 'src/app/_services/enfant.service';
import { ParentService } from 'src/app/_services/parent.service';
import * as  uuid from 'uuid';

@Component({
  selector: 'app-add-enfant',
  templateUrl: './add-enfant.component.html',
  styleUrls: ['./add-enfant.component.scss']
})
export class AddEnfantComponent implements OnInit {

  enfant :Enfant =new Enfant();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres : any;
  show;
  classes: any;
  parents;
  parentNames: { firstName: string, lastName: string }[];
  myUUID = uuid.v4();
  listVacines =["vaccin 1" ,"vaccin 2","vaccin 3" ,"vaccin 4"]

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private enfantService: EnfantService,
    private classeService : ClasseService,
    private parentService :ParentService,
    public iziToast: Ng2IzitoastService,
    //private matiereService:MatiereService,
  ) { 
    //this.enseignant.matiere = new Matiere();
  }

  ngOnInit(): void {
 
    this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
     
      //dateNaissance: ['', [Validators.required]],
      dateNaissance: ['', [Validators.required]],
      numero: ['', Validators.required],
      rue: ['', Validators.required],
      ville: ['', Validators.required],
      codePostal: ['', Validators.required],
      // classe:  ['', Validators.required],
      parent:  ['', Validators.required],
      key :[],
      vaccines : [''],
    });
    this.getclasses();
    this.getParents();
  }

  getclasses(){
      this.classeService.getClasses().subscribe(e=>{
        this.classes=e;
        //this.enseignant.matiere.id = e[0]._id
      
            })
    }

    getParents(){
        this.parentService.getParents().subscribe(e=>{
          this.parents=e;
              })
      }
  get fval_2() {
    return this.registerForm.controls;
  }


  saveEnfant() {
    this.submitted = true;
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.registerForm.value.key = this.myUUID
    this.enfantService.createEnfant(this.registerForm.value).subscribe(
      (data) => {
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        
      this.goToList();
      },
     
    );
  }
  goToList(){
    this.router.navigate(['/directeur/enfants'])
    }
    onSubmit(){
      this.saveEnfant();
    }
    }
    
