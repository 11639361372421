import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CommunicationService } from 'src/app/_services/communication.service';
import { EnfantService } from 'src/app/_services/enfant.service';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';
import { StatementService } from 'src/app/_services/statement.service';
import { PopupAddChildrenStatmentComponent } from 'src/app/admin/popup/popup-add-children-statment/popup-add-children-statment.component';
import { PopupAddPhotoChildrenComponent } from 'src/app/admin/popup/popup-add-photo-children/popup-add-photo-children.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-enfant',
  templateUrl: './details-enfant.component.html',
  styleUrls: ['./details-enfant.component.scss']
})
export class DetailsEnfantComponent implements OnInit {
  idChildren: any;
  children
  statements: any;
  photosChildrens :any
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/photosPicutresChildren/';
  BaseUrl_profilephoto = environment.baseUrl.split('/api')[0] + '/media/childProfilePhoto/';

  page: number = 1;
  count: number = 0;
  tableSize: number = 4;
  tableSizes: any = [3, 6, 9, 12];
  dataChildren: any;
  selectedFile: any;
  imageUrl: any;
  imageUrlReadrer
  fileUploaded: any;
  length =0
  registerForm: FormGroup;

  dateStat ;

  private updateDataSubscription: any;


//
submitted = false;

naps = ["1h","1h 30m","2h","2h 30m","3h","3h 30m","4h","4h 30m","5h"]
temperatures = ["36","36.5","37","37.5","38","38.5","39","39.5","40","40.5","41"]
events =["Fête des anniversaires","Spectacle de talents","Musique et animation"]
meals =["Bulgur,Légumes et Poisson","P.D.T,Légumes","RIZ,Légumes et Boeuf","Bulgur,Légumes et Oeufs"]
snacks =["Fruits du jour","Yaourt fruits","Tartine","Galette"]
numbreSaltSaisi= "1"
  closeResult: string;
//
  constructor(private router :Router,private enfantService :EnfantService,
    private statementService :StatementService,
    private profilEnfantService :ProfilEnfantService,
    private modalService: NgbModal,
    public iziToast: Ng2IzitoastService,
    private communicationService: CommunicationService,
    private formBuilder: FormBuilder,



    ) { }

  ngOnInit(): void {
    this.idChildren =this.router.url.split('/')[4]

    this.getEnfantById();
    this.getStatemntsByChildren();
    this.getPhotosChildrens();
  this.communicationService.updateData$.subscribe(() => {
      // Mettre à jour les données du composant parent ici
      this.getEnfantById();
      this.getStatemntsByChildren();
      this.getPhotosChildrens();
    });
    this.registerForm = this.formBuilder.group({
      arrivingTime : ['', Validators.required],
      departureTime : ['', Validators.required],
      meal: ['', Validators.required],
      snack : ['', Validators.required],
      salt: [false],
      nbreSalt : [],
      event: ['', Validators.required],
      temperature: ['',Validators.required],
      note: ['',],
      nap: ['', Validators.required],
      enfant :['']
  },);
  }
  

  get f() { return this.registerForm.controls; }
  // ngOnDestroy() {
  //   // Assurez-vous de vous désabonner pour éviter les fuites de mémoire
  //   this.updateDataSubscription.unsubscribe();
  // }
  getEnfantById(){
    this.enfantService.getEnfantById(this.idChildren).subscribe(data=>{
      this.children=data;

    })
  }

  getStatemntsByChildren()
  {
    this.statementService.getStatemenentByChildren(this.idChildren).subscribe(e=>{
      this.statements = e 

       })     
  }

  getPhotosChildrens(){
      
    this.profilEnfantService.getProfils(this.idChildren).subscribe(e=>{
    
       this.photosChildrens=e

       
          })   
  }
  addPictureChildren(){
    let modalRef
    modalRef =this.modalService.open(PopupAddPhotoChildrenComponent);
    modalRef.componentInstance.childrenPhoto= this.idChildren;
    }
  
  addChildrenStatment(){
    let modalRef
    modalRef =this.modalService.open(PopupAddChildrenStatmentComponent);
    modalRef.componentInstance.childrenTOStameent= this.idChildren;
    }
    getLustStatement(){
      let currentDate = new Date().toISOString().split('T')[0];
      this.statementService.getLastStatementChildrenn(this.idChildren).subscribe(
        (data) => {
        if(data){
        var dateStatementActual = new Date(data.action.date).toISOString().split('T')[0]
        }
        if (currentDate != dateStatementActual || !data) {
            this.addChildrenStatment()
        } else {

            this.iziToast.show({
              message:"Vous avez ajouté un compte rendu pour cette date.",
              messageColor:'#800f2f',
              titleColor:'#800f2f',
              progressBarColor:'#c9184a',
              icon:'bi-exclamation-diamond',	
              imageWidth:45,
              position:'topRight',
              timeout:5000,
              backgroundColor:'#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              //overlay:true,
              overlayClose:true,	
             });
        }
        
        })
    }

    onTableDataChange(event: any) {
      this.page = event;
      this.statements = this.statements
      localStorage.setItem("lastPageBenefitVisited",this.page.toString())
    }
    onTableSizeChange(event: any): void {
      this.tableSize = event.target.value;
      this.page = 1;
      this.getStatemntsByChildren()
    }
    getDataStatement(children) {
      this.dataChildren = children;

      const dateObj = new Date(this.dataChildren.action.date);

// Extraction des composantes de la date
        const jour = dateObj.getUTCDate();
        const mois = dateObj.getUTCMonth() + 1; // Les mois sont indexés à partir de zéro, donc on ajoute 1
        const annee = dateObj.getUTCFullYear();

        // Formatage de la date au format "jj/mm/aaaa"
        const dateFormatee = `${jour.toString().padStart(2, '0')}/${mois.toString().padStart(2, '0')}/${annee}`;
       

        this.dateStat = dateFormatee

    }

    onFileSelected(event: any): void {
      const fileInput2 = event.target;
      const file = fileInput2.files[0];
      this.fileUploaded = file
  
      if (file) {
        this.readAndDisplayImage(file);
        this.editImage()
      }
    }
  
    readAndDisplayImage(file: File): void {
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        this.imageUrlReadrer = e.target.result;
      };
  
      reader.readAsDataURL(file);
    }
  
    uploadFile() {
      // Envoyez ici le fichier au serveur si nécessaire

    }

    editImage(){
      {
        const imagBlob =this.fileUploaded
        const form =new FormData();
        form.set('image',imagBlob);
    
        this.enfantService.addProfilePhoto(form,this.children.key).subscribe(data=>{   
          this.getEnfantById()
         })
        }

    }
    onSubmit(data){
      this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    if (!this.registerForm.value.salt) {
        this.registerForm.value.salt = false;
    }
    if(this.registerForm.value.salt){
      this.registerForm.value.nbreSalt = this.numbreSaltSaisi
    }
    
    this.registerForm.value.enfant = data.enfant

      this.statementService.EditStatement(data._id,this.registerForm.value).subscribe(data=>{   
        this.getStatemntsByChildren()
        this.modalService.dismissAll()
        this.iziToast.show({
          message: "Modification d'un compte rendu avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
       })
      }
    
    onReset(){

    }

    open(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

}
