<section class="content ecommerce-page">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2 class="text-capitalize" *ngIf="children" style="color: #5dc4be !important">
                    Détails de l'enfant : <strong style="color: #9d9d9d;">{{children.nom}} {{children.prenom}}</strong>
                    <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a routerLink="/Enseignant"><i class="zmdi zmdi-home"></i> Puéricultrice
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/Enseignant/Liste-enfants"> Liste des enfants </a>
                    </li>
                    <li class="breadcrumb-item active">Détails enfant</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="text-center d-flex flex-column align-items-center">
                <img *ngIf="children && children.photoProfile" style="width: 200px !important;height: 200px !important;"
                src="{{ BaseUrl_profilephoto }}{{ children.key }}/{{ children.photoProfile}}" 
                alt="Profile Photo" class="card-img-top rounded-circle mx-auto mt-3" />
            </div>
        <div class="card-header bg-transparent" style="border-bottom: none !important; color: #5dc4be">
            <h3 class="mb-0">
                <i class="bi bi-info-circle-fill"></i> Informations générales
            </h3>
        </div>
        <br />
        <div *ngIf="children" class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
            <div class="row">
                <div class="col-md-4">
                    <strong class="pr-1 title-info " style="color: #5dc4be;">Nom :</strong>
                    <p class="mb-3 text-capitalize">{{children.nom}}</p>
                </div>
                <div class="col-md-4">
                    <strong class="pr-1 title-info" style="color: #5dc4be;">Prénom :</strong>
                    <p class="mb-3 text-capitalize">{{children.prenom}}</p>
                </div>
                <div class="col-md-4">
                    <strong class="pr-1 title-info" style="color: #5dc4be;">Date de naissance :</strong>
                    <p class="mb-3">{{children.dateNaissance| date:'dd/MM/yyyy'}}</p>
                </div>
                
            </div>
        </div>
        <br />
        <div class="card-header bg-transparent" style="border-bottom: none !important; color: #5dc4be">
            <div class="row">
                <div class="col-8">
                    <h3 class="mb-0"><i class="bi bi-camera-fill"></i> Listes des photos</h3>
                </div>
            </div>
        </div>

        <br />

        <div *ngIf="photosChildrens && photosChildrens.length > 0" class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
            <div id="imageCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div *ngFor="let photo of photosChildrens; let first = first" class="carousel-item text-center" [class.active]="first">
                        <img src="{{ BaseUrl }}/{{ photo.filename }}" class="d-block mx-auto img-fluid" alt="Image 1">
                    </div>
                </div>
                <a class="carousel-control-prev" href="#imageCarousel" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#imageCarousel" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <div *ngIf="photosChildrens && photosChildrens.length == 0" class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91 ;text-align: center">
            <p style="padding-bottom: 45px; padding-top: 35px ">
                Aucune photo n'est trouvé
            </p>
        </div>

        <br />
        <div class="card-header bg-transparent" style="border-bottom: none !important; color: #5dc4be">
            <div class="row">
                <div class="col-8">
                    <h3 class="mb-0"><i class="bi bi-receipt"></i> Comptes Rendu </h3>
                </div>
            </div>
        </div>
        <br />
        <div *ngIf="statements && statements.length> 0" class="card-body"
            style="background-color: #d0f0ee91; border: #d0f0ee91">
            <div class="row">
                <!-- Première carte -->
                <div *ngFor="let stat of statements | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };let k =index" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                    <div class="card" style="border: #fff">
                        <div class="card-body text-center">
                            <h5 class="card-title" style="color: #5dc4be;">Compte rendu {{stat.action.date |
                                date:'dd/MM/yyyy'}}</h5>
                            <div class="d-flex flex-column align-items-center">
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <strong style="color: #5dc4be;"><i class="bi bi-clock"></i> Heure
                                            d'arrivée:</strong>
                                            <p class="mb-0 text-capitalize">{{stat.arrivingTime}}</p>
                                    </div>
                                    <div>
                                        <strong style="color: #5dc4be;"><i class="bi bi-clock"></i> Heure de
                                            départ:</strong>
                                            <p class="mb-0 text-capitalize">{{stat.departureTime}}</p>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="d-flex" >
                                <strong class="mr-2" style="color: #5dc4be;"><i class="bi bi-thermometer-high"></i>
                                    Température:</strong>
                                <p class="mb-0 text-capitalize">{{stat.temperature}}°C</p>
                            </div>
                            <div class="d-flex">
                                    <strong class="mr-2" style="color: #5dc4be;"><i class="bi bi-clock"></i>
                                        Siéste:</strong>
                                    <p class="mb-0 text-capitalize">{{stat.nap}}</p>
                            </div>
                            <div class="d-flex">
                                    <strong class="mr-2" style="color: #5dc4be;"><i class="bi bi-egg-fried"></i>
                                        Repas:</strong>
                                    <p class="mb-0 text-capitalize">{{ stat.meal }}</p>
                            </div>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #5dc4be;"><i class="bi bi-egg-fill"></i>
                                    Goûter:</strong>
                                <p class="mb-0 text-capitalize">{{ stat.snack}}</p>
                            </div>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #5dc4be;">
                                    <i class="bi bi-question-circle"></i>
                                    Selle:
                                </strong>
                                <span *ngIf="stat.salt" class="badge badge-success">Oui</span>
                                <span *ngIf="!stat.salt" class="badge-tot-success">Non</span>
                                <p *ngIf="stat && stat.nbreSalt && stat.salt" class="mb-0 text-capitalize ml-2">{{
                                    stat.nbreSalt}} fois</p>
                            </div>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #5dc4be;"><i class="bi bi-cake2"></i>
                                    Événement:</strong>
                                <p class="mb-0 text-capitalize">{{stat.event}}</p>
                            </div>
                            <br>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #5dc4be;"><i class="bi bi-file-text"></i>
                                    Note:</strong>
                                <p class="text-capitalize">{{ stat.note | slice:0:15 }}{{ stat.note.length > 10? '...' :
                                    '' }}</p>
                                <div *ngIf="!stat.note" class="mb-3 title-info no-data"><span
                                        class="aucun-text">Aucune</span></div>
                            </div>
                            <div class="d-flex justify-content-center mb-2">
                                <!-- Icon for Visualization -->
                                <button class="btn btn-primary"style="color: #fff;background-color:#5dc4be !important" data-toggle="modal"
                                        (click)="getDataStatement(stat)" data-target="#exampleModalArchiver"
                                        title="Visualiser">Consulter</button>

                                <!-- Icon for Modification -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="statements.length > 4 ">
                <div class="d-flex justify-content-center">
                    <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                        (pageChange)="onTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
        <div *ngIf="statements && statements.length == 0" class="card-body"
            style="background-color: #d0f0ee91; border: #d0f0ee91;text-align: center;">
            <p style="padding-bottom: 45px; padding-top: 35px ">
                Aucun compte rendu n'est trouvé
            </p>
        </div>

        <br />
        <br />
    </div>
</section>

<!--Modal-->
<div class="modal fade" id="exampleModalArchiver" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div *ngIf="dataChildren" class="modal-header">
                <h3 class="modal-title" id="exampleModalLabel" style="color: #fff;">Compte rendu le
                    {{dataChildren.action.date| date:'dd/MM/yyyy'}}</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    style="color: #fff; background: none;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="dataChildren" class="modal-body">
                <ul class="list-group">.
                    <li class="d-flex justify-content-center">
                        <strong style="color:#5dc4be;" class="d-inline"><i class="bi bi-clock"></i> Arriver
                            à: </strong>
                        <span class="d-inline  mr-3"> {{dataChildren.arrivingTime}}</span>
                        <strong style="color:#5dc4be;" class="d-inline"><i class="bi bi-clock"></i> Partir
                            à: </strong>
                        <span class="d-inline"> {{dataChildren.departureTime}}</span>
                    </li>
                    <br>
                    <li class="d-flex">
                        <strong style="color:#5dc4be;"><i class="bi bi-thermometer-high"></i>Température:</strong>
                        <span class="d-inline  mr-3">{{dataChildren.temperature}}°C</span>
                        <strong style="color:#5dc4be;"><i class="bi bi-clock"></i>Sieste:</strong>
                        <span class="d-inline "> {{dataChildren.nap}}</span>
                    </li>
                    <li class="d-flex flex-row">
                        <strong style="color:#5dc4be;" class="d-inline">
                            <i class="bi bi-egg-fried"></i> 
                            Repas: </strong>
                        <span class="d-inline"> {{dataChildren.meal}} </span>


                    </li>
                    <li class="d-flex flex-row">
                        <strong style="color:#5dc4be;" class="d-inline"><i class="bi bi-egg-fill"></i> Goûter: </strong>
                        <span class="d-inline"> {{dataChildren.snack}}</span>

                    </li>
                    <li *ngIf="dataChildren.salt" class="d-flex flex-row">
                        <strong style="color:#5dc4be;">
                        <i class="bi bi-question-circle"></i> Selle: 
                        <i style="color: #28A745;" class="bi bi-circle-fill mr-2"></i> 
                        </strong>
                        {{dataChildren.nbreSalt}} fois
                    </li>
                    <li *ngIf="!dataChildren.salt" class="list-group-item"><strong style="color:#5dc4be;"><i
                                class="bi bi-question-circle"></i> Selle: </strong>
                        <i style="color: #DC3545;" class="bi bi-circle-fill"></i>
                    </li>
                    <li class="d-flex flex-row">
                        <strong style="color:#5dc4be;"><i class="bi bi-cake2"></i> Événement:</strong>
                        <span class="d-block">{{dataChildren.event}}</span>
                    </li>
                    <br>
                    <li class="d-flex flex-column">
                        <strong style="color:#5dc4be;"><i class="bi bi-file-text"></i> Note:</strong>
                        <div class="note-text">{{dataChildren.note}}</div>
                        <div *ngIf="!dataChildren.note" class="mb-3 title-info no-data"><span
                            class="aucun-text">Aucune</span></div>
                    </li>
                </ul>
                <div class="mt-4">
                    <div class="d-flex justify-content-end">
                        <div class="mr-3">
                            <i style="color: #28A745;" class="bi bi-circle-fill"></i> Oui
                        </div>
                        <div class="mr-3">
                            <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>
