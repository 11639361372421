import { Component, OnInit } from '@angular/core';
import { ClasseService } from 'src/app/_services/classe.service';
import { CoursService } from 'src/app/_services/cours.service';
import { ExerciceService } from 'src/app/_services/exercice.service';
import { QuestionService } from 'src/app/_services/question.service';
import { TestService } from 'src/app/_services/test.service';
import * as Chart from 'chart.js';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
@Component({
  selector: 'app-enseignant-home',
  templateUrl: './enseignant-home.component.html',
  styleUrls: ['./enseignant-home.component.scss']
})
export class EnseignantHomeComponent implements OnInit {
  publieee
nbreClasses;
nbreMatieres;
nbreCours;
nbreDoucuments
nbreExercices;
nbreQuestions;
lustTest;
lustUpdatesTest
dataCases8: any = {
  chart3: [0,0,0,0,0,0,0,0,0,0,2,1],
  //chart2: [200, 1000, 1200, 1400, 600, 0, 0, 0, 0, 0, 0, 0]
  chart2: [0,0,0,0,0,0,0,0,0,0,2,1],
};
chartResults : [100,2000,0,0,0,0,0,0,0,0,2,800]
public canvas: any;
public ctx: any;
public labels: any = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'];
  publieeeeeee: any;
  currentUser: any;
  type: any;
  constructor(private classeService :ClasseService,private coursService :CoursService,
    private exerciceService :ExerciceService,private questionService :QuestionService,
    public testSrvice :TestService,
    private token :TokenStorageService
    ) { }

  ngOnInit(): void {

    
    this.currentUser = this.token.getUser();
  //"::",this.currentUser[0]._id)
  //"typeEtbali",this.currentUser[0].etablissement.type)
    this.type=this.currentUser[0].etablissement.type
    

    this.totalClasses();
    this.totalMatieres();
  //   this.totalCours()
  //   this.totalExercices();
  //   this.totalQuestions();
  //   this.totalDoucuments();
  //   this.number5LustUpdatesTest();
  //   this.numberTestByMonthJAN()
  //  this.numberTestByMonthFev()
  //  this.numberTestByMonthMAR()
  //  this.numberTestByMonthAPP()
  //  this.numberTestByMonthMAY()
  //  this.numberTestByMonthJUIN()
  //  this.numberTestByMonthJUIL()
  //  this.numberTestByMonthAUG()
  //  this.numberTestByMonthSEP()
  //  this.numberTestByMonthOCT()
  //  this.numberTestByMonthNOV()
  //  this.numberTestByMonthDEC()
  //  this.number5LustTest()
    
  }

totalClasses()
{
//"e")
  this.classeService.getNumberclassesByEnseignant().subscribe(data =>{
  //data);

    this.nbreClasses=data;
  //this.nbreClasses);
  })
}

totalMatieres()
{
//"e")
  this.classeService.getNumberMatieresByEnseignant().subscribe(data =>{
  //data);

    this.nbreMatieres=data;
  })
}

totalCours()
{
//"e")
  this.coursService.totalCours().subscribe(data =>{
  //data);

    this.nbreCours=data;
  //this.nbreCours);
  })
}

totalDoucuments()
{
  //nbreDoucuments
//"e")
  this.coursService.totalDoucuments().subscribe(data =>{
  //data);

    this.nbreDoucuments=data;
  //this.nbreDoucuments);
  })
}

totalQuestions()
{
//"e")
  this.questionService.totalQuestions().subscribe(data =>{
  //data);

    this.nbreQuestions=data;
  //'**',this.nbreQuestions);
  })
}

totalExercices()
{
//"e")
  this.exerciceService.totalExercices().subscribe(data =>{
  //data);

    this.nbreExercices=data;
  //this.nbreExercices);
  })
}

private createLineChart(labels, dataCases, chartId) {
  this.canvas = document.getElementById(chartId);
  this.ctx = this.canvas.getContext('2d');

 
  
  let chart = new Chart(this.ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [
      //   {
      //   label: "Chart 1",
      //   data: this.dataCases8.chart3,
      //   backgroundColor: '#ffbb33',
      //   borderColor: '#ffbb33',
      //   fill: false,
      //   borderWidth: 2
      // },
      {
        label: "Chart ",
        data: this.dataCases8.chart2,
        backgroundColor: '#ff4444',
        borderColor: '#ff4444',
        fill: false,
        borderWidth: 2
      }
    ]
    },
    options: {
      title: {
        display: true,
        text: "Le nombre des tests par mois"
      },
      tooltips: {
        mode: 'index',
        intersect: true
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      
    }
  });
}



numberTestByMonthJAN()
  {
  //"e")
    let n :string
    n='1'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[0]=data;
    //this.dataCases8.chart2[0])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthFev()
  {
  //"e")
    let n :string
    n='2'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[1]=data;
    //this.dataCases8.chart2[1])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}
numberTestByMonthMAR()
  {
  //"e")
    let n :string
    n='3'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[2]=data;
    //this.dataCases8.chart2[2])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthAPP()
  {
  //"e")
    let n :string
    n='4'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[3]=data;
    //this.dataCases8.chart2[3])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}
numberTestByMonthMAY()
  {
  //"e")
    let n :string
    n='5'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[4]=data;
    //this.dataCases8.chart2[4])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthJUIN()
  {
  //"e")
    let n :string
    n='6'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[5]=data;
    //this.dataCases8.chart2[5])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}
numberTestByMonthJUIL()
  {
  //"e")
    let n :string
    n='7'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[6]=data;
    //this.dataCases8.chart2[6])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthAUG()
  {
  //"e")
    let n :string
    n='8'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[7]=data;
    //this.dataCases8.chart2[7])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthSEP()
  {
  //"e")
    let n :string
    n='9'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[8]=data;
    //this.dataCases8.chart2[8])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthOCT()
  {
  //"e")
    let n :string
    n='10'
    this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
     // this.testSrvice.totalTests().subscribe(data =>{
    //data);

      this.dataCases8.chart2[9]=data;
    //this.dataCases8.chart2[9])
      this.createLineChart(this.labels, this.dataCases8, 'myChart');

    })
}

numberTestByMonthNOV()
{
//"e")
  let n :string
  n='11'
  this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
   // this.testSrvice.totalTests().subscribe(data =>{
  //data);

    this.dataCases8.chart2[10]=data;
  //this.dataCases8.chart2[10])
    this.createLineChart(this.labels, this.dataCases8, 'myChart');

  })
}

numberTestByMonthDEC()
{
//"e")
  let n :string
  n='12'
  this.testSrvice.totalTestsByMonth(n).subscribe(data =>{
   // this.testSrvice.totalTests().subscribe(data =>{
  //data);

    this.dataCases8.chart2[11]=data;
  //this.dataCases8.chart2[11])
    this.createLineChart(this.labels, this.dataCases8, 'myChart');

  })
}

// les 5 derniers tests
number5LustTest()
{
 
  this.testSrvice.total5FirtsTests().subscribe(data =>{
   
 

    this.lustTest=data
    for (let i of this.lustUpdatesTest)
    {
      this.publieeeeeee=i.publie
    }
   
  })
}

number5LustUpdatesTest()
{
//"5 derniers test modifies")
 
  this.testSrvice.total5FirtsTestsModifies().subscribe(data =>{
   
  //data);

    this.lustUpdatesTest=data
  //'5 dernieres tests modifees ' , this.lustUpdatesTest)
    for (let i of this.lustUpdatesTest)
    {
      this.publieee=i.publie
    }
  })
}

}
