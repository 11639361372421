import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Enfant } from '../_models/enfant';
import { environment } from 'src/environments/environment';
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})
export class EnfantService {

  private _enfanttUrl=`${environment.baseUrl}/enfants` 
  constructor(private http: HttpClient) { }
  
 
  public getEnfants() :Observable <any> {
    

    return this.http.get<any[]>(this._enfanttUrl, { 'headers': headers }).pipe(response =>
      response)
      }

      createEnfant(enfant: any):Observable<Object> {
        return this.http.post(`${this._enfanttUrl}`,enfant);
      }
      
      deleteEnfant(id : string)
      {
        return this.http.delete(`${this._enfanttUrl}/${id}`);
      } 

     getEnfantById(id : string):Observable<Enfant>
      {
        return this.http.get<Enfant>(`${this._enfanttUrl}/${id}`);
    
      } 
      
      EditEnfant(id:string,enfant:Enfant):Observable<Object> {
        return this.http.put(`${this._enfanttUrl}/${id}`,enfant);
      }

      getEnfantsByClasse(id :string) :Observable <any>
      {
       return this.http.get(`${this._enfanttUrl}/byClasse/${id}`);
      }

      getEnfantsByParent(id :string) :Observable <any>
      {
       return this.http.get(`${this._enfanttUrl}/byParent/${id}`);
      }

      getEnfantByEtablissement(id:string) :Observable <any> {
    

        return this.http.get<any[]>(`${this._enfanttUrl}/byEtablissement/${id}`)
          }

          totalEnfants() {
            return this.http.get(`${this._enfanttUrl}/numberEtudiants`);
          }

          getEnfantsByDirector(id :string) :Observable <any>
          {
           return this.http.get(`${this._enfanttUrl}/byDirector/${id}`);
          }

          addProfilePhoto(photo:any, key : String,){
            return this.http.post(`${this._enfanttUrl}/upload/profile_photo/${key}`,photo);
          }
}
