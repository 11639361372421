

    <section class="content home">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Profil
                      
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/puericulteur"><i class="zmdi zmdi-home"></i>
                          Puéricultrice </a>
                        </li>
                        <li class="breadcrumb-item active">Profil</li>
                    </ul>
                </div>
            </div>
        </div>
        <br> 
        <div class="container">
          <div class="main-body">
        <div class="row gutters-sm">
          <div class="col-md-6 ">
            <div class="card">
              <div class="card-body">
                <div   class="d-flex flex-column align-items-center text-center">
                  <div>
                    <div class="profile-image-container">
                      <div class="profile-image">
                        <input type="file" id="fileInput" (change)="onFileSelected($event)" style="display: none;" accept="image/*">
                        <label for="fileInput" class="custom-file-upload">
                          <i class="fa fa-camera"></i>
                          <span class="change-photo-text">Changer la photo</span>
                        </label>
                        <img *ngIf="imageUrlReadrer" [src]="imageUrlReadrer" alt="Uploaded Image" />
                        <img *ngIf="!imageUrlReadrer && length > 0"  alt="Uploaded Image" src="{{ BaseUrl }}/{{ profils[length-1].filename }}" />
                        <img *ngIf="!imageUrlReadrer && length == 0" src="https://www.w3schools.com/w3css/img_avatar3.png" alt="Uploaded Image" />
                      </div>
                    </div>                                   
                  </div>

                   <!-- <div>
                    ss {{imageUrlReadrer}}
                  <img *ngIf="length > 0" [src]= "profils[length-1].filename"  alt="Admin" class="rounded-circle" width="150">
                  <img *ngIf="length == 0" src="https://www.w3schools.com/w3css/img_avatar3.png"  alt="Admin" class="rounded-circle" width="150">
                  </div>  -->
                  <div class="mt-3">
                    <h4>{{firstName}} {{lastName}}</h4>
                    <p class="text-secondary mb-1">Puéricultrice </p>
                    <p class="text-muted font-size-sm">{{email}}</p>
                    <br>
                    <button *ngIf="imageUrlReadrer" (click)="updateProfilePhoto(user._id)" class="btn0">Modifier votre photo de profil</button>
                      <button *ngIf="!imageUrlReadrer" style="cursor: not-allowed;" class="btndisabled">Modifier votre photo de profil  
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div class="col-md-12 mb-3 ">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Nom</h6>
                  </div>
                  <div class="col-sm-9 text-muted">
                    {{firstName}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Prénom</h6>
                  </div>
                  <div class="col-sm-9 text-muted">
                    {{lastName}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Email</h6>
                  </div>
                  <div class="col-sm-9 text-muted">
                      {{email}}
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Télephone</h6>
                  </div>
                  <div class="col-sm-9 text-muted">
                      {{phone}}
                  </div>
                </div>
                <hr>
                
  
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Mot de passe</h6>
                  </div>
                  <div class="col-sm-9 text-muted">
                     **********
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-sm-12 d-flex justify-content-center">
                      <button (click)="updateProfilePassword(user._id)" class="btn0 mr-3">Modifier votre mot de passe   </button>
                      <button (click)="updateProfileInformations(user._id)" class="btn0">Modifier vos cordonnés personelles  </button>
                  </div>
                </div>
              </div>
            </div>
           </div>
           </div>
          </div>
        </div>
        </section>
