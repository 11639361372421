import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Console } from 'console';
import { Profil } from 'src/app/_models/profil';
import { ProfilService } from 'src/app/_services/profil.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profil-enseignant',
  templateUrl: './profil-enseignant.component.html',
  styleUrls: ['./profil-enseignant.component.scss']
})
export class ProfilEnseignantComponent implements OnInit {

  currentUser: any;
  id;
  ids;
  user;
  d
  profils :Profil [];
  image: Profil []
  length
  firstName;
  lastName;
  role;
  email;
  phone;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/photosProfils/';
  imageUrlReadrer: string;
  fileUploaded
  closeResult: string;

  onFileSelected(event: any): void {
    const fileInput = event.target;
    const file = fileInput.files[0];
    this.fileUploaded = file

    if (file) {
      this.readAndDisplayImage(file);
    }
  }

  readAndDisplayImage(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageUrlReadrer = e.target.result;
    };

    reader.readAsDataURL(file);
  }
  //sanitization: any;
  ////
  constructor(private token :TokenStorageService,private router :Router,
    private userService :UserService,private profilService :ProfilService,
    private sanitization: DomSanitizer,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.d=this.userService.getUserById(this.currentUser[0]._id).subscribe(async data=>{
      this.user=data
      this.firstName=this.user.firstName
      this.lastName=this.user.lastName
      this.role=this.user.role.type
      this.email=this.user.email
      this.phone=this.user.phone 
  })
  this.profilService.getProfilUser().subscribe(e=>{
    
    this.profils= e
   this.length=this.profils.length;
 
 })

}
    
 
  updateProfileInformations(iduser:string)
{
 this.router.navigate(['puericulteur/profil/modification-informations',iduser])
}

updateProfilePassword(iduser:string)
{
 this.router.navigate(['puericulteur/profil/modification-motDePasse',iduser])
}

updateProfilePhoto(iduser:string)
{
 //this.router.navigate(['Enseignant/profil/modification-photo',iduser])
 this.onSubmit()
}
onSubmit()
   {
    const imagBlob =this.fileUploaded
    const form =new FormData();
    form.set('image',imagBlob);

    this.profilService.createProfil(form).subscribe(data=>{   
     })
location.reload()
    }

    
  }

  


