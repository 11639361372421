import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { SearchService } from '../_services/search.service';
import { SharedService } from '../_services/shared.service';
import { isThisQuarter } from 'date-fns';
//import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss'],
})
export class BackofficeComponent implements OnInit {
  currentUser: User;
  isSidebarVisible: boolean = true;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  captcha :string;
  email :string
  search=""
  searchs: any;
  ResultsSerachs =[]
  constructor(
    //private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private authenticationService: AuthenticationService,
    private searchService :SearchService,
    private sharedService: SharedService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.sharedService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });

  }

  ngOnInit(): void {
    this.captcha = '';
    this.email = 'khalilmeddeb2@gmail.com';
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/Connexion']);
  }

  public resolved(captchaResponse :string)
 {
   this.captcha =captchaResponse
 }
  

searchGlobale()
{
  this.router.navigate(['Super-Admin/recherche',this.search])
  this.search=""
}
toggleSidebar() {
  this.isSidebarVisible = !this.isSidebarVisible;
}
}
