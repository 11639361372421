import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Etablissement } from 'src/app/_models/etablissement';
import { Role } from 'src/app/_models/role';
import { EtablissementService } from 'src/app/_services/etablissement.service';

@Component({
  selector: 'app-etablissement',
  templateUrl: './etablissement.component.html',
  styleUrls: ['./etablissement.component.scss']
})
export class EtablissementComponent implements OnInit {

  etbalissement :Etablissement = new Etablissement();
  firstName:string
  adress:string
  pays:string
  phone:string
  taille:number ;
  actionDisabled :boolean =false;
  message="";
  message2=""
  message3="suppression avec succès"
  show :boolean=false;
  etbalissements: Etablissement[] = [];
  copyListEtablissement:any;
  roles :Role [] =[];
  i
  closeResult = '';
  enabled;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  constructor(private etablissementService :EtablissementService,private router: Router, private route: ActivatedRoute,private modalService: NgbModal
    ,public iziToast: Ng2IzitoastService,) 
  {
  
   }

  ngOnInit(): void {
    this.getEtablissements();
    this.taille=this.etbalissements.length;
  }

 

  getEtablissements(){
   
    this.etablissementService.getEtablissements().subscribe(e=>{
     
        this.etbalissements=e;
        this.copyListEtablissement=this.etbalissements;
        for(let i of e){
          this.enabled=i.status
          for ( let r of i.role)
          {
          }
                    
        }
          })
         
  }
  

 
  deleteEtablissement(etbalissement:Etablissement)
{
  
this.etablissementService.deleteEtablissement(etbalissement._id).subscribe(e=>
  {
   
    this.show=true;
    this.iziToast.show({
      message: "Supression avec succès",
      messageColor: "#b81414",
      progressBarColor: "#e3a1a1",
      icon: "bi-check-circle",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#f8e8e8",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
    });
    this.getEtablissements();
  })
  this.modalService.dismissAll()
} 

updateEtablissement(id:string)
{
 
  this.router.navigate(['Super-Admin/établissements/modification-établissement',id])
 
}


search()
{
  this.etbalissements = this.copyListEtablissement
  let filteredEtablissement = [...this.etbalissements];
  if(this.firstName != "" || this.adress != "" || this.pays != "" || this.phone != "" )
   {
    filteredEtablissement = this.etbalissements.filter(res=>{
    return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
    || res.adresse.toLocaleLowerCase().match(this.adress.toLocaleLowerCase()) 
    || res.pays.toLocaleLowerCase().match(this.pays.toLocaleLowerCase())
    || res.telephoneFixe.toLocaleLowerCase().match(this.phone.toLocaleLowerCase()) 
  })
  this.etbalissements = filteredEtablissement;
   }
   else if(this.firstName == "" && this.adress == "" && this.pays == "" && this.phone == "")
   {
     this.getEtablissements();
   }
}

open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

open2(content2) {
  this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}



changeStatusEtablissement(etbalissement:Etablissement)
{
  this.etablissementService.EditSatutsEtablissement(etbalissement._id).subscribe(e=>
    {
      this.getEtablissements();
    })
    this.modalService.dismissAll()
  }
  
  onTableDataChange(event: any) {
    this.page = event;
    this.getEtablissements();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getEtablissements();
  }
  getBadgeClass(type: string): any {
    // Determine the badge class based on the type of establishment
    if (type === 'créche') {
      return { 'badge': true, 'badge-pill': true, 'badge-teal': true };
    } else if (type === 'Université') {
      return { 'badge': true, 'badge-pill': true, 'badge-orange': true };
    } else {
      // Default badge class if type doesn't match any specific condition
      return { 'badge': true, 'badge-pill': true, 'badge-default': true };
    }
  }
}


