<section class="content home">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2 style="color: #5dc4be !important">
                    Mes enfants
                    <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a routerLink="/parent"><i class="zmdi zmdi-home"></i> Parent</a>
                    </li>
                    <li class="breadcrumb-item active">Mes enfants</li>
                </ul>
            </div>
        </div>
    </div>
    <br />
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="product_item_list">
                    <div class="body table-responsive" style="overflow: hidden !important;">
                        <div class="row">
                            <div *ngFor="let i of enfants | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let k = index"
                            class="col-lg-3 col-md-6 col-sm-12 text-center">
                                <div class="card d-flex align-items-center justify-content-center" (click)="redirectToDetails(i._id)" style="cursor: pointer;">
                                    <div class="card-body text-center">
                                        <i class="bi bi-person-vcard" style="font-size: 4rem;color: #5dc4be;"></i>
                                        <h5 class="card-title text-capitalize"
                                            style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600; color: #5dc4be;">
                                            {{ i.nom }} {{ i.prenom }}</h5>
                                    </div>
                                    <!-- <div class="card-footer">
                                        <button (click)="viewListesPhotosEnfannts(i._id)" id="but">
                                            Listes des photos
                                        </button>
                                    </div>
                                    <div class="card-footer">
                                        <button (click)="viewListesStatementsEnfannts(i._id)" id="but">
                                            Comptes rendus 
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div *ngIf="enfants && enfants.length > 9" class="card-footer border-0 py-5"
                            style="background-color: white;">
                            <div class="d-flex justify-content-center">
                                <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                                    (pageChange)="onTableDataChange($event)">
                                </pagination-controls>
                            </div>
                        </div>
                        <div *ngIf="enfants && enfants.length == 0" class="table-responsive" style="text-align: center">
                            <p style="padding-bottom: 45px; padding-top: 35px ">
                                Aucune enfant n'est trouvé
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
