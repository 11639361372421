import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SignIn } from 'src/app/_models/sign-in';
import { SignInService } from 'src/app/_services/sign-in.service';

@Component({
  selector: 'app-sig-in-list',
  templateUrl: './sig-in-list.component.html',
  styleUrls: ['./sig-in-list.component.scss']
})
export class SigInListComponent implements OnInit {

 
  inscriptions: SignIn[] = [];
  closeResult = '';
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  url2
  valueChange = true;
  constructor(private signInService :SignInService,private router: Router, private route: ActivatedRoute
    ,private modalService: NgbModal,private toastr: ToastrService,private sanitization: DomSanitizer)
   {
   
   }

  ngOnInit(): void {
    this.getInscriptions();
  }

 

  getInscriptions(){
   
    this.signInService.getInscriptions().subscribe(e=>{
     
        this.inscriptions=e.map(p=>{
         p.filename=this.sanitization.bypassSecurityTrustResourceUrl("http://localhost:3007/"+p.filename);
         this.url2=p.filename.changingThisBreaksApplicationSecurity
         //this.openExercice(this.url2)

         p.path=p.filename.changingThisBreaksApplicationSecurity;
         p.datebe =p.date
       return p;
      
       
      
       })
          })
  }
  


open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

open1(content1) {
  this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

open2(content2) {
  this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
onTableDataChange(event: any) {
  this.page = event;
  this.getInscriptions();
}
onTableSizeChange(event: any): void {
  this.tableSize = event.target.value;
  this.page = 1;
  this.getInscriptions();
}
disableSignIn(sigIn :SignIn){

this.signInService.DisableSingnIn(sigIn._id).subscribe(e=>{
  this.getInscriptions();
})
}

openFile(x)
 {
   window.open(x)
 }

 delete(signIn:SignIn)
  
 {
   
 this.signInService.delete(signIn._id).subscribe(e=>
   {
     
  
    
     this.toastr.error('suppression avec succès')
     this.getInscriptions()
   })
   this.modalService.dismissAll()
 } 
 accept(i)
  
 {
   
 this.signInService.accept(i).subscribe(e=>
   {
     
   
    
     this.toastr.success('acceptation avec succès')
     this.getInscriptions()
   })
   this.modalService.dismissAll()
 } 
 refuse(i)
  
 {
   
 this.signInService.refuse(i).subscribe(e=>
   {
     
   
    
     this.toastr.error('refut avec succès')
     this.getInscriptions()
   
   })
   this.modalService.dismissAll()
 } 
}