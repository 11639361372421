import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Matiere } from 'src/app/_models/matiere';
import { MatiereService } from 'src/app/_services/matiere.service';

@Component({
  selector: 'app-matieres',
  templateUrl: './matieres.component.html',
  styleUrls: ['./matieres.component.scss']
})
export class MatieresComponent implements OnInit {

  matiere :Matiere = new Matiere();
  nom:string
  /*numeroRegister:string
  pays:string
  taille:number ;*/
  //nbreEtablissements ;
  
  //lastName:string // comentithom
  //email:string    //comentithom
  //error :boolean=false ;
  //eroorMessage :String="Il faut remplir e champs,il est obligatoire.";
  matieres: Matiere[] = [];
  //roles :Role [] =[];
  //i
  closeResult = '';
  message="";
  message2=""
  message3="Suppression avec succès "
  show :boolean=false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 9;
  tableSizes: any = [3, 6, 9, 12];
  constructor(private matiereService :MatiereService,private router: Router, private route: ActivatedRoute,private modalService: NgbModal,
  public iziToast: Ng2IzitoastService,) 
  {
    
   }
  ngOnInit(): void {
    this.getMatieres(); 
  }

 

  getMatieres(){
    this.matiereService.getMartieres().subscribe(e=>{
        this.matieres=e;        
          })
         
  }
  

 
  deleteMatiere(matiere:Matiere)
{
this.matiereService.deleteMatiere(matiere._id).subscribe(e=>
  {
    this.show=true;
    this.iziToast.show({
      message: "Supression avec succès",
      messageColor: "#b81414",
      progressBarColor: "#e3a1a1",
      icon: "bi-check-circle",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#f8e8e8",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
    });
    this.getMatieres();
  })
  this.modalService.dismissAll()
} 

updateMatiere(id:string)
{
  this.router.navigate(['directeur/activites/modification-activite',id])
}


search()
{
  if(this.nom != ""  )
   {
  this.matieres = this.matieres.filter(res=>{
    return res.nom.toLocaleLowerCase().match(this.nom.toLocaleLowerCase()) })
  
   }
   else if(this.nom == "")
   {
     this.getMatieres();
   }
}


/*search()
{
  if(this.firstName != "" || this.numeroRegister != "" || this.pays != "" )
   {
  this.etbalissements = this.etbalissements.filter(res=>{
    return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
    || res.numeroRegister.toLocaleLowerCase().match(this.numeroRegister.toLocaleLowerCase()) 
    || res.pays.toLocaleLowerCase().match(this.pays.toLocaleLowerCase()
     ) 
  })
   }
   else if(this.firstName == "" && this.numeroRegister == "" && this.pays == "")
   {
     this.getEtablissements();
   }
}*/

open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
onTableDataChange(event: any) {
  this.page = event;
  this.getMatieres();
}
onTableSizeChange(event: any): void {
  this.tableSize = event.target.value;
  this.page = 1;
  this.getMatieres();
}
}
