<div class="mainbar">
  <div class="left" style="padding-top: 15px;padding-left: 15px;">
    <button class="hamburger-btn" (click)="toggleSidebar()">
      <i class="zmdi zmdi-menu"></i>
    </button>
  </div>
  <div class="right" style="padding-top: 7px;">
   
    <div class="profile mybox" id="">
      <h5 *ngIf="user && user.role.type === 'SuperAdmin'" style="color: coral;font-weight: 500; " class="name"> {{user.role.type}} <i class="fa fa-angle-down"></i></h5>
      <h5 *ngIf="user && user.role.type === 'Directeur'" style="color: #f9656b;font-weight: 500; " class="name">{{user.role.type}} <i class="fa fa-angle-down"></i></h5>
      <h5 *ngIf="user && user.role.type === 'Parent'" style="color: #5dc4be;font-weight: 500; " class="name">{{user.role.type}} <i class="fa fa-angle-down"></i></h5>
      <h5 *ngIf="user && user.role.type === 'Enseignant'" style="color: #f9656b;font-weight: 500; " class="name">Puéricultrice  <i class="fa fa-angle-down"></i></h5>
      <img  *ngIf="length == 0" class="pic" src="https://www.w3schools.com/w3css/img_avatar3.png" style="margin-left: 1.25rem;" />
      <img  *ngIf="length > 0" class="pic" src="{{ BaseUrl }}/{{ profils[length-1].filename }}"  style="margin-left: 1.25rem;" />
      <ul style="padding-top: 2px;">
        <!-- <li><i class="fa fa-user"></i> Profil </li>
        <li><i class="fa fa-cog"></i> Config</li> -->
        <li *ngIf="user && user.role.type === 'Enseignant'" (click)="goProfilEnseig()"><i class="bi bi-person-circle"></i> Profil</li>
        <li (click)="logout()"><i class="bi bi-power"></i> Se déconnecter</li>
      </ul>

    </div>
  </div>
</div>