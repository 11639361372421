import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { da } from 'date-fns/locale';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Matiere } from 'src/app/_models/matiere';
import { User } from 'src/app/_models/user.model';
import { EnseignantService } from 'src/app/_services/enseignant.service';
import { MatiereService } from 'src/app/_services/matiere.service';

@Component({
  selector: 'app-update-enseignant',
  templateUrl: './update-enseignant.component.html',
  styleUrls: ['./update-enseignant.component.scss']
})
export class UpdateEnseignantComponent implements OnInit {

  id:string;
  enseignant:User=new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres : any;
  d;
  toutesmatieres: any = [];
  Ttmatieres;
  tabs;



  countryCode;
  @ViewChild('phoneInput') phoneInput: NgxIntlTelInputComponent;
  phone0;
  phone;
  codePays;
  numero;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;

  
  constructor(private cdRef:ChangeDetectorRef,private enseignantService :EnseignantService,private matiereService :MatiereService,
    private router: Router, 
    private route: ActivatedRoute,
    
    private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,
   ) 
   {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.d=this.enseignantService.getEnseignantById(this.id).subscribe(async data=>{
      this.enseignant=data;
      this.enseignant.password="";
      this.matieres= this.enseignant.matiere
      this.phone0=data.phone;


      this.phone=data.phone.split(' ')
      this.codePays=this.phone[0]
      this.numero=this.phone[1]

      this.registerForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phone: [
          data.phone,
          [
            Validators.required,
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.minLength(6), ]],
        confirmpassword: ['', [Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
        matiere:  [this.matieres.map(m => m._id), Validators.required],
        //etablissement :  ['', Validators.required],
        //policy_checked: [false, Validators.required],
     }
     ,
       {
         validator: MustMatch('password', 'confirmpassword')
     }
  );
      this.toutesmatieres = await this.matiereService.getMartieres().toPromise();
      for(let i of  this.toutesmatieres.keys()){
        let index = this.matieres.findIndex(m=>m._id == this.toutesmatieres[i]._id);
        this.toutesmatieres[i].selected=index!=-1;      
      } 
    })
    

    



     
    
    //this.getMatieres();
    
    //this.getEtablissements();
  }
  

  get fval_2() {
    return this.registerForm.controls;
  }
  onChange()
 {

 }
 onSubmit() {
  this.submitted = true;

  // Return here if the form is invalid
  if (this.registerForm.invalid) {
    return;
  }

  if (this.registerForm.invalid) {
    return;
  }

  const phoneNumberObject = this.registerForm.get('phone').value;
  const phoneNumber = phoneNumberObject.internationalNumber;

  if (phoneNumber === this.phone0) {
    this.registerForm.value.phone = this.phone0;
  } else {
    this.registerForm.value.phone = phoneNumber;
  }

  // Get the selected matieres from ng-select
  const selectedMatieres = this.registerForm.value.matiere;

  // Use the selectedMatieres directly in the API call
  this.enseignantService.EditEnseignants(this.id, { ...this.registerForm.value, matiere: selectedMatieres }).subscribe(data => {
    this.iziToast.show({
      message: 'Modification avec succès',
      messageColor: '#386641',
      progressBarColor: '#6a994e',
      icon: 'bi-check-circle',
      imageWidth: 45,
      position: 'topRight',
      timeout: 5000,
      backgroundColor: '#dde5b6',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
    });
    this.goToEnseignantsList();
  });
}

  goToEnseignantsList()
  {
    this.router.navigate(['/directeur/puericulteurs']);
  }


  onCountryChange(event: any) {

    this.countryCode = event.dialCode;
    this.registerForm.value.phone = "+" + this.countryCode + " " + this.numero
  }
  getSelectedCountryISO(): CountryISO {
    const phoneNumberObject = this.registerForm.get('phone').value;
    return phoneNumberObject ? phoneNumberObject.countryCode : CountryISO.Belgium;
  }
    setCountryISOAfterViewInit(): void {
    if (this.phoneInput) {
      const selectedCountryISO = this.getSelectedCountryISO();
      this.phoneInput.selectedCountryISO = selectedCountryISO;
    }
  }



}
