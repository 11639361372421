import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Etablissement } from 'src/app/_models/etablissement';
import { Role } from 'src/app/_models/role';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { ModuleService } from 'src/app/_services/module.service';
import { RoleService } from 'src/app/_services/role.service';
import contries from 'src/app/List_countries.json';

@Component({
  selector: 'app-add-etablissement',
  templateUrl: './add-etablissement.component.html',
  styleUrls: ['./add-etablissement.component.scss']
})
export class AddEtablissementComponent implements OnInit {
  countryList = contries
  etbalissement :Etablissement =new Etablissement();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  roles : any;
  modules :any;
  show ;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private etablissementService: EtablissementService,
    private roleService:RoleService,
    private moduleService :ModuleService,
    public iziToast: Ng2IzitoastService,
    //private toastr: ToastrService
  ) { 
    this.etbalissement.role = new Role();
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      numeroRegister: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
        ],
      ],
      pays: ['', Validators.required],
      adresse: ['', Validators.required],
      telephoneFixe: [
        null, // Set to an initial value if needed
        [
          Validators.required,
        ],
        
      ],
      siteWeb: ['', Validators.required],
      // url : ['', Validators.required],
      type:['', Validators.required],
     
     
      role:  ['',[Validators.required]],
      //module :  ['',Validators.required],
      //policy_checked: [false, Validators.required],
    });
    this.getRoles();
    //this.getModules();
  }

  getRoles(){
    
   
      this.roleService.getRoles().subscribe(e=>{
        this.roles=e;
        this.etbalissement.role.id = e[0]._id

        
          
       
       
      
            })
    }

    // getModules(){
      
    //     this.moduleService.getModules().subscribe(e=>{
    //       this.modules=e;
    //       //his.etbalissement.role.id = e[0]._id
  
          
         
         
        
    //           })
    //   }
  

  get fval_2() {
    return this.registerForm.controls;
  }

  onChange()
  {

  }

  onChangeModules()
  {
 
  }

  saveEtablissement() {
    let results = [];
    let resultsModules =[]
    this.submitted = true;
  
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      
      return;
    }
    //this.loading = true;
   
    for (let m of this.roles){
      
    
     // this.registerForm.value.matiere=["620e06c80eb6fd6eb99936a9","620e23690eb6fd6eb99937f3"]
      if(m.selected == true){
      
      results.push(m._id)
      this.registerForm.value.role=results;
      
        }}
      if(results.length == 0)
      {
        
        this.show =false;
      }
      else{
        this.show=true;
      }  
      const telephoneFixeNumber = this.registerForm.value.telephoneFixe.number;
      const telephoneFixeCode = this.registerForm.value.telephoneFixe.dialCode;
    
      // Update the 'telephoneFixe' field with the combined value of country code and phone number
      this.registerForm.get('telephoneFixe').setValue(`${telephoneFixeCode} ${telephoneFixeNumber}`);
    
    this.etablissementService.createEtablissement(this.registerForm.value).subscribe(
      (data) => {
        
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.goToList();
      },
     
    );
    
  }
  goToList(){
    this.router.navigate(['/Super-Admin/établissements'])
    }
    onSubmit(){
     
      this.saveEtablissement();
    }
}
