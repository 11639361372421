import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { EnfantService } from 'src/app/_services/enfant.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-listes-enfants',
  templateUrl: './listes-enfants.component.html',
  styleUrls: ['./listes-enfants.component.scss']
})
export class ListesEnfantsComponent implements OnInit {
  currentUser: User;
  ids
  enfants;
  page: number = 1;
  count: number = 0;
  tableSize: number = 9;
  tableSizes: any = [3, 6, 9, 12];
  constructor(private token :TokenStorageService,private enfantService :EnfantService,private router :Router) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.ids=this.currentUser[0]._id
    this.getEnfants();
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getEnfants();
  }

  getEnfants(){
    this.enfantService.getEnfantsByParent(this.ids).subscribe(e=>{
        this.enfants=e;
        
          })   
  }

  viewListesPhotosEnfannts(id:string)
 {
  this.router.navigate(['parent/liste-enfants/liste-photos-enfants',id])
 }

 viewListesStatementsEnfannts(id:string)
 {
  this.router.navigate(['parent/liste-enfants/liste-comptes-rendus',id])
 }
 redirectToDetails(id: string) {
  this.router.navigate(['parent/liste-enfants/details-enfant', id]);
}
}
