<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Liste des enfants
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                   
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/puericulteur"><i class="zmdi zmdi-home"></i>
                            Puéricultrice </a>
                        </li>
                        <li class="breadcrumb-item active">Liste des enfants</li>
                       
                    </ul>
                </div>
            </div>
        </div>
       
       
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list" style="border: none;">
                        <div class="body table-responsive">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <input id="search-input" type="search" #myInput class="form-control"
                                            [(ngModel)]="firstName" [(ngModel)]="lastName" [(ngModel)]="adresse" (input)="search()" placeholder="Recherche"
                                            style="border-radius: 5rem 5rem 5rem 5rem; width: 70%" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" style=" border-radius: 5rem 5rem 5rem 5rem; background-color: #f9656b !important; color: white !important;">
                                                <i class="zmdi zmdi-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                </div>
                            </div>
                            <br/>
                            <br>
                            <table class="table table-striped">
                                    <tr>
                                         <th class="text-center">Nom</th>
                                         <th class="text-center">Prénom</th>
                                         <th class="text-center">Date de naissance</th>
                                         <th class="text-center">Adresse</th>
                                         <th class="text-center">Actions</th>
                                         
                                    </tr>
                                    <tr *ngFor="let i of enfants" >
                                        <td (click)="goToDetailsEnfant(i._id)" class="text-center text-capitalize"><span class="text-muted">{{i.nom}}</span> </td>
                                        <td (click)="goToDetailsEnfant(i._id)" class="text-center text-capitalize"><span class="text-muted">{{i.prenom}}</span> </td>
                                        <td (click)="goToDetailsEnfant(i._id)" class="text-center"><span class="text-muted">{{i.dateNaissance | date:'dd/MM/yyyy'}}</span> </td>
                                        <td (click)="goToDetailsEnfant(i._id)" class="text-center text-capitalize"><span class="text-muted">{{i.numero}} {{i.rue}} {{i.ville}} {{i.codePostal}}</span> </td>
                                        <td class="text-center">
                                            <button (click)="addPictureChildren(i._id)"  class="btn btn-default waves-effect waves-float waves-green" title="Ajouter une photo">
                                                <i class="zmdi zmdi-camera-add"></i>
                                            </button> 

                                            <!-- <button (click)="viewListesPhotosEnfants(i._id)"  class="btn btn-default waves-effect waves-float waves-green" title="Liste des photos"><i
                                                    class="zmdi zmdi-image"></i></button>    -->

                                            <button  class="btn btn-default waves-effect waves-float waves-green"><i
                                                class="bi bi-receipt" style="color: #f9656b;"
                                               
                                                (click)="getLustStatement(i._id)"
                                                
                                          ></i>
                                       
                                        </button>        
                                        </td>
                                       
                                    </tr>
                            </table> 
                            <div *ngIf="enfants && enfants.length > 7" class="card-footer border-0 py-5"
                                style="background-color: white;">
                                <div class="d-flex justify-content-center">
                                    <pagination-controls class="my-pagination" previousLabel="Précédent"
                                        nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                            <div *ngIf="enfants && enfants.length == 0" class="table-responsive" style="text-align: center">
                                <p style="padding-bottom: 45px; padding-top: 35px ">
                                    Aucun enfant n'est trouvé
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
   
      
      
      
     
</body>
