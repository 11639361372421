<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>
                        Liste des activités
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <!-- <button class="btn btn-primary btn-icon btn-round hidden-sm-down float-right m-l-10"
                        routerLink="addMatiere" style="color: white !important;">
                        <i class="zmdi zmdi-plus"></i>
                    </button> -->
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item">
                            <a routerLink="/directeur"><i class="zmdi zmdi-home"></i> Directeur</a>
                        </li>
                        <li class="breadcrumb-item active">Activités</li>
                        <li class="breadcrumb-item active">Liste des Activités</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list p-3" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                        <div class="body table-responsive">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <input id="search-input" type="search" #myInput class="form-control"
                                            [(ngModel)]="nom" (input)="search()" placeholder="Recherche"
                                            style="border-radius: 5rem 5rem 5rem 5rem; width: 70%" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" style="
                          border-radius: 5rem 5rem 5rem 5rem;
                          background-color: #f9656b !important;
                        ">
                                                <i class="zmdi zmdi-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <button routerLink="ajout-activite" class="btn btn-primary ajout"
                                        style="background-color: #f9656b !important"> <i class="bi bi-pen-fill"></i> Ajouter une activité
                                    </button>
                                </div>
                            </div>
                            <br/>
                            <div class="row">
                                <div *ngFor="let i of matieres | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let k = index"
                                class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card d-flex align-items-center justify-content-center">
                                    <div class="card-body text-center">
                                        <h5 class="card-title">{{ i.nom }}</h5>
                                        <div class="d-flex justify-content-center">
                                            <button (click)="open(content)"
                                                class="btn btn-default waves-effect waves-float waves-red mr-2">
                                                <i class="zmdi zmdi-delete"></i>
                                                <ng-template #content let-modal>
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="modal-basic-title">
                                                            Confirmation de suppression
                                                        </h4>
                                                        <button type="button" class="close" aria-label="Close"
                                                            (click)="modal.dismiss('Cross click')">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <label>Êtes-vous sûr de vouloir supprimer cette
                                                                activité?</label>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary"
                                                            (click)="modal.close('Save click')">
                                                            Annuler
                                                        </button>
                                                        <button type="button" class="btn btn-primary"
                                                            (click)="deleteMatiere(i)">
                                                            Supprimer
                                                        </button>
                                                    </div>
                                                </ng-template>
                                            </button>
                                            <button (click)="updateMatiere(i._id)"
                                                class="btn btn-default waves-effect waves-float waves-red mr-2">
                                                <i class="zmdi zmdi-edit"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div *ngIf="matieres && matieres.length > 10" class="card-footer border-0 py-5"
                                style="background-color: white;">
                                <div class="d-flex justify-content-center">
                                    <pagination-controls class="my-pagination" previousLabel="Précédent"
                                        nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                            <div *ngIf="matieres && matieres.length == 0" class="table-responsive" style="text-align: center">
                                <p style="padding-bottom: 45px; padding-top: 35px ">
                                    Aucune activité n'est trouvé
                                </p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        </div>


    </section>

    <!-- <div *ngIf="updating" id="update-product">
        <app-update-product></app-update-product>
    </div> -->
</body>