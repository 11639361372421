<app-navbar></app-navbar>
    <aside id="leftsidebar" class="sidebar fixed-top" [ngClass]="{'visible': isSidebarVisible}" style="background-color: #5dc4be;">
         <div class="menu">
            <ul class="list">
                <li>
                    <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin/chantiers/suppafacs">
                        <img src="../../assets/backoffice/images/logo BN white.png" alt="..." height="100%">
                    </a>
          
                </li>
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/parent">
                        <i class="bi bi-house-door-fill"></i><span>Accueil
                        </span>
                    </a>
                </li>
    
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="liste-enfants">
                        <i class="bi bi-people-fill"></i>
                        <span>Enfants</span>
                    </a>
                </li>

            </ul>
        </div>
</aside>
    <router-outlet></router-outlet>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>


