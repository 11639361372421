<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        Ajout d'un compte rendu
    </h4>
    <button type="button" class="close" aria-label="Close"
        (click)="onReset()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="registerForm">
        <div class="form-group">
            <label>Date du jour</label>
            <input type="date" [value]="currentDate | date:'yyyy-MM-dd'"  class="form-control"  disabled>

        </div>
        <div class="form-row">
            <div class="form-group col-6">
                <label>Heure d'arrivée</label>
                <input type="time" formControlName="arrivingTime" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.arrivingTime.errors }" />
                <div *ngIf="submitted && f.arrivingTime.errors" class="invalid-feedback">
                    <div *ngIf="f.arrivingTime.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group col-6">
                <label>Heure de départ</label>
                <input type="time" formControlName="departureTime" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.departureTime.errors }" />
                <div *ngIf="submitted && f.departureTime.errors" class="invalid-feedback">
                    <div *ngIf="f.departureTime.errors.required">Champ obligatoire.</div>
                </div>
            </div>
         </div>
        <div class="form-group">
            <label>Repas</label>
            <!-- <input type="text" formControlName="meal" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.meal.errors }" /> -->
            <ng-select
                [items]="meals"
                formControlName="meal"
                class="form-control input-custom"
                [closeOnSelect]="true"
                [ngClass]="{ 'is-invalid': submitted && f.meal.errors }"
                >
                </ng-select>
            <div *ngIf="submitted && f.meal.errors" class="invalid-feedback">
                <div *ngIf="f.meal.errors.required">Champ obligatoire.</div>
            </div>
        </div>
        <div class="form-group">
            <label>Goûter</label>
            <!-- <input type="text" formControlName="snack" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.snack.errors }" /> -->
            <ng-select
            [items]="snacks"
            formControlName="snack"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.snack.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.snack.errors" class="invalid-feedback">
                <div *ngIf="f.snack.errors.required">Champ obligatoire.</div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-2">
                <label>Selles</label>
                <br>
                <ui-switch  formControlName="salt" size="small"></ui-switch>
            </div>
            <div *ngIf="registerForm.value.salt" class="form-group col-md-10">
                <label>N° Selles</label>
                <input type="text"   min="1"
                formControlName="nbreSalt"
                 onkeypress="return /[0-9]/i.test(event.key)"
                 [(ngModel)]="numbreSaltSaisi"
                class="form-control"
                />

            </div>
         </div>
         <div class="form-row">
            <div class="form-group col-md-6">
                <label>Sieste</label>
                <ng-select
                    [items]="naps"
                    formControlName="nap"
                    class="form-control input-custom"
                    [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.nap.errors }"
                ></ng-select>
                <div *ngIf="submitted && f.nap.errors" class="invalid-feedback">
                    <div *ngIf="f.event.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Température(°C)</label>
                <ng-select
                    [items]="temperatures"
                    formControlName="temperature"
                    class="form-control input-custom"
                    [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.nap.errors }"
                ></ng-select>
                <div *ngIf="submitted && f.temperature.errors" class="invalid-feedback">
                    <div *ngIf="f.temperature.errors.required">Champ obligatoire.</div>
                </div>
            </div>
        </div>
        
         <div class="form-group">
            <label>Évènement</label>
            <!-- <input type="text" formControlName="event" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.event.errors }" /> -->
            <ng-select
            [items]="events"
            formControlName="event"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.event.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.event.errors" class="invalid-feedback">
                <div *ngIf="f.event.errors.required">Champ obligatoire.</div>
            </div>
        </div>
        <div class="form-group">
            <label>Note</label>
            <input type="text" formControlName="note" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.note.errors }" />
            <div *ngIf="submitted && f.note.errors" class="invalid-feedback">
                <div *ngIf="f.note.errors.required">Champ obligatoire.</div>
            </div>
        </div>
        <div class="text-center">
           
            <button class="btn btn-primary mr-2" type="reset"  (click)="onReset()">Annuler</button>
            <button class="btn btn-secondary" (click)="onSubmit()">Ajouter</button>
        </div>
    </form>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary"
        (click)="modal.close('Save click')">
        Annuler
    </button>
    <button type="button" class="btn btn-primary"
        (click)="onSubmit()">
        Ajouter
    </button>
</div> -->