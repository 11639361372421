import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private isSidebarVisibleSource = new BehaviorSubject<boolean>(true);
  isSidebarVisible$ = this.isSidebarVisibleSource.asObservable();

  constructor() {}

  toggleSidebarVisibility() {
    this.isSidebarVisibleSource.next(!this.isSidebarVisibleSource.value);
  }
  
}
