import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  matieres :any []=[];
  private _matiereUrl=`${environment.baseUrl}/modules`
  constructor(private http: HttpClient) { }

  public getModules() :Observable <any> {
    

    return this.http.get<any[]>(this._matiereUrl, { 'headers': headers }).pipe(response =>
      response)
      }

}
