import { Component } from '@angular/core';

import { io } from 'socket.io-client';
import { VisitorsService } from './_services/visitors.service';
//import { NotificationsService } from './_services/notifications.service';
import { NgForm } from '@angular/forms';
//import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LocationService } from './_services/location.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  locationData: any;

  private socket: any;
  public data: any;
  title = 'e-learning';
  selectedCar: number;
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
];
  constructor(private recaptchaV3Service: ReCaptchaV3Service,private locationService: LocationService) {
  
    this.socket = io('http://localhost:3007');
  }
  public ngOnInit(): void {
    this.socket.on('notification', data => {
      this.data = data;
    });
    this.locationService.getLocation().subscribe(data => {
      this.locationData = data;
      console.log("eee")
      console.log('User Location:', this.locationData);
    });
  
   
  }
 

}
