<section class="content ecommerce-page">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>
                    Détails de l'enfant :
                    <strong class="text-center text-capitalize" *ngIf="children" style="color: #9d9d9d;">
                        {{children.nom}} {{children.prenom}}</strong>
                    <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a routerLink="/puericulteur"><i class="zmdi zmdi-home"></i> Puéricultrice
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/puericulteur/liste-enfants"> Liste des enfants </a>
                    </li>
                    <li class="breadcrumb-item active">Détails enfant</li>
                </ul>
            </div>
        </div>
    </div>

    <div>
        <div class="text-center d-flex flex-column align-items-center">
            <div *ngIf="children" class="profile-image text-center">
                <input type="file" id="fileInput2" (change)="onFileSelected($event)" style="display: none;"
                    accept="image/*">
                <label for="fileInput2" class="custom-file-upload">
                    <i class="fa fa-camera"></i>
                    <span class="change-photo-text">Changer la photo de profil de l'enfant</span>
                </label>
                <img *ngIf="imageUrlReadrer" [src]="imageUrlReadrer" alt="Profile Photo"
                    class="card-img-top rounded-circle mx-auto mt-3" />
                <img *ngIf="!imageUrlReadrer && children.photoProfile"
                    src="{{ BaseUrl_profilephoto }}{{ children.key }}/{{ children.photoProfile}}" alt="Profile Photo"
                    class="card-img-top rounded-circle mx-auto mt-3" />
                <img *ngIf="!imageUrlReadrer && !children.photoProfile"
                    src="https://us.123rf.com/450wm/yupiramos/yupiramos1609/yupiramos160905313/62180608-gar%C3%A7on-souriant-enfant-heureux-enfant-visage-dessin-anim%C3%A9-vector-illustration.jpg"
                    alt="Profile Photo" class="card-img-top rounded-circle mx-auto mt-3" />

            </div>
        </div>




        <br />
        <div class="card-header bg-transparent" style="border-bottom: none !important; color: #f9656b">
            <h3 class="mb-0">
                <i class="bi bi-info-circle-fill"></i> Informations générales
            </h3>
        </div>
        <br />
        <div *ngIf="children" class="card-body" style="background-color: #f7f7f7; border: #f7f7f7">
            <div class="row">
                <div class="col-md-4">
                    <strong class="pr-1 title-info">Nom :</strong>
                    <p class="mb-3 text-capitalize">{{children.nom}}</p>
                </div>
                <div class="col-md-4">
                    <strong class="pr-1 title-info">Prénom :</strong>
                    <p class="mb-3 text-capitalize">{{children.prenom}}</p>
                </div>
                <div class="col-md-4">
                    <strong class="pr-1 title-info">Date de naissance :</strong>
                    <p class="mb-3">{{children.dateNaissance| date:'dd/MM/yyyy'}}</p>
                </div>
                <div class="col-md-4">
                    <strong class="pr-1 title-info">Adresse :</strong>
                    <p class="mb-3 text-capitalize">{{children.numero}} {{children.rue}} {{children.ville}}
                        {{children.codePostal}}</p>

                </div>
                <div class="col-md-4">
                    <strong class="pr-1 title-info">Parent :</strong>
                    <p class="mb-3 text-capitalize">{{children.parent[0].lastName}} {{children.parent[0].firstName}}</p>
                    <p *ngIf="children.parent && children.parent.length > 1" class="mb-3 text-capitalize">
                        {{children.parent[1].lastName}} {{children.parent[1].firstName}}</p>

                </div>
            </div>
        </div>
        <br />
        <div class="card-header bg-transparent" style="border-bottom: none !important; color: #f9656b">
            <div class="row">
                <div class="col-8">
                    <h3 class="mb-0"><i class="bi bi-camera-fill"></i> Listes des photos</h3>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-primary"
                        style="background-color: #f9656b !important; color: #fff !important;"
                        (click)="addPictureChildren()">Ajouter une photo</button>
                </div>
            </div>
        </div>

        <br />

        <div *ngIf="photosChildrens && photosChildrens.length > 0" class="card-body"
            style="background-color: #f7f7f7; border: #f7f7f7">
            <div id="imageCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div *ngFor="let photo of photosChildrens; let first = first" class="carousel-item text-center"
                        [class.active]="first">
                        <img src="{{ BaseUrl }}/{{ photo.filename }}" class="d-block mx-auto img-fluid" alt="Image 1">
                    </div>
                </div>
                <a class="carousel-control-prev" href="#imageCarousel" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#imageCarousel" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <div *ngIf="photosChildrens && photosChildrens.length == 0" class="card-body"
            style="background-color: #f7f7f7; border: #f7f7f7 ;text-align: center">
            <p style="padding-bottom: 45px; padding-top: 35px ">
                Aucune photo n'est trouvé
            </p>
        </div>

        <br />
        <div class="card-header bg-transparent" style="border-bottom: none !important; color: #f9656b">
            <div class="row">
                <div class="col-8">
                    <h3 class="mb-0"><i class="bi bi-receipt"></i> Comptes Rendu </h3>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-primary"
                        style="background-color: #f9656b !important; color: #fff !important;"
                        (click)="getLustStatement()">Ajouter un compte
                        rendu</button>
                </div>
            </div>
        </div>
        <br />

        <div *ngIf="statements && statements.length> 0" class="card-body"
            style="background-color: #f7f7f7; border: #f7f7f7">
            <div class="row">
                <!-- Première carte -->
                <div *ngFor="let stat of statements | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };let k =index" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                    <div class="card" style="border: #fff">
                        <div class="card-body text-center">
                            <h5 class="card-title" style="color: #f9656b;">Compte rendu {{stat.action.date |
                                date:'dd/MM/yyyy'}}</h5>
                            <div class="d-flex flex-column align-items-center">
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <strong style="color: #f9656b;"><i class="bi bi-stopwatch"></i> Heure
                                            d'arrivée:</strong>
                                        <input type="time" class="form-control" [(ngModel)]="stat.arrivingTime"
                                            name="arrivingTime" readonly>
                                    </div>
                                    <div>
                                        <strong style="color: #f9656b;"><i class="bi bi-stopwatch"></i> Heure de
                                            départ:</strong>
                                        <input type="time" class="form-control" [(ngModel)]="stat.departureTime"
                                            name="departureTime" readonly>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="d-flex" >
                                <strong class="mr-2" style="color: #f9656b;"><i class="bi bi-thermometer-high"></i>
                                    Température:</strong>
                                <p class="mb-0 text-capitalize">{{stat.temperature}}°C</p>
                            </div>
                            <div class="d-flex">
                                    <strong class="mr-2" style="color: #f9656b;"><i class="bi bi-clock"></i>
                                        Siéste:</strong>
                                    <p class="mb-0 text-capitalize">{{stat.nap}}</p>
                            </div>
                            <div class="d-flex">
                                    <strong class="mr-2" style="color: #f9656b;"><i class="bi bi-egg-fried"></i>
                                        Repas:</strong>
                                    <p class="mb-0 text-capitalize">{{ stat.meal }}</p>
                            </div>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #f9656b;"><i class="bi bi-egg-fill"></i>
                                    Goûter:</strong>
                                <p class="mb-0 text-capitalize">{{ stat.snack}}</p>
                            </div>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #f9656b;">
                                    <i class="bi bi-question"></i>
                                    Selle:
                                </strong>
                                <span *ngIf="stat.salt" class="badge badge-success">Oui</span>
                                <span *ngIf="!stat.salt" class="badge-tot-success">Non</span>
                                <p *ngIf="stat && stat.nbreSalt && stat.salt" class="mb-0 text-capitalize ml-2">{{
                                    stat.nbreSalt}} fois</p>
                            </div>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #f9656b;"><i class="bi bi-cake2"></i>
                                    Événement:</strong>
                                <p class="mb-0 text-capitalize">{{stat.event}}</p>
                            </div>
                            <br>
                            <div class="d-flex">
                                <strong class="mr-2" style="color: #f9656b;"><i class="bi bi-file-text"></i>
                                    Note:</strong>
                                <p class="text-capitalize">{{ stat.note | slice:0:15 }}{{ stat.note.length > 10? '...' :
                                    '' }}</p>
                                <div *ngIf="!stat.note" class="mb-3 title-info no-data"><span
                                        class="aucun-text">Aucune</span></div>
                            </div>
                            <div class="d-flex justify-content-center mb-2">
                                <!-- Icon for Visualization -->
                                <span class="btn btn-link">
                                    <i class="bi bi-eye" style="color: #f9656b;" data-toggle="modal"
                                        (click)="getDataStatement(stat)" data-target="#exampleModalArchiver"
                                        title="Visualiser"></i>
                                    <i class="bi bi-pencil" *ngIf="!stat.share"
                                        style="color: #f9656b;margin-left: 18px;"
                                        (click)="getDataStatement(stat);open(content)">

                                    </i>
                                </span>

                                <!-- Icon for Modification -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="statements.length > 4 ">
                <div class="d-flex justify-content-center">
                    <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                        (pageChange)="onTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
        <div *ngIf="statements && statements.length == 0" class="card-body"
            style="background-color: #f7f7f7; border: #f7f7f7;text-align: center;">
            <p style="padding-bottom: 45px; padding-top: 35px ">
                Aucun compte rendu n'est trouvé
            </p>
        </div>

        <br />
        <br />

    </div>
</section>


<!--Modal view compte rendue -->
<div class="modal fade" id="exampleModalArchiver" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div *ngIf="dataChildren" class="modal-header">
                <h3 class="modal-title" id="exampleModalLabel" style="color: #f9656b;">Compte rendu le
                    {{dataChildren.action.date| date:'dd/MM/yyyy'}}</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    style="color: #f9656b; background: none;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="dataChildren" class="modal-body">
                <ul class="list-group">.
                    <li class="d-flex justify-content-center">
                        <strong style="color:#f9656b;" class="d-inline"><i class="bi bi-stopwatch"></i> Arriver
                            à: </strong>
                        <span class="d-inline  mr-3"> {{dataChildren.arrivingTime}}</span>
                        <strong style="color:#f9656b;" class="d-inline"><i class="bi bi-stopwatch"></i> Partir
                            à: </strong>
                        <span class="d-inline"> {{dataChildren.departureTime}}</span>
                    </li>
                    <br>
                    <li class="d-flex">
                        <strong style="color:#f9656b;"><i class="bi bi-thermometer-high"></i>Température:</strong>
                        <span class="d-inline  mr-3">{{dataChildren.temperature}}°C</span>
                        <strong style="color:#f9656b;"><i class="bi bi-clock"></i>Sieste:</strong>
                        <span class="d-inline "> {{dataChildren.nap}}</span>
                    </li>
                    <li class="d-flex flex-row">
                        <strong style="color:#f9656b;" class="d-inline">
                            <i class="bi bi-egg-fried"></i> 
                            Repas: </strong>
                        <span class="d-inline"> {{dataChildren.meal}} </span>


                    </li>
                    <li class="d-flex flex-row">
                        <strong style="color:#f9656b;" class="d-inline"><i class="bi bi-egg-fill"></i> Goûter: </strong>
                        <span class="d-inline"> {{dataChildren.snack}}</span>

                    </li>
                    <li *ngIf="dataChildren.salt" class="d-flex flex-row">
                        <strong style="color:#f9656b;">
                        <i class="bi bi-question"></i> Selle: 
                        <i style="color: #28A745;" class="bi bi-circle-fill mr-2"></i> 
                        </strong>
                        {{dataChildren.nbreSalt}} fois
                    </li>
                    <li *ngIf="!dataChildren.salt" class="list-group-item"><strong style="color:#f9656b;"><i
                                class="bi bi-question"></i> Selle: </strong>
                        <i style="color: #DC3545;" class="bi bi-circle-fill"></i>
                    </li>
                    <li class="d-flex flex-row">
                        <strong style="color:#f9656b;"><i class="bi bi-cake2"></i> Événement:</strong>
                        <span class="d-block">{{dataChildren.event}}</span>
                    </li>
                    <br>
                    <li class="d-flex flex-column">
                        <strong style="color:#f9656b;"><i class="bi bi-file-text"></i> Note:</strong>
                        <div class="note-text">{{dataChildren.note}}</div>
                        <div *ngIf="!dataChildren.note" class="mb-3 title-info no-data"><span
                            class="aucun-text">Aucune</span></div>
                    </li>
                </ul>
                <div class="mt-4">
                    <div class="d-flex justify-content-end">
                        <div class="mr-3">
                            <i style="color: #28A745;" class="bi bi-circle-fill"></i> Oui
                        </div>
                        <div class="mr-3">
                            <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>
<!--Modal update compte rendue -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="color: #f9656b;">
            Modification d'un compte rendu
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Save click')">
            <span aria-hidden="true" style="color: #f9656b;">&times;</span>
        </button>
    </div>
    <div *ngIf="dataChildren" class="modal-body">
        <form [formGroup]="registerForm">
            <div class="form-group">
                <label>Date</label>
                <input type="text"  [value]="dateStat"  class="form-control"   disabled>
    
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label>Heure d'arrivée</label>
                    <input type="time" formControlName="arrivingTime" class="form-control"
                        [(ngModel)]="dataChildren.arrivingTime"
                        [ngClass]="{ 'is-invalid': submitted && f.arrivingTime.errors }" />
                    <div *ngIf="submitted && f.arrivingTime.errors" class="invalid-feedback">
                        <div *ngIf="f.arrivingTime.errors.required">Champ obligatoire.</div>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label>Heure de départ</label>
                    <input type="time" formControlName="departureTime" class="form-control"
                        [(ngModel)]="dataChildren.departureTime"
                        [ngClass]="{ 'is-invalid': submitted && f.departureTime.errors }" />
                    <div *ngIf="submitted && f.departureTime.errors" class="invalid-feedback">
                        <div *ngIf="f.departureTime.errors.required">Champ obligatoire.</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Repas</label>
                <!-- <input type="text" formControlName="meal" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.meal.errors }" /> -->
                <ng-select [items]="meals" [(ngModel)]="dataChildren.meal" formControlName="meal"
                    class="form-control input-custom" [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.meal.errors }">
                </ng-select>
                <div *ngIf="submitted && f.meal.errors" class="invalid-feedback">
                    <div *ngIf="f.meal.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group">
                <label>Goûter</label>
                <!-- <input type="text" formControlName="snack" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.snack.errors }" /> -->
                <ng-select [items]="snacks" [(ngModel)]="dataChildren.snack" formControlName="snack"
                    class="form-control input-custom" [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.snack.errors }">
                </ng-select>
                <div *ngIf="submitted && f.snack.errors" class="invalid-feedback">
                    <div *ngIf="f.snack.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-2">
                    <label>Selles</label>
                    <br>
                    <ui-switch formControlName="salt" [(ngModel)]="dataChildren.salt" size="small"></ui-switch>
                </div>
                <div *ngIf="dataChildren.salt" class="form-group col-3">
                    <label>N° Selles</label>
                    <input type="text" [(ngModel)]="dataChildren.nbreSalt" min="1" formControlName="nbreSalt"
                        onkeypress="return /[0-9]/i.test(event.key)" [(ngModel)]="numbreSaltSaisi"
                        class="form-control" />

                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Sieste</label>
                    <ng-select [items]="naps" [(ngModel)]="dataChildren.nap" formControlName="nap"
                        class="form-control input-custom" [closeOnSelect]="true"
                        [ngClass]="{ 'is-invalid': submitted && f.nap.errors }">
                    </ng-select>
                    <div *ngIf="submitted && f.nap.errors" class="invalid-feedback">
                        <div *ngIf="f.event.errors.required">Champ obligatoire.</div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Température(°C)</label>
                    <ng-select [items]="temperatures" [(ngModel)]="dataChildren.temperature"
                        formControlName="temperature" class="form-control input-custom" [closeOnSelect]="true"
                        [ngClass]="{ 'is-invalid': submitted && f.nap.errors }">
                    </ng-select>
                    <div *ngIf="submitted && f.temperature.errors" class="invalid-feedback">
                        <div *ngIf="f.temperature.errors.required">Champ obligatoire.</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Évènement</label>
                <!-- <input type="text" formControlName="event" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.event.errors }" /> -->
                <ng-select [items]="events" formControlName="event" [(ngModel)]="dataChildren.event"
                    class="form-control input-custom" [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.event.errors }">
                </ng-select>
                <div *ngIf="submitted && f.event.errors" class="invalid-feedback">
                    <div *ngIf="f.event.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group">
                <label>Note</label>
                <input type="text" formControlName="note" class="form-control" [(ngModel)]="dataChildren.note"
                    [ngClass]="{ 'is-invalid': submitted && f.note.errors }" />
                <div *ngIf="submitted && f.note.errors" class="invalid-feedback">
                    <div *ngIf="f.note.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="text-center">

                <button (click)="modal.close('Save click')" class="btn btn-primary mr-2" type="reset">Annuler</button>
                <button class="btn btn-secondary" (click)="onSubmit(dataChildren)">Ajouter</button>
            </div>
        </form>

    </div>
</ng-template>