<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Liste des puéricultrices
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/directeur"><i class="zmdi zmdi-home"></i>
                                Directeur</a>
                        </li>
                        <li class="breadcrumb-item active">Puéricultrices</li>
                        <li class="breadcrumb-item active">Liste des puéricultrices</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list p-3" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                        <div class="body table-responsive">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <input id="search-input" type="search" #myInput class="form-control"
                                        [(ngModel)]="firstName" [(ngModel)]="lastName" [(ngModel)]="email" [(ngModel)]="phone" (input)="search()" placeholder="Recherche"
                                            style="border-radius: 5rem 5rem 5rem 5rem; width: 70%" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" style="
                          border-radius: 5rem 5rem 5rem 5rem;
                          background-color: #f9656b !important;
                        ">
                                                <i class="zmdi zmdi-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <button routerLink="ajout-puericulteur" class="btn btn-primary ajout"
                                        style="background-color: #f9656b !important">
                                        <i class="bi bi-person-video3"></i> Ajouter une puéricultrice 
                                    </button>
                                </div>
                            </div>

                            <br>

                            <table class="table table-striped">

                                <tr>
                                    <th class="text-center">Nom</th>
                                    <th class="text-center">Prénom</th>
                                    <th class="text-center">Email</th>
                                    <th class="text-center">Numéro de téléphone</th>
                                    <!-- <th>Matières</th> -->
                                    <th class="text-center">Compte</th>
                                    <th class="text-center">Actions</th>
                                </tr>


                                <tr *ngFor="let i of enseignants | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                let k = index
                              ">


                                    <td class="text-center text-capitalize"><span class="text-muted">{{i.firstName}}</span> </td>
                                    <td class="text-center text-capitalize"><span class="text-muted">{{i.lastName}}</span> </td>
                                    <td class="text-center"><span class="text-muted">{{i.email}}</span> </td>
                                    <td class="text-center"><span class="text-muted">{{i.phone}}</span> </td>


                                    <!-- <td>
                                        <ul *ngFor="let m of i.matiere">
                                            <li>{{m.nom}}</li>
                                        </ul>
                                    </td> -->
                                    <td class="text-center" *ngIf="i.status == true "> <ui-switch size="small" checked
                                        (click)="changeStatus(i)"> </ui-switch></td>
                                <td class="text-center" *ngIf="i.status == false "> <ui-switch size="small"
                                        (click)="changeStatus(i)"></ui-switch></td>
                                    <!-- <td (click)="see_details()"><span
                                                        [ngClass]="{ 'col-green': product.article_status === 'Publish', 'col-red': product.article_status === 'Draft', 'col-amber': product.article_status === 'Pending' }">
                                                        article_status</span></td> -->
                                    <td class="text-center">
                                        <button (click)="updateEnseignant(i._id)"
                                            class="btn btn-default waves-effect waves-float waves-green"><i
                                                class="zmdi zmdi-edit"></i></button>

                                        <button (click)="open(content)"
                                            class="btn btn-default waves-effect waves-float waves-red"><i
                                                class="zmdi zmdi-delete"></i>
                                            <ng-template #content let-modal>
                                                <div class="modal-header">
                                                    <h4 class="modal-title" id="modal-basic-title">
                                                        Confirmation de suppression
                                                    </h4>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="form-group">
                                                        <label>Êtes-vous sûr de vouloir supprimer cette puéricultrice </label>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                        (click)="modal.close('Save click')">
                                                        Annuler
                                                    </button>
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="deleteEnseignant(i)">
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </button>


                                    </td>
                                </tr>

                            </table>

                            <div *ngIf="enseignants && enseignants.length > 7" class="card-footer border-0 py-5"
                                style="background-color: white;">
                                <div class="d-flex justify-content-center">
                                    <pagination-controls class="my-pagination" previousLabel="Précédent"
                                        nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                            <div *ngIf="enseignants && enseignants.length == 0" class="table-responsive" style="text-align: center">
                                <p style="padding-bottom: 45px; padding-top: 35px ">
                                    Aucun puéricultrice  n'est trouvé
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>






</body>