<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-7 col-md-6 col-sm-12">
        <h2 style="color: #5dc4be !important">Liste des comptes rendus</h2>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item">
            <a routerLink="/parent"><i class="zmdi zmdi-home"></i> Parent</a>
          </li>
          <li class="breadcrumb-item active">
            <a routerLink="/parent/liste-enfants">Liste des enfants</a>
          </li>
          <li class="breadcrumb-item active">
            Liste des comptes rendus
          </li>
          <!-- <li class="breadcrumb-item active">Liste des photos</li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card product_item_list">
          <div class="body table-responsive">
            <div class="row">
              <div *ngFor="let i of statements" class="col-lg-3 col-md-6 col-sm-12 text-center">
                <div class="card d-flex align-items-center justify-content-center">
                  <div class="card-body text-center">
                    <i class="bi bi-person-vcard" style="font-size: 4rem;color: #5dc4be;"></i>
                    <h5 class="card-title"
                      style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600; color: #5dc4be;">
                      {{i.action.date| date:'dd/MM/yyyy'}}</h5>
                    <button id="but" data-toggle="modal" (click)="getDataStatement(i)"
                      data-target="#exampleModalArchiver">
                      Voir détails
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="statements && statements.length == 0" class="table-responsive" style="text-align: center">
                <p style="padding-bottom: 45px; padding-top: 35px">
                  Aucun compte rendu n'est trouvé
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<!--Modal-->
<div class="modal fade" id="exampleModalArchiver" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div *ngIf="dataChildren" class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel" style="color: #fff;">Compte rendu le {{dataChildren.action.date |
          date:'dd/MM/yyyy'}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="color: #fff; background: none;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="dataChildren" class="modal-body">
        <div class="row">
          <div class="col-12">
            <ul class="list-group">
              <li class="list-group-item">
                <strong style="color:#5dc4be;"><i class="bi bi-egg-fried"></i> Repas:</strong>
                <span class="d-block">{{dataChildren.meal}}</span>
              </li>
              <li *ngIf="dataChildren.salt" class="list-group-item"><strong style="color:#5dc4be;"><i
                    class="bi bi-question"></i> Sel: </strong>
                <i style="color: #28A745;" class="bi bi-circle-fill"></i>
              </li>
              <li *ngIf="!dataChildren.salt" class="list-group-item"><strong style="color:#5dc4be;"><i
                    class="bi bi-question"></i> Sel: </strong>
                <i style="color: #DC3545;" class="bi bi-circle-fill"></i>
              </li>
              <li class="list-group-item">
                <strong style="color:#5dc4be;"><i class="bi bi-cake2"></i> Événement:</strong>
                <span class="d-block">{{dataChildren.event}}</span>
              </li>
              <li class="list-group-item"><strong style="color:#5dc4be;"><i class="bi bi-thermometer-high"></i>
                  Température:</strong> {{dataChildren.temperature}}°C</li>
              <li class="list-group-item"><strong style="color:#5dc4be;"><i class="bi bi-clock"></i> Sieste:</strong>
                {{dataChildren.nap}}h</li>
              <li class="list-group-item">
                <strong style="color:#5dc4be;"><i class="bi bi-file-text"></i> Note:</strong>
                <div class="note-text">{{dataChildren.note}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-4">
          <div class="d-flex justify-content-end">
            <div class="mr-3">
              <i style="color: #28A745;" class="bi bi-circle-fill"></i> Oui
            </div>
            <div class="mr-3">
              <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>