<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Modifier un utilisateur
                        <!-- <small class="text-muted">Bienvenue à elearning Backoffice</small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            Accueil</a>
                        </li>
                        <li class="breadcrumb-item active"><a routerLink="/Super-Admin/utilisateurs">Utilisateurs</a></li>
                        <li class="breadcrumb-item active">Modifier un utilisateur</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="main-content">
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <form [formGroup]="registerForm"  *ngIf="this.registerForm" (ngSubmit)="onSubmit()" >
                                        <div class="card-body">
                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Nom
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="firstName" class="form-control" 
                                                        placeholder="Entrez le nom du l'utilisateur"  [(ngModel)]="user.firstName"
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.firstName.errors }" />  

                                                        <div *ngIf="submitted && fval_2.firstName.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.firstName.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                               
                                            </div>

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Prénom
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="lastName" class="form-control" 
                                                        placeholder="Entrez le prenom du l'utilisateur"  [(ngModel)]="user.lastName"
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.lastName.errors }" />
                                                       
                                                        <div *ngIf="submitted && fval_2.lastName.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.lastName.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Email
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="email" formControlName="email" class="form-control" 
                                                        placeholder="Entrez le l'adresse mail du l'utilisateur" [(ngModel)]="user.email"
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.email.errors }" /> 

                                                        <div *ngIf="submitted && fval_2.email.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.email.errors.required">Champs obligatoire</div>
                                                            <div *ngIf="fval_2.email.errors.email">Merci de saisir une adresse email valide.
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Mot de passe
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="password" formControlName="password" class="form-control" 
                                                        placeholder="vous pouvez changer le mot de passe du l'utilisateur" [(ngModel)]="user.password"
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.password.errors }" />  
                                                        
                                                        <div *ngIf="submitted && fval_2.password.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.password.errors.pattern">Le Mot de passe de avoir au moinns 8 caractéres telque au moins un lettre minuscule
                                                                et Lettre  majuscule et un chiffre
                                                                
                                                               </div>
                                                            <div *ngIf="fval_2.password.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Confirmer le mot de passe
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="password" formControlName="confirmpassword" class="form-control" 
                                                        placeholder="Confirmer le mot de passe "
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.confirmpassword.errors }" />  
                                                        
                                                        <div *ngIf="submitted && fval_2.confirmpassword.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.confirmpassword.errors.pattern">Le Mot de passe de avoir au moinns 8 caractéres telque au moins un lettre minuscule
                                                                et Lettre  majuscule et un chiffre
                                                                
                                                               </div>
                                                            <div *ngIf="fval_2.confirmpassword.errors.required">Champs obligatoire</div>
                                                            <div *ngIf="fval_2.confirmpassword.errors.mustMatch">le mot de passe et la confirmation du mot de passe ne sont pas les mêmes </div>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Numéro de téléphone</label>
                                                <div class="col-sm-12 col-md-7">
                                                    <ngx-intl-tel-input
                                                    [cssClass]="'form-control input-custom-phone'"
                                                    [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="contryIsoSelected"
                                                    [maxLength]="15"
                                                    [phoneValidation]="true"
                                                    name="phone"
                                                    (countryChange)="onCountryChange($event)"
                                                    [formControl]="registerForm.get('phone')"
                                                    ></ngx-intl-tel-input>
                                            
                                                    <div *ngIf="submitted && fval_2.phone.errors" class="invalid-feedback-tel">
                                                        <div *ngIf="fval_2.phone.errors.required">Champ obligatoire</div>
                                                        <div *ngIf="registerForm.value.phone && fval_2.phone.valid" style="color: green;">
                                                            Numéro de téléphone validé <i class="bi bi-check"></i>
                                                        </div>
                                                        <div *ngIf="registerForm.value.phone && fval_2.phone.invalid" style="color: red;">
                                                            Numéro de téléphone non validé <i class="bi bi-x"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Établissement</label>
                                                <div *ngIf="etbalissements" class="col-sm-12 col-md-7">
                                                  <ng-select
                                                    [items]="etbalissements"
                                                    bindLabel="firstName"
                                                    bindValue="_id"
                                                    formControlName="etablissement"
                                                    [(ngModel)]="user.etablissement._id"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && fval_2.etablissement.errors }"
                                                    
                                                  >
                                                  </ng-select>
                                              
                                                  <div *ngIf="submitted && fval_2.etablissement.errors" class="invalid-feedback">
                                                    <div *ngIf="fval_2.etablissement.errors.required">Champs obligatoire</div>
                                                  </div>
                                                </div>
                                              </div>
                                              

                                              <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Rôle</label>
                                                <div class="col-sm-12 col-md-7">
                                                  <ng-select
                                                    [items]="roles"
                                                    bindLabel="type"
                                                    bindValue="_id"
                                                    formControlName="role"
                                                    [(ngModel)]="user.role._id"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && fval_2.role.errors }"
                                                    placeholder="Changer le rôle pour l'utilisateur"
                                                  >
                                                  </ng-select>
                                              
                                                  <div *ngIf="submitted && fval_2.role.errors" class="invalid-feedback">
                                                    <div *ngIf="fval_2.role.errors.required">Champs obligatoire</div>
                                                  </div>
                                                </div>
                                              </div>
                                            <div style="padding-top: 3%;" class="text-center">
              
                                                <button routerLink="/Super-Admin/utilisateurs" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
                                                <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
                                            </div>
                                  
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
   
</body>