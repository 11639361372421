<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Résultats de recherche
                      
                        
                                 
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                   
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            SuperAdmin</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>
      
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list">
                        <div class="body table-responsive">
                       
                          </div>
                            <table *ngIf="verifUser" class="table table-striped">
                                   
                           <tr>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                        <th >Email</th>
                                        <th >Numéro de téléphone</th>
                                        <th >Rôle</th>
                                        <th >Etat</th>
                                        <th >Établissement</th>
                                         
                                    </tr>
                               
                                
                                    <tr *ngFor="let i of searchsUser">
                                        
                                       
                                        <td ><span class="text-muted">{{i.firstName}}</span>
                                        </td>
                                        <td ><span class="text-muted">{{i.lastName}}</span>
                                        </td>
                                        <td ><span class="text-muted">{{i.email}}
                                            </span></td>
                                            <td ><span class="text-muted">{{i.phone}}
                                            </span></td>
                                            <td ><span class="text-muted">{{i.role.type}}
                                            </span></td>
                                            <td ><strong class="text-muted">{{i.etat}}
                                            </strong></td>
                                            <td ><span class="text-muted">{{i.etablissement.firstName}}
                                            </span></td>
                                        <!-- <td (click)="see_details()"><span
                                                        [ngClass]="{ 'col-green': product.article_status === 'Publish', 'col-red': product.article_status === 'Draft', 'col-amber': product.article_status === 'Pending' }">
                                                        article_status</span></td> -->
                                       
                                    </tr>

                             
                            </table>

                            <table *ngIf="VerifRole" class="table table-striped">
                                   
                                <tr>
                                             <th>Type</th>
                                             
                                              
                                         </tr>
                                    
                                     
                                         <tr *ngFor="let i of this.searchs[3]">
                                             <td ><span class="text-muted">{{i.type}}</span>
                                             </td>
                                         </tr>
     
                                  
                           </table>

                           <table *ngIf="VerifEtab" class="table table-striped">
                               
                            <tr>
                                 <th>Nom</th>
                                 <th>Numéro de registre</th>
                                 <th>Pays</th>
                                 <th>Adresse</th>
                                 <th>Téléphone Fixe </th>
                                 <th>Site web</th>
                                 <th>Type</th>
                                 <th >Rôles</th>
                                
                                
                            </tr>
                       
                        
                            <tr *ngFor="let i of searchsEtabl 
                      ">
                                
                               
                                <td ><span class="text-muted">{{i.firstName}}</span> </td>
                                <td ><span class="text-muted">{{i.numeroRegister}}</span> </td>
                                <td ><span class="text-muted">{{i.pays}}</span> </td>
                                <td ><span class="text-muted">{{i.adresse}}</span> </td>
                                <td ><span class="text-muted">{{i.telephoneFixe}}</span> </td>
                                <td ><a [href]="i.url" target="_blank">{{i.siteWeb}}</a></td>
                                <td ><a></a><span class="text-muted" target="_blank">{{i.type}}</span> </td>
                                <td >
                                     <ul *ngFor="let r of i.role" >
                                            <li>{{r.type}}</li>
                                     </ul>
                                </td>
                              
                                    
                                
                               
                               
                            </tr>

                     
                           </table>

                        </div>
                    </div>
                  
                   
                </div>
            </div>
       
   
    </section>



</body>
