import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/_models/user.model';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  user :User = new User();
  firstName:string
  lastName:string
  email:string
  phone:string
  closeResult = '';
  message="";
  message2=""
  message3="suppression avec succès"
  show:boolean ;
  enabled;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  users: User[] = [];
  copyListUser:any;
  constructor(private userService :UserService,private router: Router, private route: ActivatedRoute,private modalService: NgbModal,public iziToast: Ng2IzitoastService,) {
    
 
  }
    
  ngOnInit(): void {
    this.getUsers();
 
  }
  
  
  getUsers(){
    
    this.userService.getUsers().subscribe(e=>{
     
        this.users=e;
        this.copyListUser=this.users;
       
        for(let i of e)
        {
             this.enabled=i.status
            
        }
          })
  }
  deleteUser(role:User)
{
 
this.userService.deleteUser(role._id).subscribe(e=>
  {
   
    this.show=true;
    this.iziToast.show({
      message: "Supression avec succès",
      messageColor: "#b81414",
      progressBarColor: "#e3a1a1",
      icon: "bi-check-circle",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#f8e8e8",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
    });
    this.getUsers()
     
  })
  this.modalService.dismissAll()
} 

updateUser(id:string)
{
  
  this.router.navigate(['Super-Admin/utilisateurs/modification-utilisateur',id])
  
}
search()
{ 
  this.users = this.copyListUser
  let filteredUser = [...this.users];
  if(this.firstName != "" || this.lastName != "" || this.email != "" || this.phone != "")
   {
    filteredUser = this.users.filter(res=>{
    return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
    || res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) 
    || res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase())
    || res.phone.toLocaleLowerCase().match(this.phone.toLocaleLowerCase()) 
  })
  this.users = filteredUser;
   }
   else if(this.firstName == "" && this.lastName == "" && this.email == "" && this.phone == "")
   {
     this.getUsers();
   }
}

open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

open2(content2) {
  this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}



changeStatus(user:User)
{
 
  this.userService.EditSatutsUser(user._id).subscribe(e=>
    {
      this.iziToast.show({
        message: "Archivé avec succès",
        messageColor: "#b81414",
        progressBarColor: "#e3a1a1",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#f8e8e8",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      }); 
      this.getUsers();
    })
   
    this.modalService.dismissAll()
  } 

  onTableDataChange(event: any) {
    this.page = event;
    this.getUsers();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getUsers();
  }
getRoleBadgeClass(roleType: string): string {
  switch (roleType) {
      case 'Directeur':
          return 'primary'; // Utilisez les classes Bootstrap pour la couleur correspondante
      case 'Enseignant':
          return 'success';
      case 'Parent':
          return 'info';
      default:
          return 'secondary';
  }
}
}

