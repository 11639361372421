import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/_models/user.model';
import { EnseignantService } from 'src/app/_services/enseignant.service';

@Component({
  selector: 'app-enseignants',
  templateUrl: './enseignants.component.html',
  styleUrls: ['./enseignants.component.scss']
})
export class EnseignantsComponent implements OnInit {

  //etbalissement :Etablissement = new Etablissement();
  firstName:string
  lastName:string
  email:string
  phone:string
  taille:number ;
  //nbreEtablissements ;
  
  //lastName:string // comentithom
  //email:string    //comentithom
  //error :boolean=false ;
  //eroorMessage :String="Il faut remplir e champs,il est obligatoire.";
  enseignants: User[] = [];
  copyListEnseignant:any;
  //roles :Role [] =[];
  i
  closeResult = '';
  message="";
  message2=""
  message3="Suppression avec succès "
  show :boolean=false;
  enabled;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  
  constructor(private enseignantService :EnseignantService,private router: Router,
     private route: ActivatedRoute,private modalService: NgbModal,public iziToast: Ng2IzitoastService,) 
  {
   
   }
  ngOnInit(): void {
   
    this.getEnseignants();
   
    
    
  }

 

  getEnseignants(){
    this.enseignantService.getEnseignants().subscribe(e=>{
     
        this.enseignants=e;
        this.copyListEnseignant=this.enseignants;
        for(let i of e){
          for ( let m of i.matiere)
          { 
          }
                    this.enabled=i.status
        }
          })
         
  }
  

 
  deleteEnseignant(enseignant:User)
{
this.enseignantService.deleteEnseignant(enseignant._id).subscribe(e=>
  {
    this.show=true;
    this.iziToast.show({
      message: "Supression avec succès",
      messageColor: "#b81414",
      progressBarColor: "#e3a1a1",
      icon: "bi-check-circle",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#f8e8e8",
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
    });
    this.getEnseignants();
  })
  this.modalService.dismissAll()
} 

updateEnseignant(id:string)
{
  this.router.navigate(['directeur/puericulteurs/modification-puericulteur',id])
}


search()
{
  this.enseignants=this.copyListEnseignant
  let filteredEnseignant = [...this.enseignants]
  if(this.firstName != "" || this.lastName != "" || this.email != "" || this.phone != ""   )
   {
  filteredEnseignant= this.enseignants.filter(res=>{
    return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
    || res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) 
    || res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase())
    || res.phone.toLocaleLowerCase().match(this.phone.toLocaleLowerCase()) 
  })
  this.enseignants= filteredEnseignant
   }
   else if(this.firstName == "" && this.lastName == "" && this.email == "" && this.phone == "")
   {
     this.getEnseignants();
   }
}



open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
open2(content2) {
  this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}



changeStatus(user:User)
{
  this.enseignantService.EditSatutEnseignant(user._id).subscribe(e=>
    {
      this.getEnseignants();
    })
    this.modalService.dismissAll()
  } 
  onTableDataChange(event: any) {
    this.page = event;
    this.getEnseignants();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getEnseignants();
  }
  getBadgeClass(etat: string): string {
    switch (etat) {
      case 'Activé':
        return 'success'; // Utilisez les classes Bootstrap pour la couleur correspondante
    default:
        return 'warning';
    }
  }
}
