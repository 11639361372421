import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { io } from 'socket.io-client';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
@Component({
  selector: 'app-templ-parent',
  templateUrl: './templ-parent.component.html',
  styleUrls: ['./templ-parent.component.scss']
})
export class TemplParentComponent implements OnInit {
  captcha :string;
  email :string
  currentUser: User;
  isSidebarVisible: boolean = true;
  message: "";
  id :string;
  notifEtat ;
  notifications;
  private socket: any;
  public data: any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private token :TokenStorageService,
    private notificationService :NotificationService,
    private sharedService: SharedService,
    public iziToast: Ng2IzitoastService,
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.socket = io('http://localhost:3007');
   
  }

  ngOnInit(): void {
    this.captcha = '';
    this.email = 'khalilmeddeb2@gmail.com';
  
    this.id = this.route.snapshot.params['id'];
    this.currentUser = this.token.getUser();

    this.socket.on('tp', data => {
      
      this.data = data;
      
     
      if(this.currentUser[0]._id == data.id)
      {
        this.iziToast.show({
          message: data.message,
          messageColor: "#386641",
          progressBarColor: "#386641",
          icon: "bi bi-bell-fill",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#fff",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
      }
    })
    this.sharedService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
    };
  

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/Connexion']);
  }

  changerEtat()
  {
   this.notifEtat = !this.notifEtat;
   this.getNotifications();
  }
  getNotifications(){
    
    
   this.notificationService.getNotificationsByEnseignant().subscribe(e=>{
    
       this.notifications=e
          })   
  }

  onChange(id)
  {
    this.notificationService.getChangeSelect(id).subscribe(e=>{
    
         })   

  }

  public resolved(captchaResponse :string)
  {
    this.captcha =captchaResponse
  }

  notif() {
    
    this.router.navigate(['/Parent/liste-enfants']);
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
}

