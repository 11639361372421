import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { User } from 'src/app/_models/user.model';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-update-informations',
  templateUrl: './update-informations.component.html',
  styleUrls: ['./update-informations.component.scss']
})
export class UpdateInformationsComponent implements OnInit {

  iduser: any;
  d: any;
  user:User=new User();
  user2:User=new User();

  constructor(private route :ActivatedRoute,private userService:UserService,private router:Router,public iziToast: Ng2IzitoastService,) { }

  ngOnInit(): void {
    this.iduser = this.route.snapshot.params['id'];
    this.d=this.userService.getUserById(this.iduser).subscribe(async data=>{
      this.user=data;
      this.user.password="";
      
  })

}

onSubmit()
   {
   
     this.userService.EditUser(this.iduser , this.user).subscribe( data=>{
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
      this.goToCategoriesList();
       
     })
   }
goToCategoriesList()
{
  this.router.navigate(['/puericulteur/profil']);
}

back()
{
 this.router.navigate(['puericulteur/profil'])
}

}
