<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>
                        Modifier une activité
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item">
                            <a routerLink="/directeur"><i class="zmdi zmdi-home"></i> Directeur</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/directeur/activites">Activités</a>
                        </li>
                        <li class="breadcrumb-item active">Modifier une activité</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="main-content">
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card p-3" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                                    <form enctype="multipart/form-data">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="col-md-12">
                                                    <label class="col-form-label">Nom</label>
                                                    <input type="text" class="form-control" name="nom"
                                                        placeholder="Entrez le nom de la matiére"
                                                        [(ngModel)]="matiere.nom" />
                                                </div>
                                            </div>

                                            <div class="text-center">
                                                <button routerLink="/directeur/activites"
                                                    class="btn btn-secondary mr-4 button_list" type="reset">
                                                    Annuler
                                                </button>
                                                <button class="btn btn-primary button_list" (click)="onSubmit()"
                                                    value="submit" type="submit">
                                                    Modifier
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</body>