import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  //product :any []=[];
  private _notificationlUrl=`${environment.baseUrl}/notifications` 
  constructor(private http: HttpClient) { }

      getNotificationsByEnseignant() :Observable <any>
      {
       return this.http.get(`${this._notificationlUrl}/enseignant`);
      }

      getNotificationsByEtudiant() :Observable <any>
      {
       return this.http.get(`${this._notificationlUrl}/etudiant`);
      }

      getChangeSelect(id :string)
      {
        return this.http.get(`${this._notificationlUrl}/${id}/selected`);
      }
   
}
