import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { Actualite } from 'src/app/_models/actualite';
import { ActualiteService } from 'src/app/_services/actualite.service';

@Component({
  selector: 'app-add-actualite',
  templateUrl: './add-actualite.component.html',
  styleUrls: ['./add-actualite.component.scss']
})
export class AddActualiteComponent implements OnInit {
  
  @ViewChild('fileInput' , {static:false}) fileInput :ElementRef;

  actualites :any
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres ;
  classes ;
  show;
  numberTotalNews: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actualiteService: ActualiteService,
    private toastr: ToastrService
  ) { 
   
  }

  ngOnInit(): void {
  
    this.registerForm = this.formBuilder.group({
      titre: ['', Validators.required],     
      description:  ['', Validators.required],
      image: [
        '',
        [
          Validators.required,
          Validators.pattern("[^\\s]+(.*?)\\.(png|PNG|gif|GIF|jpg|JPG|jpeg|JPEG|mp4|MP4 )$"),
        ],
      ],
      url :[''],
    });
    this.getActualites()
  }

  
  get fval_2() {
    return this.registerForm.controls;
  }
  
 

  saveActualite() {
    let titleToUdapte = this.registerForm.value.titre
    titleToUdapte = titleToUdapte.replace(/\W+(?!$)/g, '-').toLowerCase();
    titleToUdapte = titleToUdapte.replace(/\W$/, '').toLowerCase();
    this.numberTotalNews =this.numberTotalNews+1
    this.registerForm.value.url =titleToUdapte+"-"+this.numberTotalNews

    const imagBlob =this.fileInput.nativeElement.files[0];
   
    const form =new FormData();
    form.set('image',imagBlob);
    form.set('titre',this.registerForm.value.titre);
    form.set('description',this.registerForm.value.description);
    form.set('url',this.registerForm.value.url);
    this.submitted = true;
   
    if (this.registerForm.invalid) {
     
      return;
    }
   
    this.actualiteService.createActualite(form).subscribe(
      (data) => {
       
        
        this.toastr.success('Ajout avec succés')
        this.goToList();
      },
    );
   
  }
  goToList(){
    this.router.navigate(['/Super-Admin/actualités' ])
    }
    onSubmit(){
    
      this.saveActualite();
    }
    editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: "15rem",
      width :"63%",
      minHeight: "5rem",
      placeholder: "Enter text here...",
      translate: "no",
      defaultParagraphSeparator: "p",
      defaultFontName: "Arial",
      toolbarHiddenButtons: [["bold"]],
      
      customClasses: [
        {
          name: "quote",
          class: "quote"
        },
        {
          name: "redText",
          class: "redText"
        },
        {
          name: "titleText",
          class: "titleText",
          tag: "h1"
        }
      ]
    };


    getActualites(){
      this.actualiteService.getActualites().subscribe(e=>{
          this.actualites=e
          this.numberTotalNews = this.actualites.length

             })   
     }
}
