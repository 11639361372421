import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { User } from 'src/app/_models/user.model';
import { UserService } from 'src/app/_services/user.service';
//import { MustMatch } from 'src/app/_helpers/must-match.validator';
//import {ConfirmedValidator} from '../validator';
@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  id:string;
  user:User=new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  eroorMessage :string;
  
  d;
  error: boolean =false;
  user2:User=new User();
  idPath;
  ids;
 
 
 
  constructor(private userService :UserService,
    private router: Router, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,
   ) {}

  ngOnInit(): void {
  
    this.id = this.route.snapshot.params['id'];
    this.d=this.userService.getUserById(this.id).subscribe(data=>{
      
      this.user=data;
     


      this.registerForm = this.formBuilder.group({
        
        password: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
        newpassword: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
        confirmpassword: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
        
        //policy_checked: [false, Validators.required],
      },
      {
        validator: MustMatch('newpassword', 'confirmpassword')
    }
      );

    
      this.idPath=this.router.url.split('/');
    this.ids=this.idPath[2]
     })
    
    
   
  }
 

  get fval_2() {
    return this.registerForm.controls;
  }
  onSubmit()
  {
    this.user.password=this.user2.password
    this.submitted = true;
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    //this.loading = true;
    //this.registerForm.value.phone="+"+this.countryCode+this.registerForm.value.phone;
    this.userService.EditPasswordUser(this.id , this.registerForm.value).subscribe( (data) => {
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
     this.goToCategoriesList();
      
    },
    (error) => {
      //this.toastr.error(error.error.message, 'Error');
      this.loading = false;
      this.error=true
      this.eroorMessage=error.error
      //this.toastr.info(this.eroorMessage) 
      
    }
  );
  }
  
  goToCategoriesList()
  {
    this.router.navigate(['/puericulteur/profil']);
  }
  
  back()
  {
   this.router.navigate(['puericulteur/profil'])
  }

}


