
<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Liste des actualités
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                        
                                 
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <button class="btn btn-primary btn-icon btn-round hidden-sm-down float-right m-l-10"
                        routerLink="addActualite" style="color: white !important;">
                        <i class="zmdi zmdi-plus"></i>
                    </button>
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            SuperAdmin</a>
                        </li>
                        <li class="breadcrumb-item active">Actualités</li>
                        <li class="breadcrumb-item active">Liste des actualités</li>
                    </ul>
                </div>
            </div>
        </div>
      
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list">
                        <div class="body table-responsive">
                            <button routerLink="ajout-actualité" class="ajout"><i class="zmdi zmdi-widgets">Ajouter une actualité</i></button>
                            <!--
                            <ul class="nav navbar-nav navbar-left">
                 
                                <li class="hidden-sm-down">
                                   <div class="input-group">
                                       <input type="text" #myInput class="form-control" [(ngModel)] ="firstName" [(ngModel)] ="lastName" [(ngModel)] ="email" (input)="search()"  placeholder="Search...">
                                       <span class="input-group-addon">
                                           <i class="zmdi zmdi-search"></i>
                                       </span>
                                   </div>
                               </li> 
                           </ul>
                        -->
                        <!-- <div class="input-group">
                            <div >
                              <input id="search-input" type="search" #myInput class="form-control" [(ngModel)] ="firstName" [(ngModel)] ="lastName" [(ngModel)] ="email" (input)="search()" placeholder="recherhe" />
                              
                            </div>
                           
                            <button  type="button" >
                                <i class="zmdi zmdi-search"></i>
                            </button>
                           
                          </div> -->
                          <!-- <div class="col-sm" *ngIf= "message"  class="alert alert-success" role="alert" >
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong>{{message}}</strong>
                        </div>
                        <div class="col-sm" *ngIf= "message2"  class="alert alert-warning"  role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> {{message2}}</strong>
                        </div>
                        <div class="col-sm" *ngIf= "show == true"  class="alert alert-danger" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> {{message3}} </strong>
                        </div> -->
                     <!--
                        <div class="col-sm" *ngIf= "enabled == false"  class="alert alert-danger" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> Désactivation d'un compte  </strong>
                        </div>
                    -->
                           <br>
                           <br>
                           <div style="cursor: move;" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                            <table class="table table-striped">
                                   
                           <tr>
                                        <th>Titre</th>
                                        <th >Date</th>
                                        <th >Image</th>
                                        
                                        <th >Actions</th>
                                    </tr>
                               
                                
                                    <tr *ngFor="let i of actualites  | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                let k = index 
                              " cdkDrag>
                                        
                                       
                                        <td style="text-transform: capitalize;" >
                                            <span class="text-muted">{{ (i.titre.length>30)? (i.titre | slice:0:20)+'...':(i.titre)}}</span>
                                        </td>

                                          
                                        
                                        <td ><span class="text-muted">{{i.date  | date: 'dd-MM-yyyy'}}
                                            </span></td>
                                            <td> <img style="width:140px ;height:600 px;"
                                                [src]= "i.filename" 
                                                >  </td>
                                            
                                        <!-- <td (click)="see_details()"><span
                                                        [ngClass]="{ 'col-green': product.article_status === 'Publish', 'col-red': product.article_status === 'Draft', 'col-amber': product.article_status === 'Pending' }">
                                                        article_status</span></td> -->
                                        <td>
                                            <button  (click)="updateActualite(i._id)" class="btn btn-default waves-effect waves-float waves-green"><i
                                                    class="zmdi zmdi-edit"></i></button>
                                            <button  (click)="open(content)"class="btn btn-default waves-effect waves-float waves-red"><i
                                                    class="zmdi zmdi-delete"></i>
                                                    <ng-template #content let-modal>
                                                        <div class="modal-header">
                                                          <h4 class="modal-title" id="modal-basic-title">Confirmation de suppression</h4>
                                                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                            <span aria-hidden="true">&times;</span>
                                                          </button>
                                                        </div>
                                                        <div class="modal-body">
                                                          
                                                            <div class="form-group">
                                                             <label>Êtes-vous sûr de vouloir supprimer cette actualité ?</label>
                                                            </div>
                                                         
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-outline-dark" (click)="deleteActualite(i)">Supprimer</button>  
                                                          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Annuler</button>
                                                        </div>
                                                      </ng-template>      
                                                </button>

                                                
                                        </td>
                                    </tr>

                             
                            </table>
                        </div>
                        </div>
                    </div>
                  
                    
                    <!-- <div [ngSwitch]="view">
                    
                        <mwl-calendar-month-view (dayClicked)="dayClicked($event.day)" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events">
                        </mwl-calendar-month-view>
                    
                        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events">
                        </mwl-calendar-week-view>
                    
                        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events">
                        </mwl-calendar-day-view>
                    
                    </div> -->
                   


                </div>
            </div>
        </div>
   
    </section>



</body>
