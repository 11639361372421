<section class="content home">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Liste des classes
                    <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a routerLink="/Enseignant"><i class="zmdi zmdi-home"></i>
                        Puéricultrice </a>
                    </li>
                    <li class="breadcrumb-item active">Liste des classes</li>
                </ul>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card product_item_list">
                    <div class="body table-responsive">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">
                                    <input id="search-input" type="search" #myInput class="form-control"
                                        [(ngModel)]="nom" (input)="search()" placeholder="Recherche"
                                        style="border-radius: 5rem 5rem 5rem 5rem; width: 70%" />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" style="
                      border-radius: 5rem 5rem 5rem 5rem;
                      background-color: #f9656b !important;
                    ">
                                            <i class="zmdi zmdi-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                            </div>
                        </div>
                        <br/>
                        <br>
                        <div class="row">
                            <div *ngFor="let i of classes | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let k = index"
                                class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card d-flex align-items-center justify-content-center">
                                    <div class="card-body text-center">
                                        <i class="bi bi-folder" style="font-size: 4rem;color: #f9656b;"></i>
                                        <h5 class="card-title"
                                            style="font-size: 24px;font-family: Nunito, sans-serif;font-weight: 600">
                                            {{ i.nom }}</h5>
                                    </div>
                                    <div class="card-footer">
                                        <button *ngIf="test != type" id="but" (click)="viewListesEtudiants(i._id)"
                                            class="btn btn-default waves-effect waves-float waves-red">
                                            Liste des étudiants
                                        </button>
                                        <button *ngIf="test == type" id="but" (click)="viewListesEnfants(i._id)"
                                            class="btn btn-default waves-effect waves-float waves-red">
                                            Listes des enfants
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="classes && classes.length > 9" class="card-footer border-0 py-5"
                            style="background-color: white;">
                            <div class="d-flex justify-content-center">
                                <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                                    (pageChange)="onTableDataChange($event)">
                                </pagination-controls>
                            </div>
                        </div>
                        <div *ngIf="classes && classes.length == 0" class="table-responsive" style="text-align: center">
                            <p style="padding-bottom: 45px; padding-top: 35px ">
                                Aucune classe n'est trouvé
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

