import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';;


@Injectable({
  providedIn: 'root'
})
export class SendContactsMarketingService {

  
  private _sendContactMarketingUrl=`${environment.baseUrl}/sendContactMarketing` 
  constructor(private http: HttpClient) { }

  

      createRole(send: any,id):Observable<Object> {
        return this.http.post(`${this._sendContactMarketingUrl}/${id}`,send);
      }
      
    }
