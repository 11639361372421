import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/_services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  id: any;
  searchs: any;
  searchsUser :any;
  searchsRole :any;
  searchsEtabl : any; 

  verifUser;
  verifAct
  VerifEtab
  VerifRole

  constructor(private router :Router, private searchService :SearchService) { }

  ngOnInit(): void {
    this.id=this.router.url.split('/');
    this.searchGlobale()
  }

  searchGlobale(){

      this.searchService.searchEspaceSuperAdmin(this.id[3]).subscribe(e=>{
        
          this.searchs =e;
           if(this.searchs.length != 0)
              {
                if(this.searchs[0].length != 0)
                   {
                     this.searchsUser=this.searchs[0]
                     this.verifUser =true;
                   }
                if(this.searchs[1].length != 0)
                   {
                   }   
                if(this.searchs[2].length != 0)
                   {
                     this.searchsEtabl=this.searchs[2]
                     this.VerifEtab=true;
                   }
                if(this.searchs[3].length != 0)
                   {
                     this.searchsRole=this.searchs[3]
                     this.VerifRole=true;
                   }       
              }
              else{
              }
        },
        (error) => {
          ;
        }
      );
    
    }

}
