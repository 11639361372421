import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import {  HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
//
export class VisitorsService {

  constructor(  private http: HttpClient) { }

  getIpAddress() {
    return this.http
          .get('https://23.227.140.190')
          .pipe(
            catchError(this.handleError)
          );
  } 


  getGEOLocation(ip) {

 
      let url = "https://api.ipgeolocation.io/ipgeo?apiKey=0f4e06b0721a4a2b82bce38abc80b31a&ip="+ip; 
        return this.http
              .get(url)
              .pipe(
                catchError(this.handleError)
              );
      } 

  private handleError(error: HttpErrorResponse ,contry) {
    if (error.error instanceof ErrorEvent) {
     
      console.error('An error occurred:', error.error.message);
    } else {
     
        contry="Be"
     
    }
    
    return throwError(
      'Something bad happened; please try again later.');
  }
}
