<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Ajout d'un nouvel enfant
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/directeur"><i class="zmdi zmdi-home"></i>
                                Directeur</a>
                        </li>
                        <li class="breadcrumb-item active"><a routerLink="/directeur/enfants">Enfants</a></li>
                        <li class="breadcrumb-item active">Ajout d'un enfant</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="main-content">
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card p-1" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" >
                                        <div class="card-body">
                                            <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label class="col-form-label"> Nom <b style="color:#f9656b;">*</b></label>
                                                            <input type="text" formControlName="nom" class="form-control" [ngClass]="{ 'is-invalid': submitted && fval_2.nom.errors }" />
                                                            <div *ngIf="submitted && fval_2.nom.errors" class="invalid-feedback">
                                                                <div *ngIf="fval_2.nom.errors.required">Champ obligatoire</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label class="col-form-label">Prénom <b style="color:#f9656b;">*</b></label>
                                                            <input type="text" formControlName="prenom" class="form-control" [ngClass]="{ 'is-invalid': submitted && fval_2.prenom.errors }" />
                                                            <div *ngIf="submitted && fval_2.prenom.errors" class="invalid-feedback">
                                                                <div *ngIf="fval_2.prenom.errors.required">Champ obligatoire</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-form-label ">Parent <b style="color:#f9656b;">*</b></label>
                                                        <ng-select formControlName="parent" 
                                                        (click)="getParents()"
                                                        [items]="parents" 
                                                        bindLabel="firstName_lastName"
                                                        bindValue="_id"
                                                        formControlName="parent"
                                                        [closeOnSelect]="true"
                                                        multiple = true
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.parent.errors }"
                                                        >
                                                        </ng-select>
                                                        
                                                        <div *ngIf="submitted && fval_2.parent.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.parent.errors.required">Champ obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Date de naissance <b style="color:#f9656b;">*</b></label>
                                                        <input type="date" formControlName="dateNaissance" class="form-control" 
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.dateNaissance.errors }" />
                                                           
                                                            <div *ngIf="submitted && fval_2.dateNaissance.errors" class="invalid-feedback">
                                                                <div *ngIf="fval_2.dateNaissance.errors.required">Champ obligatoire</div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label class="col-form-label">Adresse <b style="color:#f9656b;">*</b></label>
                                                <div class="form-group m-3">
                                                    <div class="row">
                                                        <div class="col-md-6 m-0">
                                                            <label class="col-form-label">Rue
                                                            </label>
                                                            <input type="text" formControlName="rue" class="form-control" 
                                                                [ngClass]="{ 'is-invalid': submitted && fval_2.rue.errors }" />
                                                               
                                                                <div *ngIf="submitted && fval_2.rue.errors" class="invalid-feedback">
                                                                    <div *ngIf="fval_2.rue.errors.required">Champ obligatoire</div>
                                                                </div>
                                                        </div>
                                                        <div class="col-md-6 m-0">
                                                            <label class="col-form-label">Numéro
                                                            </label>
                                                            <input type="number" formControlName="numero" class="form-control" 
                                                                [ngClass]="{ 'is-invalid': submitted && fval_2.numero.errors }" />
                                                               
                                                                <div *ngIf="submitted && fval_2.numero.errors" class="invalid-feedback">
                                                                    <div *ngIf="fval_2.numero.errors.required">Champ obligatoire</div>
                                                                </div>
                                                        </div>
    
                                                    </div>
                                                </div>
                                                <div class="form-group m-3">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label class="col-form-label">Code postal
                                                            </label>
                                                            <input type="number" formControlName="codePostal" class="form-control" 
                                                                [ngClass]="{ 'is-invalid': submitted && fval_2.codePostal.errors }" />
                                                               
                                                                <div *ngIf="submitted && fval_2.codePostal.errors" class="invalid-feedback">
                                                                    <div *ngIf="fval_2.codePostal.errors.required">Champ obligatoire</div>
                                                                </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label class="col-form-label">ville
                                                            </label>
                                                            <input type="text" formControlName="ville" class="form-control" 
                                                                [ngClass]="{ 'is-invalid': submitted && fval_2.ville.errors }" />
                                                               
                                                                <div *ngIf="submitted && fval_2.ville.errors" class="invalid-feedback">
                                                                    <div *ngIf="fval_2.ville.errors.required">Champ obligatoire</div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-form-label ">Vaccin(s)</label>
                                                        <ng-select formControlName="vaccines" 
                                                        [items]="listVacines" 
                                                        [closeOnSelect]="true"
                                                        multiple = true
                                                        class="form-control"
                                                        >
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Classe</label>
                                                <div *ngIf="classes" class="col-sm-12 col-md-7">
                                                    <ng-select
                                                        [items]="classes"
                                                        bindLabel="nom"
                                                        formControlName="classe"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.classe.errors }"
                                                    >
                                                    </ng-select>
                                            
                                                    <div *ngIf="submitted && fval_2.classe.errors" class="invalid-feedback">
                                                        <div *ngIf="fval_2.classe.errors.required">Champ obligatoire</div>
                                                    </div>
                                                </div>
                                            </div>                                             -->
                                            <div class="text-center">
                                                <button routerLink="/directeur/enfants" class="btn btn-secondary mr-4 button_list mt-4" type="reset">Annuler</button>
                                                <button class="btn btn-primary button_list mt-4" value="submit" type="submit">Ajouter</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</body>
