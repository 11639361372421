import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CommunicationService } from 'src/app/_services/communication.service';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';

@Component({
  selector: 'app-popup-add-photo-children',
  templateUrl: './popup-add-photo-children.component.html',
  styleUrls: ['./popup-add-photo-children.component.scss']
})
export class PopupAddPhotoChildrenComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  idChildren: any;
  selectedFile: File;
  registerForm: FormGroup;
  submitted = false;
  modalRef: NgbModalRef;
  childrenphoto:any;
  constructor(
    private router: Router,
    private profilEnfantService :ProfilEnfantService,
    public iziToast: Ng2IzitoastService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private communicationService: CommunicationService


    //private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.idChildren =this.router.url.split('/')[4]
  }
  addPhotoPicture() {
   
    if(!this.selectedFile)
    this.iziToast.show({
      message: 'Aucune photo selectionnée',
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
      const form =new FormData();

      form.set('image',this.selectedFile);    
      this.submitted = true;
      if(!this.idChildren){
        this.idChildren = localStorage.getItem("idChildToAddPhoto")
      }
      this.profilEnfantService.createProfil(this.idChildren,form).subscribe(
       
        (data) => {
          this.iziToast.show({
            message: "Ajout d'une photo avec succès",
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          if(localStorage.getItem("idChildToAddPhoto")){
         this.router.navigate(['puericulteur/liste-enfants/details', this.idChildren])
         localStorage.removeItem('idChildToAddPhoto')
          }
          else{
         this.communicationService.updateData();
          }

          this.modalService.dismissAll();
         // location.reload();
          
        },
       
      );
  }
  get f() { return this.registerForm.controls; }

  getChildrenphoto(children){
    this.childrenphoto = children
  }
  selectedFiles: File[] = [];

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
        this.selectedFiles = [];
        for (let i = 0; i < files.length; i++) {
            this.selectedFiles.push(files[i]);
        }
        // Add this line to manually set the file input
        this.fileInput = { nativeElement: event.target };
    }
}

onFileChanged(event: any) {
  this.selectedFile = event.target.files[0];

  if(this.selectedFile != undefined){

    if(this.selectedFile.size < 1*1024*1024)
    {
      var types = ["image/png", "image/jpg", "image/jpeg"]
      if(types.indexOf(this.selectedFile.type) == -1){
        //this.toastr.error('L\’image doit être de type png, jpg ou jpeg.')
        this.iziToast.show({
          message: 'L\’image doit être de type png, jpg ou jpeg.',
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          icon:'bi-exclamation-diamond',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay:true,
          overlayClose:true,
          
        });
        this.selectedFile = undefined
        this.selectedFiles = []
        return;
      }
    }
    else{
      this.iziToast.show({
        message: 'Image très large! merci de ne pas dépasser 1 Mo.',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      this.selectedFile = undefined
      this.selectedFiles = []
      return;
    }
  }
}

  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }
  getSafeUrl(file: File): any {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  onReset() {
    this.modalService.dismissAll();
  
  }
}
