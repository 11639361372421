<app-navbar></app-navbar>
<aside id="leftsidebar" class="sidebar fixed-top" [ngClass]="{'visible': isSidebarVisible}" style="background-color: coral;">
        <div class="menu">
            <ul class="list">
                <li>
                    <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin/chantiers/suppafacs">
                        <img src="../../assets/backoffice/images/logo BN white.png" alt="..." height="100%">
                    </a>
          
                </li>
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/Super-Admin"><i class="bi bi-house-door-fill"></i><span>Accueil</span>
                    </a>
                </li>
    
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="rôles"><i class="bi bi-person-badge-fill"></i> <span>Rôles</span>
                    </a>
                </li>
    
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="établissements"><i class="bi bi-building-fill"></i><span>Établissements</span>
                    </a>
                </li>
    
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="utilisateurs"><i class="bi bi-people-fill"></i><span>Utilisateurs</span>
                    </a>
                </li>
    
                <!-- <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="actualités">
                        <i class="zmdi zmdi-widgets"></i><span>Actualités</span>
                    </a>
                </li>
    
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="contacts">
                        <i class="zmdi zmdi-receipt"></i><span>Contacts</span>
                    </a>
                </li>
    
                <li class="nav-item" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="inscriptions">
                        <i class="zmdi zmdi-account-add"></i><span>Inscriptions</span>
                    </a>
                </li> -->
            </ul>
        </div>
</aside> 
<router-outlet></router-outlet>