<button class="c" (click)="makePDF(id)" type="button">Télécharger</button>
<section  id="content" #content class="section">
<section>
    <div><h5>Rapport de l'établissement</h5></div>
</section>
<section>
    <div>
        <table>
            <tr>
              <th>Nombre des classes</th>
              <th>Nombre des enseignants</th>
              <th *ngIf="type == 'créche' ">Nombre des enfants</th>
              <th *ngIf="type != 'créche' ">Nombre des etudiants</th>
            </tr>
            <tr>
              <td>{{numberClasses}}</td>
              <td>{{numberEnseignat}}</td>
              <td *ngIf="type == 'créche' ">{{numberEnfant}}</td>
              <td *ngIf="type != 'créche' ">{{numberEtudiant}}</td>
            </tr>
           
          </table>
    </div>
</section>
</section>