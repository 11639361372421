import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { EnfantService } from 'src/app/_services/enfant.service';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';
import { StatementService } from 'src/app/_services/statement.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parentdetails-enfant',
  templateUrl: './parentdetails-enfant.component.html',
  styleUrls: ['./parentdetails-enfant.component.scss']
})
export class ParentdetailsEnfantComponent implements OnInit {
  id: string[];
  ids: string;
  idChildren: string;
  children:any
  statements: any;
  photosChildrens: any;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/photosPicutresChildren/';
  page: number = 1;
  count: number = 0;
  tableSize: number = 4;
  tableSizes: any = [3, 6, 9, 12];
  dataChildren: any;
  BaseUrl_profilephoto = environment.baseUrl.split('/api')[0] + '/media/childProfilePhoto/';

  

  constructor(private router :Router,private enfantService :EnfantService,
    private statementService :StatementService,
    private profilEnfantService :ProfilEnfantService,
    private modalService: NgbModal,
    public iziToast: Ng2IzitoastService,


    ) { }

  ngOnInit(): void {
    this.idChildren =this.router.url.split('/')[4]

    this.enfantService.getEnfantById(this.idChildren).subscribe(data=>{
      this.children=data;

    })
    this.getStatemntsByChildren()
    this.getPhotosChildrens()
  }

  getStatemntsByChildren()
  {
    this.statementService.getStatementsChildrenByParent(this.idChildren).subscribe(e=>{
      this.statements = e 

       })     
  }

  getPhotosChildrens(){
      
    this.profilEnfantService.getProfils(this.idChildren).subscribe(e=>{
    
       this.photosChildrens=e

       
          })   
  }
  getDataStatement(children) {
    this.dataChildren = children;
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.statements = this.statements
    localStorage.setItem("lastPageBenefitVisited",this.page.toString())
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getStatemntsByChildren()
  }
}
