import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { ParentService } from 'src/app/_services/parent.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import countries from 'src/app/contries.json';
import { VisitorsService } from 'src/app/_services/visitors.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-add-parent',
  templateUrl: './add-parent.component.html',
  styleUrls: ['./add-parent.component.scss']
})
export class AddParentComponent implements OnInit {


  ipaddress:string = '';
   latitude:string= '';
   longitude:string= '';
   currency:string = '';
   currencysymbol:string = '';
   isp:string= '';
   city:string = '';
   country='BE'
   countryCode="32";

    // list all contries 
    public countryList:{name:string, dial_code:string , code:string}[] = countries;
    //initialContry ={initialContry: 'BE'};
    initialContry ={initialCountry: this.country};

  parent :User =new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres : any;
  show;
  SearchCountryField = SearchCountryField;
   CountryISO = CountryISO;
   preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private parentService: ParentService,
    public iziToast: Ng2IzitoastService,
    private visitorsService:VisitorsService
   
    //private toastr: ToastrService
  ) { 
    //this.enseignant.matiere = new Matiere();
  }

  ngOnInit(): void {
   
    this.visitorsService.getIpAddress().subscribe(res => {

      this.ipaddress = res['ip'];
      this.visitorsService.getGEOLocation(this.ipaddress).subscribe(res => {

        this.latitude = res['latitude'];
        this.longitude = res['longitude'];
        this.currency = res['currency']['code'];
        this.currencysymbol = res['currency']['symbol'];
        this.city = res['city'];
        this.country = res['country_code3'];
        this.isp = res['isp'];
      });

    });
    for(let c of this.countryList)
    {
      if(c.name == this.city)
      {
        this.country=c.code
        this.initialContry ={initialCountry: this.country};
       this.countryCode = c.dial_code
       break;
      }
      
    }
    
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['',Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, ,Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
      confirmpassword: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
     
     
      
      //policy_checked: [false, Validators.required],
    },
    {
      validator: MustMatch('password', 'confirmpassword')
  }
    );
    
  }

 

  get fval_2() {
    return this.registerForm.controls;
  }
  saveParents() {
    this.submitted = true;
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    
    //this.loading = true;
    
    this.registerForm.value.phone = this.registerForm.get('phone').value.internationalNumber;
    this.parentService.createParent(this.registerForm.value).subscribe(
      (data) => {
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        }); 
      this.goToList();
      },
     
    );
  }
  goToList(){
    this.router.navigate(['/directeur/parents'])
    }
    onSubmit(){
      this.saveParents();
    }

    hasError(event: any): void {
      if (!event && this.registerForm.value.phone !== '') {
          this.registerForm.get('phone')?.setErrors(['invalid_cell_phone', true]);
      }
  }

  onCountryChange(event : any) {
    this.countryCode = event.dialCode;
  }
}
