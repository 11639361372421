import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Actualite } from 'src/app/_models/actualite';
import { ActualiteService } from 'src/app/_services/actualite.service';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { startOfDay } from 'date-fns';
import { environment } from 'src/environments/environment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-actualites',
  templateUrl: './actualites.component.html',
  styleUrls: ['./actualites.component.scss']
})
export class ActualitesComponent implements OnInit {
  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi'
  ];
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  //viewDate: Date = new Date();
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
actualites;
  closeResult: string;
  url ="http://localhost:3007/" ;
  imageUrl=`${environment.imageurl}`;
  constructor(private router: Router,private actualitéService :ActualiteService,private sanitization: DomSanitizer,private modalService: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getActualites()
    
  }
 drop (event: CdkDragDrop<string[]>) {
    moveItemInArray(this.actualites, event.previousIndex, event.currentIndex);
    this.storageNews()
  }
 
  storageNews()
  {
    let newsId =[]
    for(let actu of this.actualites)
    {
      newsId.push(actu._id)
    }
    this.actualitéService.changeOrder(newsId).subscribe(e=>
      {
      })
    
  }

  getActualites(){
   this.actualitéService.getActualites().subscribe(e=>{
       this.actualites=e.map(p=>{
       p.filename=this.sanitization.bypassSecurityTrustResourceUrl(this.imageUrl+p.filename);
       
     return p;
     })  
          })   
  }

  
  deleteActualite(actualite:Actualite)
{
this.actualitéService.deleteActualite(actualite._id).subscribe(e=>
  {
    this.toastr.error('suppression avec succés')
    this.getActualites();
  })
  this.modalService.dismissAll()
} 

updateActualite(id:string)
{
  this.router.navigate(['Super-Admin/actualités/modification-actualité',id])
}

  onTableDataChange(event: any) {
    this.page = event;
    this.getActualites();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getActualites();
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  open2(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

}

}
