import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Profil } from 'src/app/_models/profil';
import { User } from 'src/app/_models/user.model';
import { ProfilService } from 'src/app/_services/profil.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-updatephoto',
  templateUrl: './updatephoto.component.html',
  styleUrls: ['./updatephoto.component.scss']
})
export class UpdatephotoComponent implements OnInit {
  @ViewChild('fileInput' , {static:false}) fileInput :ElementRef;
  id: any;
  d: any;
  profil:Profil=new Profil();
  user2:User=new User();

  constructor(private route :ActivatedRoute,private userService:UserService,private router:Router, private profilService :ProfilService,
    public iziToast: Ng2IzitoastService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
  //   this.d=this.profilService.getProfilById(this.id).subscribe(async data=>{
  //     this.profil=data;
      
      
  // })

}
//https://stackoverflow.com/questions/3828554/how-to-allow-input-type-file-to-accept-only-image-files
//

onSubmit()
   {
    const imagBlob =this.fileInput.nativeElement.files[0];
    
    const form =new FormData();
    form.set('image',imagBlob);
    if(imagBlob == null)
    {    
      this.iziToast.show({
        message: "Ce champ est obligatoire",
        messageColor: "#b81414",
        progressBarColor: "#e3a1a1",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#f8e8e8",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      }); 
    }
    else if(imagBlob != null){
      let ch =imagBlob.name.split('').reverse().join('');
      let ch1=ch.split(".")
     let ch2=ch1[0].split('').reverse().join('');
      if (ch2 !="png" && ch2 !="PNG" && ch2 !="jpg" && ch2 !="JPG" && ch2 !="jpeg" && ch2 !="JPEG")
      {
        this.iziToast.show({
          message: "Ce champs accepte seulement des images de type png,jpg,gif et jpeg",
          messageColor: "#b81414",
          progressBarColor: "#e3a1a1",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#f8e8e8",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        }); 
      }
      else{
    //form.set('name',this.product.name);
    //form.set('type',this.product.type);
    //form.set('description',this.product.description);
    //form.set('url',this.product.url);
    //form.set('category',this.product.category.id);
    this.profilService.createProfil(form).subscribe(data=>{
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
      this.goToCategoriesList();
       
    })}
  }
   }
goToCategoriesList()
{
  this.router.navigate(['/Enseignant/profil']);
}

back()
{
 this.router.navigate(['Enseignant/profil'])
}

}
