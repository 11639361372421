
<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Liste des inscriptions
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                  
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Enseignant"><i class="zmdi zmdi-home"></i>
                            SuperAdmin</a>
                        </li>
                        <li class="breadcrumb-item active">Inscription</li>
                        <li class="breadcrumb-item active">Liste des inscriptions</li>
                    </ul>
                </div>
            </div>
        </div>
      
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list">
                        <div class="body table-responsive">

                            
                            <!--
                            <ul class="nav navbar-nav navbar-left">
                 
                                <li class="hidden-sm-down">
                                   <div class="input-group">
                                       <input type="text" #myInput class="form-control" [(ngModel)] ="firstName" [(ngModel)] ="lastName" [(ngModel)] ="email" (input)="search()"  placeholder="Search...">
                                       <span class="input-group-addon">
                                           <i class="zmdi zmdi-search"></i>
                                       </span>
                                   </div>
                               </li> 
                           </ul>
                        -->
                        
<!--
                          <div class="col-sm" *ngIf= "message"  class="alert alert-success" role="alert" >
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                           <strong> {{message}} </strong>
                        </div>
                        <div class="col-sm" *ngIf= "message2"  class="alert alert-warning" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong>  {{message2}} </strong>
                        </div>
                        <div class="col-sm" *ngIf= "show == true"  class="alert alert-danger" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> {{message3}} </strong>
                        </div>
                    -->

                   
                           <br>
                           <br>
                            <table class="table table-striped">
                               
                                    <tr>
                                         <th>Nom</th>
                                         <th >Prénom</th>
                                         <th >Email</th>
                                         <th >Etablissement</th>
                                         <th>type</th>
                                         <th >Adresse</th>
                                         <th >Demande</th>
                                         <!-- <th>Acceptation</th> -->
                                         <th>Actions</th>
                                    </tr>
                               
                                
                                    <tr *ngFor="let i of inscriptions | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                let k = index
                              ">
                                        
                                       
                                        <td ><span  class="text-muted">{{i.firstName}}</span> </td>
                                        <td ><span class="text-muted">{{i.lastName}}</span> </td>
                                        <td ><span class="text-muted">{{i.email}}</span> </td>
                                        <td ><span class="text-muted">{{i.etablissement}}</span> </td>
                                       
                                        <td ><span class="text-muted">{{i.type}}</span> </td>
                                        <td ><span class="text-muted">{{i.adress}}</span> </td>
                                        <td ><span class="text-muted">{{i.originalname}}</span> </td>
                                        <!-- <td *ngIf="i.etat == true">

                                            <ui-switch checked (valueChange)="onValueChange($event)" (click)="disableSignIn(i)"> </ui-switch>
                                            
                                            
                                        </td>

                                        <td *ngIf="i.etat == false">

                                            <ui-switch  (valueChange)="onValueChange($event)" > </ui-switch>
                                            
                                        </td> -->
                                        <td>
                                            
                                            <button (click)="openFile(i.path)"  class="btn btn-default waves-effect waves-float waves-green"><i
                                            class="zmdi zmdi-eye"></i></button>


                                            <button (click)="open(content)" class="btn btn-default waves-effect waves-float waves-red"><i
                                                class="zmdi zmdi-delete"></i>
                                                <ng-template #content let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title" id="modal-basic-title">Confirmation de suppression</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      
                                                        <div class="form-group">
                                                         <label>Êtes-vous sûr de vouloir supprimer cette demande?</label>
                                                        </div>
                                                     
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-dark" (click)="delete(i)">Supprimer</button>  
                                                      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Annuler</button>
                                                    </div>
                                                  </ng-template>      
                                            </button>


                                            <button (click)="open1(content1)"
                                                class="btn btn-default waves-effect waves-float waves-green">
                                                <i class='fa fa-check-circle'  style='font-size:22px;color:green'></i>
                                                <ng-template #content1 let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title" id="modal-basic-title">Confirmation d'acceptation</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      
                                                        <div class="form-group">
                                                         <label>Êtes-vous sûr de vouloir accepter cette demande?</label>
                                                        </div>
                                                     
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-dark" (click)="accept(i._id)">Accepter</button>  
                                                      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Annuler</button>
                                                    </div>
                                                  </ng-template>      
                                            
                                            </button>
                                            

                                            <button (click)="open2(content2)" class="btn btn-default waves-effect waves-float waves-green">
                                                <i class='fa fa-times-circle'  style='font-size:22px;color:red'></i>

                                                <ng-template #content2 let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title" id="modal-basic-title">Confirmation de Refus</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      
                                                        <div class="form-group">
                                                         <label>Êtes-vous sûr de vouloir refuser cette demande?</label>
                                                        </div>
                                                     
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-dark" (click)="refuse(i._id)">Refuser</button>  
                                                      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Annuler</button>
                                                    </div>
                                                  </ng-template>      
                                            </button>

                                            
                                         </td>

                                          
                                            
                                        <!-- <td (click)="see_details()"><span
                                                        [ngClass]="{ 'col-green': product.article_status === 'Publish', 'col-red': product.article_status === 'Draft', 'col-amber': product.article_status === 'Pending' }">
                                                        article_status</span></td> -->
                                        <!-- <td>
                                           <button (click)="updateCours(i._id)"  class="btn btn-default waves-effect waves-float waves-green"><i
                                                    class="zmdi zmdi-edit"></i></button> 
                                                     
                                            <button (click)="open(content)" class="btn btn-default waves-effect waves-float waves-red"><i
                                                    class="zmdi zmdi-delete"></i>
                                                    <ng-template #content let-modal>
                                                        <div class="modal-header">
                                                          <h4 class="modal-title" id="modal-basic-title">Confirmation de suppression</h4>
                                                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                            <span aria-hidden="true">&times;</span>
                                                          </button>
                                                        </div>
                                                        <div class="modal-body">
                                                          
                                                            <div class="form-group">
                                                             <label>Êtes-vous sûr de vouloir supprimer ce document?</label>
                                                            </div>
                                                         
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-outline-dark" (click)="deleteCours(i)">Supprimer</button>  
                                                          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Annuler</button>
                                                        </div>
                                                      </ng-template>      
                                                </button>
                                                
                                                <button (click)="openFile(i.path)"  class="btn btn-default waves-effect waves-float waves-green"><i
                                                    class="zmdi zmdi-eye"></i></button> 
                                               
                                        </td> -->
                                    </tr>

                             
                            </table>
                          
                                   


                            
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <pagination-controls
                          previousLabel="Prev"
                          nextLabel="Next"
                          (pageChange)="onTableDataChange($event)"
                        >
                        </pagination-controls>
                      </div>
                   
                </div>
            </div>
        </div>
        
        
    </section>
    
    
      
      
      
     
</body>







