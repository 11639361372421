import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-etbalissementempl',
  templateUrl: './etbalissementempl.component.html',
  styleUrls: ['./etbalissementempl.component.scss']
})
export class EtbalissementemplComponent implements OnInit {

  currentUser: User;
  isSidebarVisible: boolean = true;
  message: "";
  type
  test ="créche"
  captcha :string;
  email :string
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private token :TokenStorageService,
    private sharedService: SharedService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.route.params.subscribe( (params : Params )=>{
      this.message=params['caller']
     

    } )
    this.sharedService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
  }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.type=this.currentUser[0].etablissement.type
    this.captcha = '';
    this.email = 'khalilmeddeb2@gmail.com';
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/Connexion']);
  }
  public resolved(captchaResponse :string)
  {
    this.captcha =captchaResponse
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
}
