<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Liste des contacts
                       
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                   
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            SuperAdmin</a>
                        </li>
                        <li class="breadcrumb-item active">Contacts</li>
                        <li class="breadcrumb-item active">Liste des contacts</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list">
                        <div class="body table-responsive">
                          <div class="input-group">
                            <div >
                              <input id="search-input" type="search" #myInput class="form-control" [(ngModel)] ="firstName" [(ngModel)] ="lastName" [(ngModel)] ="email" (input)="search()" placeholder="Recherche" />
                              
                            </div>
                           
                            <button  type="button" >
                                <i class="zmdi zmdi-search"></i>
                            </button>
                           
                          </div>  
                          <br><br>
                            <table  class="table table-striped"  >
                                
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Etat</th>
                                    <th>Actions</th>
                                    
                                   
                                  <!--  <th>Actions</th>-->
                                    </tr>
                                    
                                    <tr *ngFor="let i of contacts | paginate
                                    : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                let k = index
                              ">
                                        <td>{{i.firstName}} </td>
                                        <td>{{i.lastName}} </td>
                                        <td>{{i.email}} </td>
                                        <td>{{i.message}} </td>
                                        <td *ngIf=" i.etat == true " ><i class='fa fa-check-circle'  style='font-size:24px;color:green'></i>
                                        </td>
                                        <td *ngIf=" i.etat == false " ><i class='fa fa-times-circle'  style='font-size:24px;color:red'></i>
                                        </td>
                                     
                                        
                                        
                                       
                                        <td>
                                           
                                           

                                                 <button  (click)="open(content)" class="btn btn-default waves-effect waves-float waves-red"><i
                                                    class="zmdi zmdi-delete"></i>
                                                    <ng-template #content let-modal>
                                                        <div class="modal-header">
                                                          <h4 class="modal-title" id="modal-basic-title">Confirmation de suppression
                                                        </h4>
                                                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                            <span aria-hidden="true">&times;</span>
                                                          </button>
                                                        </div>
                                                        <div class="modal-body">
                                                          
                                                            <div class="form-group">
                                                             <label>Êtes-vous sûr de vouloir supprimer ce contact ?
                                                            </label>
                                                            </div>
                                                         
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-outline-dark" (click)="deleteContact(i)">Supprimer</button>  
                                                          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Annuler</button>
                                                        </div>
                                                      </ng-template>      
                                                </button>     
                                            
                                                <!-- <button   class="btn btn-default waves-effect waves-float waves-red"><i class="zmdi zmdi-comments"></i>
                                                    </button> -->
                                                    <button  class="btn btn-default waves-effect waves-float waves-red" (click)="open3(content3)"><i class="zmdi zmdi-comments"></i>
                                                        <ng-template #content3 let-modal>
                                                          <div class="modal-header">
                                                            <h4 class="modal-title" id="modal-basic-title">{{i.titre}}</h4>
                                                            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                                                          </div>
                                                          <div class="modal-body">
                                                            <form  >
                                                              <div class="form-group row mb-4">
                                                                <label class="label"><strong>Objet</strong>
                                                                    </label>
                                                               
                                                                    <input type="text" class="form-control"  name="objet"
                                                                        placeholder="saisir votre objet"  [(ngModel)]="sendContact.objet">
                                                               
                                                            </div>
                                                            <div class="form-group row mb-4">
                                                              <label class="label" ><strong>Message</strong>
                                                                 </label>
                                                            
                                                                  <textarea type="text" class="form-control"  name="message"
                                                                      placeholder="Saisir votre message"  [(ngModel)]="sendContact.message">
                                                                  </textarea>
                                                              
                                                          </div>
                                                            </form>
                                                          </div>
                                                          <div class="modal-footer">
                                                            <button (click)="sendContactMarketing(i._id)" type="button"  class="ajout" >Envoyer</button>
                                                          </div>
                                                        </ng-template> 
                                                      </button>
                                                      <!-- <button> -->
                                                        <ui-switch></ui-switch>
                                                      <!-- </button> -->
                
                                        </td>
                                    
                                    </tr>
                                    </table>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center  ">
                        <pagination-controls
                          previousLabel="Prev"
                          nextLabel="Next"
                          (pageChange)="onTableDataChange($event)"
                        >
                        </pagination-controls>
                      </div>
                    
                </div>
            </div>
        </div>
    </section>
   

</body>
