import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { EnfantService } from 'src/app/_services/enfant.service';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';
import { StatementService } from 'src/app/_services/statement.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit {
  currentUser: User;
  ids
  enfants;
  statemnent
  fisrtChildren
  currentDate 
  photos
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/photosPicutresChildren/';

  constructor(private token :TokenStorageService,private enfantService :EnfantService,
    private router :Router,private statemntService :StatementService,private profilEnfants : ProfilEnfantService)  { }

 
  ngOnInit(): void {
    let tDate = new Date();
    let currentDateAsString = tDate.toISOString(); // Convert the Date object to 
    this.currentDate = currentDateAsString.split("T")[0]
    this.currentUser = this.token.getUser();
    this.ids=this.currentUser[0]._id
    this.getEnfants();
    this.currentDate.split("T")[0]
    
  }
  getEnfants(){
    this.enfantService.getEnfantsByParent(this.ids).subscribe(e=>{
        this.enfants=e;

        this.getStatementByDateByChildren(this.enfants[0]._id)
        this.getPhotosByDateByChildren(this.enfants[0]._id)
        this.fisrtChildren = this.enfants[0]
        
          })   
  }

  getStatementByDateByChildren(id){

    this.statemntService.getStatementsChildrenbyCurentDate(id).subscribe(e=>{
      this.statemnent=e;

      
        })   
  }
  getPhotosByDateByChildren(id){

    this.profilEnfants.getphotosChildrenByCurentDzte(id).subscribe(e=>{
      this.photos=e;

      
        })   
  }

  getChildrenToStatement(children){

    this.getStatementByDateByChildren(children._id)
    this.getPhotosByDateByChildren(children._id)
  }
  

}
