import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root'
})
export class SearchService {
 
  private _searchUrl=`${environment.baseUrl}/search` 
  constructor(private http: HttpClient) { }

  searchEspaceSuperAdmin(search : string):Observable<any>
  {
    return this.http.get<any>(`${this._searchUrl}/${search}`);

  }  
}
