import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/_models/role';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  role :Role = new Role();
 
  constructor(private roleService :RoleService,private router :Router,private toastr: ToastrService){}
  ngOnInit(): void {
  }


  saveRole(){
   
     this.roleService.createRole(this.role).subscribe(data=>{
       
       this.goToListRoles();
       this.toastr.success('Ajout avec succés')
 
   },
    );

 }
 
 goToListRoles(){
   this.router.navigate(['/Super-Admin/rôles'])
   }
 
 
   onSubmit(){
    
     this.saveRole();
     
   }

}
