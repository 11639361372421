<app-navbar></app-navbar>
<aside id="leftsidebar" class="sidebar fixed-top" [ngClass]="{'visible': isSidebarVisible}" style="background-color: #f9656b;">
    <div class="menu">
        <ul class="list">
            <li>
                <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin/chantiers/suppafacs">
                    <img src="../../assets/backoffice/images/logo BN white.png" alt="..." height="100%">
                </a>
      
            </li>
            <!-- <li class="header">MAIN</li> -->
            <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="/puericulteur" ><i class="bi bi-house-door-fill"></i><span>Tableau de bord</span></a>
                
            </li>

            <!-- <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="profil" ><i class="bi bi-person-circle"></i><span>Profil</span></a>
                
            </li> -->

            <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="activites" ><i class="bi bi-pen-fill"></i><span>Activités</span></a>
                
            </li>

            <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="liste-enfants"><i class="bi bi-person-vcard-fill"></i><span>Enfants</span></a>
                
            </li>

           
        </ul>
    </div>
</aside> 
<router-outlet></router-outlet>
