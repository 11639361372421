import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listes-photos-enfants',
  templateUrl: './listes-photos-enfants.component.html',
  styleUrls: ['./listes-photos-enfants.component.scss']
})
export class ListesPhotosEnfantsComponent implements OnInit {
  id
  ids
  id4
  photosEnfants;
  description:string;
  imageUrl = `${environment.imageurl}`;
  page: number = 1;
  copyListPhotosEnfant: any;
  constructor(private router: Router, private profilEnfantService: ProfilEnfantService, private sanitization: DomSanitizer) { }

  ngOnInit(): void {
    this.id = this.router.url.split('/');
    this.ids = this.id[6]
    this.id4 = this.id[4]
    this.getPhotosAndDescriptionsEnfants()
  }

  getPhotosAndDescriptionsEnfants() {
    this.profilEnfantService.getProfils(this.id4).subscribe(e => {
      this.photosEnfants = e
      this.copyListPhotosEnfant = this.photosEnfants;
      this.photosEnfants = e.map(p => {
        p.filename = this.sanitization.bypassSecurityTrustResourceUrl(this.imageUrl + p.filename);

        return p;



      })


    })
  }

  
  onTableDataChange(event: any) {
    this.page = event;
    this.getPhotosAndDescriptionsEnfants();
  }
  search() {
    this.photosEnfants = this.copyListPhotosEnfant;
    let filteredPhotoEnfant = [...this.photosEnfants];

    if (this.description !== "" ) {
      filteredPhotoEnfant = this.photosEnfants.filter(res => {
        return (res.description && res.description.toLocaleLowerCase().includes(this.description.toLocaleLowerCase()))
      });

      this.photosEnfants = filteredPhotoEnfant;
    } else if (this.description === "" ) {
      this.getPhotosAndDescriptionsEnfants();
    }
  }

}
