import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/_models/role';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss'],
})
export class UpdateRoleComponent implements OnInit {
  id: string;
  //category :any={name:""}
  role: Role = new Role();
  constructor(
    private roleService: RoleService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.roleService.getRoleById(this.id).subscribe((data) => {
      this.role = data;
    });
  }

  onSubmit() {
    this.roleService.EditRole(this.id, this.role).subscribe((data) => {
      this.toastr.warning('Modification avec succés');
      this.goToCategoriesList();
    });
  }
  goToCategoriesList() {
    this.router.navigate(['/Super-Admin/rôles']);
  }
}
