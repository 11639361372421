import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatementService } from 'src/app/_services/statement.service';

@Component({
  selector: 'app-list-statements',
  templateUrl: './list-statements.component.html',
  styleUrls: ['./list-statements.component.scss']
})
export class ListStatementsComponent implements OnInit {
  id: string[];
  ids: string;
  statements : any
  dataChildren: any;

  constructor(private router: Router,private statementService :StatementService) { }
  
  ngOnInit(): void {
    this.id=this.router.url.split('/');
    this.ids=this.id[4]      
    this.getStatements()
  }
  // onTableDataChange(event: any) {
  //   this.page = event;
  //   this.getPhotosAndDescriptionsEnfants();
  // }

  getStatements(){
    this.statementService.getStatemenentByChildren(this.ids).subscribe(e=>{
    this.statements = e 
     })     
  }

  getDataStatement(children) {
    this.dataChildren = children;
  }


}
