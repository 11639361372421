import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { SharedService } from '../../_services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ProfilService } from 'src/app/_services/profil.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  captcha :string;
  currentUser: string;
  user: any;
  d: any;
  notifEtat ;
  notifications;
  profils: any;
  length: any;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/photosProfils/';



  constructor(private router: Router, private authenticationService: AuthenticationService,private sharedService: SharedService,private userService: UserService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private token :TokenStorageService,
    private notificationService :NotificationService,
    private profilService :ProfilService,private sanitization: DomSanitizer
) {}

  ngOnInit(): void {
    this.currentUser=localStorage.getItem('user')
    this.d=this.userService.getUserById(this.currentUser).subscribe(async data=>{
      this.user=data
     
    })
    this.profilService.getProfilUser().subscribe(e=>{
    
      this.profils=e
     this.length=this.profils.length;
   
   })

  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/Connexion']);
  }
  toggleSidebar() {
    this.sharedService.toggleSidebarVisibility();
  }
  changerEtat()
  {
   this.notifEtat = !this.notifEtat;
   this.getNotifications();
  }
  getNotifications(){
       this.notificationService.getNotificationsByEnseignant().subscribe(e=>{
       this.notifications=e
          })   
  }

  onChange(id)
  {
    this.notificationService.getChangeSelect(id).subscribe(e=>{
     })   

  }

  public resolved(captchaResponse :string)
  {
    this.captcha =captchaResponse
  }

  notif() {
     
   this.router.navigate(['/parent/liste-enfants']);
  }

  goProfilEnseig(){
    this.router.navigate(['puericulteur/profil']);

  }


}
