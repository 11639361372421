<section class="content home">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <!-- <h2 style="color: #5dc4be !important;">Accueil</h2> -->
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a routerLink="/Parent"><i class="zmdi zmdi-home"></i> Parent</a></li>
                    <li class="breadcrumb-item active">Accueil</li>
                </ul>
            </div>
        </div>
    </div>
    <br>
    <div  class="container-fluid" >
        <div  class="text-center" >
               <label class="text-center" style="font-size: 1.25rem;color: #5dc4be;"><strong><i class="bi bi-calendar-date"></i> {{currentDate | date: 'dd/MM/yyyy'}}</strong> </label>
        </div>
        <br>
        <div class="row">
            <div *ngIf="enfants && enfants.length > 0" class="col-md-6 col-12" >
                <ng-select [items]="enfants"
                bindLabel="prenom"
                 class="form-control"
                 [closeOnSelect]="true"
                 [(ngModel)]="fisrtChildren"
                 (change)="getChildrenToStatement($event)"
                 >
                 </ng-select>
    
            </div>    
        </div>
    </div>
    <br>
    <br>
    <div *ngIf="statemnent" class="container-fluid">

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info  " style="color: #5dc4be;"><i class="bi bi-clock"></i> Heure d'arrivée :</strong>
                    <!-- <p class="mb-3 text-capitalize">{{statemnent.arrivingTime}}</p> -->
                    <input type="time" class="form-control" [(ngModel)]="statemnent.arrivingTime" disabled >
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info" style="color: #5dc4be;"><i class="bi bi-clock"></i> Heure de départ :</strong>
                    <!-- <p class="mb-3 text-capitalize">{{statemnent.departureTime}}</p> -->
                    <input type="time" class="form-control" [(ngModel)]="statemnent.departureTime" disabled >
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-thermometer-high"></i> Température :</strong>
                    <p class="mb-3 text-capitalize text-center">{{statemnent.temperature}}°C</p>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-alarm"></i> Sieste :</strong>
                    <p class="mb-3 text-capitalize text-center">{{statemnent.nap}}</p>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-question-circle"></i> Selles :</strong>
                    <p *ngIf="statemnent.salt == true" class="mb-3 text-capitalize text-center">{{statemnent.nbreSalt}} fois</p>
                    <p *ngIf="statemnent.salt == false" class="mb-3 text-capitalize text-center">0 fois</p>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-egg-fried"></i> Repas :</strong>
                    <p class="mb-3 text-capitalize text-center">{{statemnent.meal}}</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-egg"></i> Goûter :</strong>
                    <p class="mb-3 text-capitalize text-center">{{statemnent.snack}}</p>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-cake2"></i> Événement:</strong>
                    <p class="mb-3 text-capitalize text-center">{{statemnent.event}}</p>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                    <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-file-text"></i> Notes :</strong>
                    <p class="mb-3 text-capitalize">{{statemnent.note}}</p>
                    <p  *ngIf="!statemnent.note" class="mb-3 text-center">Aucune note n'est saisie</p>
                </div>
            </div>
        </div>
        <br> 
        <br>
        <div  *ngIf="photos && photos.length > 0" class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
            <div id="imageCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div *ngFor="let photo of photos; let first = first" class="carousel-item text-center" [class.active]="first">
                        <img src="{{ BaseUrl }}/{{ photo.filename }}" class="d-block mx-auto img-fluid" alt="Image 1">
                    </div>
                </div>
                <a class="carousel-control-prev" href="#imageCarousel" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#imageCarousel" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <div *ngIf="photos && photos.length == 0" class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91 ;text-align: start">
            <strong class="pr-1 title-info " style="color: #5dc4be;"><i class="bi bi-camera"></i> Liste des photos :</strong>
            <p style="padding-bottom: 45px; padding-top: 35px " class="text-center">
                Aucune photo n'est trouvé
            </p>
        </div>
        <br>
        <br>
    </div>
    <div *ngIf="!statemnent" class="row">
        <div class="col-md-12 col-12">
            <div class="card-body" style="background-color: #d0f0ee91; border: #d0f0ee91">
                <p  class="mb-3 text-center">L'enfant est absent </p>
            </div>
        </div>
    </div>


</section>
