<section class="content home">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Modifier vos informations personelles
                  
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a routerLink="/Enseignant"><i class="zmdi zmdi-home"></i>
                        Puéricultrice </a>
                    </li>
                    <li class="breadcrumb-item"><a routerLink="/Enseignant/profil">Profil</a></li>
                    <li class="breadcrumb-item active">Modifier vos informations</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="main-content">
            <section class="section">
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
    
                                <form   >
                                    <div class="card-body">
                                        <div class="form-group row mb-4">
                                            <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Nom
                                               </label>
                                            <div class="col-sm-12 col-md-7">
                                                <input type="text" name="firstName" [(ngModel)]="user.firstName" class="form-control" 
                                                    placeholder="Saisir votre nouveau nom"  />
                                            </div>
                                           
                                        </div>
    
                                        <div class="form-group row mb-4">
                                            <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Prénom
                                               </label>
                                            <div class="col-sm-12 col-md-7">
                                                <input type="text"name="lastName"  [(ngModel)]="user.lastName" class="form-control" 
                                                    placeholder="Saisir votre nouveau prénom" />
                                                   
                                                    
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button routerLink="/Enseignant/profil"
                                                class="btn btn-secondary mr-4 button_list mb-2"
                                                type="reset">Annuler</button>
                                            <button (click)="onSubmit()" class="btn btn-primary button_list mb-2" value="submit"
                                                type="submit">Modifier</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    </section>
