import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { ProfilEnfant } from 'src/app/_models/profil-enfant';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';

@Component({
  selector: 'app-ajout-photo-enfant',
  templateUrl: './ajout-photo-enfant.component.html',
  styleUrls: ['./ajout-photo-enfant.component.scss']
})
export class AjoutPhotoEnfantComponent implements OnInit {

  @ViewChild('fileInput' , {static:false}) fileInput :ElementRef;
  
  profilEnfant :ProfilEnfant =new ProfilEnfant();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres ;
  classes ;
  show;
  id
  ids
  id4
  dateAuj = new Date();
  d : string; 
  d1 : string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    //private coursService: CoursService,
    //private classesService:ClasseService,
    private profilEnfantService :ProfilEnfantService,
    public iziToast: Ng2IzitoastService,
    //private toastr: ToastrService
  ) { 
   
  }

  ngOnInit(): void {
    this.d=this.dateAuj.toISOString();
    this.d1= (this.dateAuj.toISOString()).substr(0, 10);
    this.id=this.router.url.split('/');
    this.ids=this.id[6]
    this.id4=this.id[4]
   
    this.registerForm = this.formBuilder.group({
      description: ['', Validators.required],     
     
      image :['', Validators.required],
      date : ['',],
      //policy_checked: [false, Validators.required],
    });
   
  }



   

  get fval_2() {
    return this.registerForm.controls;
  }
  onChange()
  {
  }
 

  saveCours() {
   
    const imagBlob =this.fileInput.nativeElement.files[0];
 
    const form =new FormData();
    form.set('image',imagBlob);
    form.set('description',this.registerForm.value.description);
    form.set('date',this.registerForm.value.date);
  
    this.submitted = true;
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      this.iziToast.show({
        message: "Toutes les champs sont obligatoires",
        messageColor: "#b81414",
        progressBarColor: "#e3a1a1",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#f8e8e8",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      }); 
      return;
    }
    //this.loading = true;
    
    this.profilEnfantService.createProfil(this.id4,form).subscribe(
      (data) => {
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.goToList();
      },
     
    );
  }
  goToList(){
    this.router.navigate(['/puericulteur/liste-enfants/Liste-photos/'+  this.id4])
    }
    onSubmit(){
      this.saveCours();
    }

}
