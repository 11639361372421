import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { Ng2IziToastModule } from 'ng2-izitoast';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {
  CommonModule,
  HashLocationStrategy,
  Location,
  LocationStrategy,
} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { RegisterComponent } from './register/register.component';

import { RoleComponent } from './backoffice/role/role.component';
import { AddRoleComponent } from './backoffice/role/add-role/add-role.component';
import { UserListComponent } from './backoffice/user-list/user-list.component';
import { AddUserComponent } from './backoffice/user-list/add-user/add-user.component';
import { UpdateUserComponent } from './backoffice/user-list/update-user/update-user.component';
import { AuthGuard } from './_helpers/auth.guard';
import { UpdateRoleComponent } from './backoffice/role/update-role/update-role.component';
import { EtablissementComponent } from './backoffice/etablissement/etablissement.component';
import { AddEtablissementComponent } from './backoffice/etablissement/add-etablissement/add-etablissement.component';
import { UpdateEtablissementComponent } from './backoffice/etablissement/update-etablissement/update-etablissement.component';
import { TokenInterceptorServiceService } from './_services/token-interceptor-service.service';

import { EtbalissementemplComponent } from './Etablissements/etbalissementempl/etbalissementempl.component';
import { MatieresComponent } from './Etablissements/etbalissementempl/matieres/matieres.component';
import { AddMatiereComponent } from './Etablissements/etbalissementempl/matieres/add-matiere/add-matiere.component';
import { UpdateMatiereComponent } from './Etablissements/etbalissementempl/matieres/update-matiere/update-matiere.component';
import { EnseignantsComponent } from './Etablissements/etbalissementempl/enseignants/enseignants.component';
import { AddEnseignantComponent } from './Etablissements/etbalissementempl/enseignants/add-enseignant/add-enseignant.component';
import { UpdateEnseignantComponent } from './Etablissements/etbalissementempl/enseignants/update-enseignant/update-enseignant.component';
import { DirecteurHomeComponent } from './Etablissements/etbalissementempl/directeur-home/directeur-home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EnseignantHomeComponent } from './Etablissements/Enseignant/templ-enseignant/enseignant-home/enseignant-home.component';
import { TemplEnseignantComponent } from './Etablissements/Enseignant/templ-enseignant/templ-enseignant.component';
import { ClassesEnseigComponent } from './Etablissements/Enseignant/templ-enseignant/classes-enseig/classes-enseig.component';
import { MatieresEnseigComponent } from './Etablissements/Enseignant/templ-enseignant/matieres-enseig/matieres-enseig.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
//
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProfilEnseignantComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/profil-enseignant.component';
import { UpdateInformationsComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/update-informations/update-informations.component';
import { UpdatePasswordComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/update-password/update-password.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UpdatephotoComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/updatephoto/updatephoto.component';
import { TemplParentComponent } from './Etablissements/Parent/templ-parent/templ-parent.component';
import { AccueilComponent } from './Etablissements/Parent/templ-parent/accueil/accueil.component';
import { EnfantsComponent } from './Etablissements/etbalissementempl/enfants/enfants.component';
import { AddEnfantComponent } from './Etablissements/etbalissementempl/enfants/add-enfant/add-enfant.component';
import { UpdateEnfantComponent } from './Etablissements/etbalissementempl/enfants/update-enfant/update-enfant.component';
import { ListEnfantsComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/list-enfants.component';
import { AjoutPhotoEnfantComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/ajout-photo-enfant/ajout-photo-enfant.component';
import { ListesPhotosEnfantsComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/listes-photos-enfants/listes-photos-enfants.component';
import { ParentsComponent } from './Etablissements/etbalissementempl/parents/parents.component';
import { AddParentComponent } from './Etablissements/etbalissementempl/parents/add-parent/add-parent.component';
import { UpdateParentComponent } from './Etablissements/etbalissementempl/parents/update-parent/update-parent.component';
import { ListesEnfantsComponent } from './Etablissements/Parent/templ-parent/listes-enfants/listes-enfants.component';
import { ListesPhotosEnfantsByParentComponent } from './Etablissements/Parent/templ-parent/listes-enfants/listes-photos-enfants-by-parent/listes-photos-enfants-by-parent.component';
import { ActualitesComponent } from './backoffice/actualites/actualites.component';
import { UpdateActualiteComponent } from './backoffice/actualites/update-actualite/update-actualite.component';
import { AddActualiteComponent } from './backoffice/actualites/add-actualite/add-actualite.component';
import { ViewEtablissementComponent } from './view-etablissement/view-etablissement.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from '../environments/environment';
import { SearchComponent } from './backoffice/search/search.component';
import { UpdatePasswordGlobaleComponent } from './update-password-globale/update-password-globale.component';
import { MainComponent } from './backoffice/main/main.component';
import { ContactsMarketingComponent } from './backoffice/contacts-marketing/contacts-marketing.component';
import { SigInListComponent } from './backoffice/sig-in-list/sig-in-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavbarComponent } from './admin/navbar/navbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ListStatemntsByChildComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/list-statemnts-by-child/list-statemnts-by-child.component';
import { DetailsEnfantComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/details-enfant/details-enfant.component';
import { ListStatementsComponent } from './Etablissements/Parent/templ-parent/listes-enfants/list-statements/list-statements.component';
import { PopupAddPhotoChildrenComponent } from './admin/popup/popup-add-photo-children/popup-add-photo-children.component';
import { PopupAddChildrenStatmentComponent } from './admin/popup/popup-add-children-statment/popup-add-children-statment.component';
import { ParentdetailsEnfantComponent } from './Etablissements/Parent/templ-parent/listes-enfants/parentdetails-enfant/parentdetails-enfant.component';

//import { ExercicesComponent } from './exercices/exercices.component';
//import { AddExericeComponent } from './add-exerice/add-exerice.component';
//import { QuizComponent } from './Etablissements/Enseignant/templ-enseignant/quiz/quiz.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BackofficeComponent,
    RegisterComponent,
    MainComponent,
    RoleComponent,
    AddRoleComponent,
    UserListComponent,
    AddUserComponent,
    UpdateUserComponent,
    UpdateRoleComponent,
    EtablissementComponent,
    AddEtablissementComponent,
    UpdateEtablissementComponent,

    EtbalissementemplComponent,

    MatieresComponent,

    AddMatiereComponent,

    UpdateMatiereComponent,

    EnseignantsComponent,

    AddEnseignantComponent,

    UpdateEnseignantComponent,

    DirecteurHomeComponent,

    TemplEnseignantComponent,

    EnseignantHomeComponent,

    ClassesEnseigComponent,

    MatieresEnseigComponent,

    ProfilEnseignantComponent,

    UpdateInformationsComponent,

    UpdatePasswordComponent,

    UpdatephotoComponent,

    TemplParentComponent,

    AccueilComponent,

    EnfantsComponent,

    AddEnfantComponent,

    UpdateEnfantComponent,

    ListEnfantsComponent,

    AjoutPhotoEnfantComponent,

    ListesPhotosEnfantsComponent,

    ParentsComponent,

    AddParentComponent,

    UpdateParentComponent,

    ListesEnfantsComponent,

    ListesPhotosEnfantsByParentComponent,

    ActualitesComponent,

    UpdateActualiteComponent,

    AddActualiteComponent,


    ViewEtablissementComponent,

    SearchComponent,

    UpdatePasswordGlobaleComponent,

    ContactsMarketingComponent,

    SigInListComponent,

    NavbarComponent,

    ListStatemntsByChildComponent,

    DetailsEnfantComponent,

    ListStatementsComponent,

    PopupAddPhotoChildrenComponent,

    PopupAddChildrenStatmentComponent,

    ParentdetailsEnfantComponent,
    //ExercicesComponent,

    //AddExericeComponent,

    //QuizComponent,

    //TemplEtudiantComponent,

    //EtudiantHomeComponent,

    //CoursEtudiantsComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgxIntlTelInputModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    Ng2IziToastModule,

    DragDropModule,

    NgSelectModule,

    NgxPaginationModule,
    Ng2TelInputModule,
    //
    AngularEditorModule,
    ToastrModule.forRoot(),
    //
    CommonModule,
    //RecaptchaModule,
    RecaptchaV3Module,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    //

    UiSwitchModule.forRoot({
      // color: 'coral',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    AuthGuard,
    Location,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorServiceService,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
