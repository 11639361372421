import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastrService } from 'ngx-toastr';
import { Matiere } from 'src/app/_models/matiere';
import { MatiereService } from 'src/app/_services/matiere.service';


@Component({
  selector: 'app-add-matiere',
  templateUrl: './add-matiere.component.html',
  styleUrls: ['./add-matiere.component.scss']
})
export class AddMatiereComponent implements OnInit {

  matiere :Matiere =new Matiere();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  //roles : any;
  //etbalissements :any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private matiereService: MatiereService,
    public iziToast: Ng2IzitoastService
    //private roleService:RoleService,
    //private etablissementService:EtablissementService,
    
    //private toastr: ToastrService
  ) { 
    //this.user.role = new Role();
  }

  ngOnInit(): void { 
    this.registerForm = this.formBuilder.group({
      
      nom: [
        '',
        [
          Validators.required,          
        ],
      ],
     
    });
  }

 

   

  get fval_2() {
    return this.registerForm.controls;
  }

 

  saveMatiere() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.matiereService.createMatiere(this.registerForm.value).subscribe(
      (data) => {
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.goToList();
      },
     
    );
  }
  goToList(){
    this.router.navigate(['/directeur/activites'])
    }
    onSubmit(){
      this.saveMatiere();
    }
 


}
