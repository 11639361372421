import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { User } from 'src/app/_models/user.model';
import { ParentService } from 'src/app/_services/parent.service';

@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss']
})
export class ParentsComponent implements OnInit {

 //etbalissement :Etablissement = new Etablissement();
 firstName:string
 lastName:string
 email:string
 phone:string
 taille:number ;
 //nbreEtablissements ;
 
 //lastName:string // comentithom
 //email:string    //comentithom
 //error :boolean=false ;
 //eroorMessage :String="Il faut remplir e champs,il est obligatoire.";
 parents: User[] = [];
 copyListparent:any;
 //roles :Role [] =[];
 i
 closeResult = '';
 message="";
 message2=""
 message3="Suppression avec succès "
 show :boolean=false;
 enabled;
 page: number = 1;
 count: number = 0;
 tableSize: number = 7;
 tableSizes: any = [3, 6, 9, 12];
 
 constructor(private parentService :ParentService,private router: Router, private route: ActivatedRoute,private modalService: NgbModal
  ,public iziToast: Ng2IzitoastService,) 
 {
   
  }
 ngOnInit(): void {
  
   this.getParents();
  
   
   
 }



 getParents(){
   this.parentService.getParents().subscribe(e=>{
    
       this.parents=e;
       this.copyListparent=this.parents;
      
         })
        
 }
 


 deleteParent(parent:User)
{
this.parentService.deleteParent(parent._id).subscribe(e=>
 {
   this.show=true;
   this.iziToast.show({
    message: "Supression avec succès",
    messageColor: "#b81414",
    progressBarColor: "#e3a1a1",
    icon: "bi-check-circle",
    imageWidth: 45,
    position: "topRight",
    timeout: 5000,
    backgroundColor: "#f8e8e8",
    transitionIn: "flipInX",
    transitionOut: "flipOutX",
  });
   this.getParents();
 })
 this.modalService.dismissAll()
} 

updateParent(id:string)
{
 this.router.navigate(['directeur/parents/modification-parent',id])
}


search()
{
  this.parents = this.copyListparent
  let filteredParent = [...this.parents];
 if(this.firstName != "" || this.lastName != "" || this.email != ""|| this.phone != "")
  {
 filteredParent = this.parents.filter(res=>{
   return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
   || res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) 
   || res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase()) 
   || res.phone.toLocaleLowerCase().match(this.phone.toLocaleLowerCase()) 
 })
 this.parents = filteredParent;
  }
  else if(this.firstName == "" && this.lastName == "" && this.email == "" && this.phone == "")
  {
    this.getParents();
  }
}



open(content) {
 this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
   this.closeResult = `Closed with: ${result}`;
 }, (reason) => {
   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
 });
}

private getDismissReason(reason: any): string {
 if (reason === ModalDismissReasons.ESC) {
   return 'by pressing ESC';
 } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
   return 'by clicking on a backdrop';
 } else {
   return `with: ${reason}`;
 }
}
open2(content2) {
 this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
   this.closeResult = `Closed with: ${result}`;
 }, (reason) => {
   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
 });
}



changeStatus(user:User)
{
 this.parentService.EditSatutEnseignant(user._id).subscribe(e=>
   {
     this.getParents();
   })
   this.modalService.dismissAll()
 } 
 onTableDataChange(event: any) {
   this.page = event;
   this.getParents();
 }
 onTableSizeChange(event: any): void {
   this.tableSize = event.target.value;
   this.page = 1;
   this.getParents();
 }
 getBadgeClass(etat: string): string {
  switch (etat) {
    case 'Activé':
      return 'success'; // Utilisez les classes Bootstrap pour la couleur correspondante
  default:
      return 'warning';
  }
}
}
