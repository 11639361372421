import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Enfant } from 'src/app/_models/enfant';
import { ClasseService } from 'src/app/_services/classe.service';
import { EnfantService } from 'src/app/_services/enfant.service';
import { ParentService } from 'src/app/_services/parent.service';

@Component({
  selector: 'app-update-enfant',
  templateUrl: './update-enfant.component.html',
  styleUrls: ['./update-enfant.component.scss']
})
export class UpdateEnfantComponent implements OnInit {

  id:string;
  enfant:any;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres : any;
  d;
  classes: any;
  parents ;
  date_start: "2014-11-19"
  listVacines =["vaccin 1" ,"vaccin 2","vaccin 3" ,"vaccin 4"]
  
  constructor(private enfantService :EnfantService,private classeService :ClasseService,
    private router: Router, 
    private route: ActivatedRoute,
    public iziToast: Ng2IzitoastService,
    private formBuilder: FormBuilder,
    private parentService :ParentService,
   ) 
   {}

  ngOnInit(): void {
    
    this.id = this.route.snapshot.params['id'];
    this.d=this.enfantService.getEnfantById(this.id).subscribe(async data=>{
      this.enfant=data;
    
    

    



      this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      dateNaissance: ['', [Validators.required]],
      //adresse: ['', Validators.required],
      numero: ['', Validators.required],
      rue: ['', Validators.required],
      ville: ['', Validators.required],
      codePostal: ['', Validators.required],
      parent : ['', Validators.required],
      vaccines : [''],

  
    });
      for (let pare of this.enfant.parent){
        pare.firstName_lastName = pare["lastName"]+" "+ pare["firstName"]
      }
    }
    ),
    // }

    
    //this.getclasses();
    this.getParents();
    
    //this.getEtablissements();
  }


    getParents(){
        this.parentService.getParents().subscribe(e=>{
          this.parents=e;
              })
      }

  get fval_2() {
    return this.registerForm.controls;
  }
 
  onSubmit()
  {
    let results= [];
    this.submitted = true;
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    
    
    
   

    this.enfantService.EditEnfant(this.id , this.registerForm.value).subscribe( data=>{
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });

     this.goToEnseignantsList();
      
    })
  }
  goToEnseignantsList()
  {
    this.router.navigate(['/directeur/enfants']);
  }
  compareParentFn(option1: any, option2: any): boolean {
    return option1 && option2 ? option1._id === option2 : option1 === option2;
}

compareClasseFn(option1: any, option2: any): boolean {
    return option1 && option2 ? option1._id === option2 : option1 === option2;
}
}
