import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/_models/role';
import { User } from 'src/app/_models/user.model';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { RoleService } from 'src/app/_services/role.service';
import { UserService } from 'src/app/_services/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { VisitorsService } from 'src/app/_services/visitors.service';
import countries from 'src/app/contries.json';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  user :User =new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  roles : any;
  etbalissements :any;
 

  ipaddress:string = '';
   latitude:string= '';
   longitude:string= '';
   currency:string = '';
   currencysymbol:string = '';
   isp:string= '';
   city:string = '';
   country='BE'
   countryCode="32";
   SearchCountryField = SearchCountryField;
   CountryISO = CountryISO;
   preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

   // list all contries 
   public countryList:{name:string, dial_code:string , code:string}[] = countries;
   //initialContry ={initialContry: 'BE'};
   initialContry ={initialCountry: this.country};
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private roleService:RoleService,
    private etablissementService:EtablissementService,
    private visitorsService:VisitorsService,
    public iziToast: Ng2IzitoastService,
  ) { 
    this.user.role = new Role();
  }

  ngOnInit(): void {
    this.visitorsService.getIpAddress().subscribe(res => {

      this.ipaddress = res['ip'];
      this.visitorsService.getGEOLocation(this.ipaddress).subscribe(res => {

        this.latitude = res['latitude'];
        this.longitude = res['longitude'];
        this.currency = res['currency']['code'];
        this.currencysymbol = res['currency']['symbol'];
        this.city = res['city'];
        this.country = res['country_code3'];
        this.isp = res['isp'];
       
      });

    });
    
    for(let c of this.countryList)
    {
      if(c.name == this.city)
      {
        this.country=c.code
        this.initialContry ={initialCountry: this.country};
       this.countryCode = c.dial_code
       break;
      }
      
    }


    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['',Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, ,Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
      confirmpassword: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
     
      role:  ['', Validators.required],
      etablissement: ['', Validators.required],
      //policy_checked: [false, Validators.required],
    },
    {
      validator: MustMatch('password', 'confirmpassword')
  }
    );
    this.getRoles();
    this.getEtablissements();
  }

  getRoles(){
 
      this.roleService.getRoles().subscribe(e=>{
        this.roles=e;
        this.user.role.id = e[0]._id


       
       
      
            })
    }

    getEtablissements(){
    
        this.etablissementService.getEtablissements().subscribe(e=>{
          this.etbalissements=e;
         // this.user.etablissement.id = e[0]._id
  
          
         
         
        
              })
      }

  get fval_2() {
    return this.registerForm.controls;
  }

 

  saveUser() {
    this.submitted = true;
  
    // return for here if form is invalid
    if (this.registerForm.invalid) {
   
      return;
    }
    //this.loading = true;
   
    //this.registerForm.value
    //this.registerForm.controls['phone']?.setValue('+' + this.countryCode +this.registerForm.get('phone')?.value)
    this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber
   
    this.userService.createUser(this.registerForm.value).subscribe(
      (data) => {
       
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.goToList();
      },
     
    );
  
  }
  goToList(){
    this.router.navigate(['/Super-Admin/utilisateurs', {caller : "Ajout avec succès"}])
    }
    onSubmit(){
    
      this.saveUser();
    }

    hasError(event: any): void {
      if (!event && this.registerForm.value.phone !== '') {
          this.registerForm.get('phone')?.setErrors(['invalid_cell_phone', true]);
      }
  }

  onCountryChange(event : any) {
   
    this.countryCode = event.dialCode;
  }

 
}