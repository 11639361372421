<br /><br /><br /><br /><br /><br />
<div class="container" id="container">
  <div class="form-container sign-in-container">
    <form [formGroup]="loginForm" (ngSubmit)="onSignIn()">
      <h3 style="color: #5c5a7e !important">Réinitialiser votre mot de passe</h3>
      <div class="social-container">
        <a href="/" class="social"
          ><i
            class="bi bi-circle-fill"
            style="color: coral; opacity: 25% !important"
          ></i
        ></a>
        <a href="/" class="social"
          ><i
            class="bi bi-circle-fill"
            style="color: coral; opacity: 50% !important"
          ></i
        ></a>
        <a href="/" class="social"
          ><i
            class="bi bi-circle-fill"
            style="color: coral; opacity: 100% !important"
          ></i
        ></a>
      </div>

      <input
        placeholder="Email"
        type="email"
        formControlName="email"
        id="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
      />
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Veuillez renseigner ce champ.</div>
        <div *ngIf="f.email.errors.pattern">
          Merci de saisir une adresse email valide.
        </div>
      </div>

      <!-- <input
        placeholder="Mot de passe"
        type="password"
        formControlName="password"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      />
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">
          Veuillez renseigner ce champ.
        </div>
      </div> -->
      <!-- <div>
        <label Style="margin-left: -60%;"
          ><input
            id="cb1"
            type="checkbox"
            (click)="rememberMe()"
            style="width: 13px; margin: 7px"
          />Se souvenir de moi</label
        >
      </div> -->
      <br />
      <br />
      <button style="color: #fff">Envoyer</button>
      <!-- <p routerLink="/réinitialisation-motDePasse" style="cursor: pointer; color: #888">
        Mot de passe oublié ?
      </p> -->
      <br />
    </form>
  </div>
  <div>
    <div>
      <div class="overlay-panel overlay-right" style="background: coral">
        <img
          class="img-fluid"
          src="assets\backoffice\images\logo BN white.png"
          alt=""
          width="250px"
          height="200px"
        />
        <br />
        <p style="color: #fff">Bienvenue dans notre platforme BrainNut</p>
      </div>
    </div>
  </div>
</div>
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"
/>

