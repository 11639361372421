import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Matiere } from 'src/app/_models/matiere';
import { MatiereService } from 'src/app/_services/matiere.service';

@Component({
  selector: 'app-update-matiere',
  templateUrl: './update-matiere.component.html',
  styleUrls: ['./update-matiere.component.scss']
})
export class UpdateMatiereComponent implements OnInit {

  id: string;
  //category :any={name:""}
  matiere: Matiere = new Matiere();
  constructor(private matiereService: MatiereService, private route: ActivatedRoute, private router: Router,
    public iziToast: Ng2IzitoastService,) { }

  ngOnInit(): void {
   
    this.id = this.route.snapshot.params['id'];
    this.matiereService.getMatiereById(this.id).subscribe(data => {
      this.matiere = data;
    })

  }

  onSubmit() {
    this.matiereService.EditRole(this.id, this.matiere).subscribe(data => {
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
      this.goToMatieresList();

    })
  }
  goToMatieresList() {
    this.router.navigate(['/directeur/activites']);
  }

}
