import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import { ClasseService } from '../_services/classe.service';
import { EnfantService } from '../_services/enfant.service';
import { EnseignantService } from '../_services/enseignant.service';
import { EtablissementService } from '../_services/etablissement.service';
import { EtudiantService } from '../_services/etudiant.service';

@Component({
  selector: 'app-view-etablissement',
  templateUrl: './view-etablissement.component.html',
  styleUrls: ['./view-etablissement.component.scss']
})
export class ViewEtablissementComponent implements OnInit {

  id
  d
  etablissement
  numberClasses;
  numberEnseignat;
  numberEnfant;
  numberEtudiant
  type
  //cours :Cours
  //contenu
  result;
  @ViewChild('content', {static:false}) el!: ElementRef;
    constructor(private classeService :ClasseService,private route: ActivatedRoute,
     private enseignantService :EnseignantService, private enfantService :EnfantService ,private etudiantService :EtudiantService,
     private etablissementService :EtablissementService) { }
  
    ngOnInit(): void {
      this.id = this.route.snapshot.params['id'];
      this.d=this.etablissementService.getEtablissementsById(this.id).subscribe(async data=>{
        this.etablissement=data;
        this.type=this.etablissement.type;
        
        //this.startCounter()
      })
      this.totalClasses()
      this.totalEnfants()
      this.totalEnseigant()
      this.totalEtudiant()
      
  
    }

    totalClasses()
  {
    this.classeService.getClasseByEtablissement(this.id).subscribe(data =>{
      this.numberClasses=data;
    })
  }

  
  totalEnseigant()
  {
    this.enseignantService.getEnseignantByEtablissement(this.id).subscribe(data =>{
      this.numberEnseignat=data;
    })
  }

  
  totalEnfants()
  {
    this.enfantService.getEnfantByEtablissement(this.id).subscribe(data =>{
      this.numberEnfant=data;
    })
  }

  
  totalEtudiant()
  {
    this.etudiantService.getEtudiantByEtablissement(this.id).subscribe(data =>{
      this.numberEtudiant=data;
    })
  }
  makePDF(id)
  {
  
    let pdf =new jsPDF ('p','pt','a1');
    pdf.html(this.el.nativeElement,{
      callback:(pdf)=>{
      pdf.save("Rapport.pdf")
      }
    }
    )
   
   
  }


    
}
