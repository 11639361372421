<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Modifier un établissement
                        <!-- <small class="text-muted">Bienvenue à elearning Backoffice</small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            Accueil</a>
                        </li>
                        <li class="breadcrumb-item active"><a routerLink="/Super-Admin/établissements">Établissements</a></li>
                        <li class="breadcrumb-item active">Modifier un établissement</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="main-content">
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" >
                                        <div class="card-body">
                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Nom
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input  type="text" formControlName="firstName" class="form-control" 
                                                         [(ngModel)]="etablissement.firstName"  [ngClass]="{ 'is-invalid': submitted && fval_2.firstName.errors }"
                                                         />  
                                                           
                                                        <div *ngIf="submitted && fval_2.firstName.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.firstName.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                               
                                            </div>

                                            <!-- <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Numéro de registre 
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="numeroRegister" class="form-control" 
                                                          [(ngModel)]="etablissement.numeroRegister" 
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.numeroRegister.errors }" />  

                                                        <div *ngIf="submitted && fval_2.numeroRegister.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.numeroRegister.errors.required">Champs obligatoire</div>
                                                            <div *ngIf="fval_2.numeroRegister.errors.pattern">Ce champs doit contenir seulement des
                                                                chiffres.</div>
                                                                <div *ngIf="fval_2.numeroRegister.errors.minlength">Ce Chapms doit avoir au minumin 8 chiffres</div>
                                                        </div>
                                                </div>
                                               
                                            </div> -->

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Pays</label>
                                                <div *ngIf="roles" class="col-sm-12 col-md-7">
                                                  <ng-select
                                                    [items]="countryList"
                                                    bindLabel="name"
                                                    bindValue="code"
                                                    formControlName="pays"
                                                    class="form-control"
                                                    [(ngModel)]="etablissement.pays"
                                                    [ngClass]="{ 'is-invalid': submitted && fval_2.pays.errors }"
                                                  >
                                                  </ng-select>
                                              
                                                  <div *ngIf="submitted && fval_2.pays.errors" class="invalid-feedback">
                                                    <div *ngIf="fval_2.pays.errors.required">Champs obligatoire</div>
                                                  </div>
                                                </div>
                                              </div>
                                              

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Adresse 
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="adresse" class="form-control" 
                                                        [(ngModel)]="etablissement.adresse" 
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.adresse.errors }" />  

                                                        <div *ngIf="submitted && fval_2.adresse.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.adresse.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                               
                                            </div>


                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Téléphone Fixe</label>
                                                <div class="col-sm-12 col-md-7">
                                                    <ngx-intl-tel-input
                                                    [cssClass]="'form-control input-custom-phone'"
                                                    [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="contryIsoSelected"
                                                    [maxLength]="15"
                                                    [phoneValidation]="true"
                                                    name="telephoneFixe"
                                                    (countryChange)="onCountryChange($event)"
                                                    [formControl]="registerForm.get('telephoneFixe')"
                                                    ></ngx-intl-tel-input>
                                            
                                                    <div *ngIf="submitted && fval_2.telephoneFixe.errors" class="invalid-feedback-tel">
                                                        <div *ngIf="fval_2.telephoneFixe.errors.required">Champ obligatoire</div>
                                                        <div *ngIf="registerForm.value.telephoneFixe && fval_2.telephoneFixe.valid" style="color: green;">
                                                            Numéro de téléphone validé <i class="bi bi-check"></i>
                                                        </div>
                                                        <div *ngIf="registerForm.value.telephoneFixe && fval_2.telephoneFixe.invalid" style="color: red;">
                                                            Numéro de téléphone non validé <i class="bi bi-x"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Site Web 
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="siteWeb" class="form-control" 
                                                        [(ngModel)]="etablissement.siteWeb" 
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.siteWeb.errors }" />  

                                                        <div *ngIf="submitted && fval_2.siteWeb.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.siteWeb.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                            </div>
<!-- 
                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Url 
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="url" class="form-control" 
                                                        placeholder="Entrez le site Web  du l'établissement"  [(ngModel)]="etablissement.url" 
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.url.errors }" />  

                                                        <div *ngIf="submitted && fval_2.url.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.url.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                               
                                            </div>  -->

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Type</label>
                                                <div *ngIf="roles" class="col-sm-12 col-md-7">
                                                  <ng-select
                                                    [items]="['créche', 'Université']" 
                                                    bindLabel="name"
                                                    bindValue="value"
                                                    formControlName="type"
                                                    class="form-control"
                                                    [(ngModel)]="etablissement.type"
                                                    
                                                  >
                                                  </ng-select>
                                                </div>
                                              </div>
                                              

                                      <!--      <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">role
                                                   </label>
                                                <div *ngIf="roles" class="col-sm-12 col-md-7">
                                                    <select multiple formControlName="role" class="form-control" [(ngModel)]="etablissement.role._id" [ngClass]="{ 'is-invalid': submitted && fval_2.role.errors }">
                                                        
                                                        <option value="" disabled selected>changer le rôle
                                                            pour l'utilisateur 
                                                            
                                                        </option>
                                                        <option *ngFor="let i of roles"  [value]="i._id" >{{i.type}}</option>
                                                    </select>
                                                        
                                                    <div *ngIf="submitted && fval_2.role.errors" class="invalid-feedback">
                                                        <div *ngIf="fval_2.role.errors.required">Champs obligatoire</div>
                                                    </div>   
                                                </div>
                                            </div>
                                        -->
                                          
                                        <div class="form-group row mb-4">
                                            <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Rôles</label>
                                            <div class="col-sm-12 col-md-7">
                                                <ng-select
                                                [items]="toutesroles"
                                                [(ngModel)]="etablissementsByRole"
                                                bindLabel="type"
                                                formControlName="role"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && fval_2.role.errors }"
                                                [multiple]="true"
                                                (change)="getNewEtablissemenst()">
                                             </ng-select>
                                                <div *ngIf="submitted && fval_2.role.errors" class="invalid-feedback">
                                                    <div *ngIf="fval_2.role.errors.required">Champs obligatoire</div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <!-- <div class="form-group row mb-4">
                                            <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Modules 
                                            </label>
                                            <div class="col-sm-12 col-md-7">   
                                            <br>
                                            <div *ngFor="let i of toutesmodules" >
                                                <div >
                                                <input type="checkbox" (change)="i.selected = !i.selected" [checked]="i.selected" >
                                                <label  >{{i.nom}}</label>
                                                </div> 
                                              </div>
                                             
                                            </div>
                                           
                                        </div> -->

                                            <!-- <div class="form-group row mb-4">
                                                <label
                                                    class="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                                <div class="col-sm-12 col-md-7">
                                                    <button  class="btn btn-primary" value="submit"
                                                        type="submit">Modifier</button>
                                                </div>
                                            </div> -->
                                            <div style="padding-top: 3%;" class="text-center">
              
                                                <button routerLink="/Super-Admin/établissements" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
                                                <button class="btn btn-primary button_list mb-2" (click)="onSubmit()">Sauvegarder</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>

    
   
</body>
