import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root'
})
export class StatementService {

  statements :any []=[];
  private _statementUrl=`${environment.baseUrl}/statements`
  constructor(private http: HttpClient) { }

  public getStatements() :Observable <any> {
    

    return this.http.get<any[]>(this._statementUrl, { 'headers': headers }).pipe(response =>
      response)
      }

      createStatement(role: any):Observable<Object> {
        return this.http.post(`${this._statementUrl}`,role);
      }
      
      deleteStatement(id : string)
      {
        return this.http.delete(`${this._statementUrl}/${id}`);
    
      } 
      
      getStatementById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._statementUrl}/${id}`);
    
      } 
      
      EditStatement(id:string,statement):Observable<Object> {
        return this.http.put(`${this._statementUrl}/${id}`,statement);
      }

      getStatemenentByChildren(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._statementUrl}/byChildren/${id}`);
    
      } 

      getLastStatementChildrenn(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._statementUrl}/last-statement-childrenn/${id}`);
    
      } 

      //espace parent
      getStatementsChildrenByParent(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._statementUrl}/statements-childrenn/espace-parent/${id}`);
    
      } 

       //espace parent
       getStatementsChildrenbyCurentDate(id : string):Observable<any>
       {
         return this.http.get<any>(`${this._statementUrl}/byChildren/byCurentDate/${id}`);
     
       } 



     
}
