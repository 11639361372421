<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Ajout d'une nouvelle actualité
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            SuperAdmin</a>
                        </li>
                        <li class="breadcrumb-item active"><a routerLink="/Super-Admin/actualités">Actualités</a></li>
                        <li class="breadcrumb-item active">Ajout d'une actualité </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="main-content">
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <form [formGroup]="registerForm"   >
                                        <div class="card-body">

                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Titre
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="titre" class="form-control" 
                                                        placeholder="Entrez le titre d'une actualité"  
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.titre.errors }" />  

                                                        <div *ngIf="submitted && fval_2.titre.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.titre.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                               
                                            </div>

                                            <!-- <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Description
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input type="text" formControlName="description" class="form-control" 
                                                        placeholder="Entrez la description d'une actualité"  
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.description.errors }" />  

                                                        <div *ngIf="submitted && fval_2.description.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.description.errors.required">Champs obligatoire</div>
                                                        </div>
                                                </div>
                                               
                                            </div> -->
                                            <div class="form-group row mb-4">
                                            <angular-editor style="margin-left: 20% !important;" [placeholder]="'créer votre cours'" formControlName="description" [config]="editorConfig"  [ngClass]="{ 'is-invalid': submitted && fval_2.description.errors }" ></angular-editor>
                                            <br>
                                            <div *ngIf="submitted && fval_2.description.errors" class="invalid-feedback-description" >
                                                <div>* Champs obligatoire</div>
                                            </div>
                                            </div>
                                         

                            
                                            <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Photo
                                                   </label>
                                                <div class="col-sm-12 col-md-7">
                                                    <input #fileInput type="file" formControlName="image" class="form-control" 
                                                       
                                                        [ngClass]="{ 'is-invalid': submitted && fval_2.image.errors }" />  

                                                        <div *ngIf="submitted && fval_2.image.errors" class="invalid-feedback">
                                                            <div *ngIf="fval_2.image.errors.required">Champs obligatoire</div>
                                                            <div *ngIf="fval_2.image.errors.pattern">Ce champs accept seulement des images de type png,jpg,gif et jpeg
                                                                
                                                               </div>
                                                        </div>
                                                </div>
                                               
                                            </div>
                                           
                                            <br>
                                            <br>
                                            <div class="form-group row mb-4">
                                                <label
                                                    class="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                                <div class="col-sm-12 col-md-7">
                                                    <button (click)="onSubmit()" class="btn btn-primary" value="submit"
                                                        type="submit">Ajouter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <!-- <h1>32131314</h1>
        <video width="320" height="240" controls>
            <source src="assets\backoffice\images\video3.mp4" type="video/mp4">
           
          Your browser does not support the video tag.
          </video> -->
    </section>
</body>



