<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Modifier un enseignant
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right">
                        <li class="breadcrumb-item"><a routerLink="/directeur"><i class="zmdi zmdi-home"></i>
                                Directeur</a>
                        </li>
                        <li class="breadcrumb-item active"><a routerLink="/directeur/puericulteurs">Puéricultrices</a></li>
                        <li class="breadcrumb-item active">Modifier une puéricultrice</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="main-content">
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card p-3"
                                    style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                                    <form [formGroup]="registerForm" *ngIf="this.registerForm" (ngSubmit)="onSubmit()">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Nom <b style="color:#f9656b;">*</b></label>
                                                        <input type="text" formControlName="firstName"
                                                            class="form-control"
                                                            placeholder="Entrez le nom du l'utilisateur"
                                                            [(ngModel)]="enseignant.firstName"
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.firstName.errors }" />

                                                        <div *ngIf="submitted && fval_2.firstName.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fval_2.firstName.errors.required">Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Prénom <b style="color:#f9656b;">*</b></label>
                                                        <input type="text" formControlName="lastName"
                                                            class="form-control"
                                                            placeholder="Entrez le prenom du l'utilisateur"
                                                            [(ngModel)]="enseignant.lastName"
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.lastName.errors }" />

                                                        <div *ngIf="submitted && fval_2.lastName.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fval_2.lastName.errors.required">Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Email <b style="color:#f9656b;">*</b></label>
                                                        <input type="email" formControlName="email" class="form-control"
                                                            placeholder="Entrez le l'adresse mail du l'utilisateur"
                                                            [(ngModel)]="enseignant.email"
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.email.errors }" />

                                                        <div *ngIf="submitted && fval_2.email.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fval_2.email.errors.required">Champ obligatoire
                                                            </div>
                                                            <div *ngIf="fval_2.email.errors.email">Merci de saisir une
                                                                adresse email valide.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Numéro de téléphone <b style="color:#f9656b;">*</b></label>
                                                        <ngx-intl-tel-input
                                                            [cssClass]="'form-control input-custom-phone'"
                                                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                            [selectFirstCountry]="false" [maxLength]="15"
                                                            [phoneValidation]="true" name="phone"
                                                            (countryChange)="onCountryChange($event)"
                                                            [formControl]="registerForm.get('phone')"></ngx-intl-tel-input>

                                                        <div *ngIf="submitted && fval_2.phone.errors"
                                                            class="invalid-feedback-tel">
                                                            <div *ngIf="fval_2.phone.errors.required">Champ obligatoire
                                                            </div>
                                                            <div *ngIf="registerForm.value.phone && fval_2.phone.valid"
                                                                style="color: green;">
                                                                Numéro de téléphone validé <i class="bi bi-check"></i>
                                                            </div>
                                                            <div *ngIf="registerForm.value.phone && fval_2.phone.invalid"
                                                                style="color: red;">
                                                                Numéro de téléphone non validé <i class="bi bi-x"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Mot de passe <b style="color:#f9656b;">*</b></label>
                                                        <input type="password" formControlName="password"
                                                            class="form-control"
                                                            placeholder="vous pouvez changer le mot de passe de l'enseignant"
                                                            [(ngModel)]="enseignant.password"
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.password.errors }" />

                                                        <div *ngIf="submitted && fval_2.password.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fval_2.password.errors.pattern">Le Mot de passe
                                                                de avoir au moinns 8 caractéres telque au moins un
                                                                lettre minuscule
                                                                et Lettre majuscule et un chiffre

                                                            </div>

                                                            <div *ngIf="fval_2.password.errors.required">Champ
                                                                obligatoire</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Confirmer le mot de passe <b style="color:#f9656b;">*</b></label>
                                                        <input type="password" formControlName="confirmpassword"
                                                            class="form-control"
                                                            placeholder="Confirmer le mot de passe "
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.confirmpassword.errors }" />

                                                        <div *ngIf="submitted && fval_2.confirmpassword.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fval_2.confirmpassword.errors.pattern">Le Mot de
                                                                passe de avoir au moinns 8 caractéres telque au moins un
                                                                lettre minuscule
                                                                et Lettre majuscule et un chiffre

                                                            </div>
                                                            <div *ngIf="fval_2.confirmpassword.errors.required">Champ
                                                                obligatoire</div>
                                                            <div *ngIf="fval_2.confirmpassword.errors.mustMatch">le mot
                                                                de passe et la confirmation du mot de passe ne sont pas
                                                                les mêmes </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="form-group row mb-4">
                                                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Matière
                                                   </label>
                                                <div *ngIf="matieres" class="col-sm-12 col-md-7">
                                                    <select formControlName="matiere" class="form-control"  [ngClass]="{ 'is-invalid': submitted && fval_2.matiere.errors }">
                                                        
                                                        <option value="" disabled selected>Choisissez un matiere pour le nouvel utilisateur
                                                            
                                                        </option>
                                                        <option *ngFor="let i of matieres"  [value]="i._id" >{{i.nom}}</option>
                                                    </select>
                                                        
                                                    <div *ngIf="submitted && fval_2.matiere.errors" class="invalid-feedback">
                                                        <div *ngIf="fval_2.matiere.errors.required">Champs obligatoire</div>
                                                    </div>   
                                                </div>
                                            </div>-->

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="col-form-label">Matières <b style="color:#f9656b;">*</b></label>
                                                        <ng-select formControlName="matiere" class="form-control"
                                                            [multiple]="true" [items]="toutesmatieres" bindLabel="nom"
                                                            bindValue="_id" placeholder="Select matieres"
                                                            (change)="onChange()"
                                                            [ngClass]="{ 'is-invalid': submitted && fval_2.matiere.errors }">
                                                        </ng-select>
                                                        <div *ngIf="submitted && fval_2.matiere.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fval_2.matiere.errors.required">Champ
                                                                obligatoire
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="text-center">
                                                <button routerLink="/directeur/puericulteurs"
                                                    class="btn btn-secondary mr-4 button_list mb-2"
                                                    type="reset">Annuler</button>
                                                <button class="btn btn-primary button_list mb-2" value="submit"
                                                    type="submit">Modifier</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</body>