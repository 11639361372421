import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { RoleService } from 'src/app/_services/role.service';
import { UserService } from 'src/app/_services/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {
  id
  firstNameu: string;
  lastNameu: string;
  emailu: string;
  phoneu: string;
  passwordu: string;
  roleu;
  etablissementu
  contryIsoSelected:any =CountryISO.Belgium
  user: User = new User()
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  roles: any;
  d
  etbalissements: any;
  test;
  verif = "Directeur"
  countryCode;
  @ViewChild('phoneInput') phoneInput: NgxIntlTelInputComponent;
  phone0;
  phone;
  codePays;
  numero;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private formBuilder: FormBuilder,
    private etablissementService: EtablissementService,
    public iziToast: Ng2IzitoastService,) { }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];

    this.d = this.userService.getUserById(this.id).subscribe(data => {

      this.user = data;
      this.user.password = "";
      this.test = data.role.type
      this.phone0 = data.phone;



      this.phone = data.phone.split(' ')
      this.codePays = this.phone[0]
      this.numero = this.phone[1]

      this.registerForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phone: [
          data.phone,
          [
            Validators.required,
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
        confirmpassword: ['', [Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
        role: ['', Validators.required],
        etablissement: ['', Validators.required],

      },
        {
          validator: MustMatch('password', 'confirmpassword')
        }
      )
      this.setCountryISOAfterViewInit(); 
    });


    this.getRoles();
    this.getEtablissements();
  }
  getRoles() {
    this.roleService.getRoles().subscribe(e => {
      this.roles = e;
    })
  }
  getEtablissements() {
    this.etablissementService.getEtablissements().subscribe(e => {
      this.etbalissements = e;
    



    })
  }

  get fval_2() {
    return this.registerForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    const phoneNumberObject = this.registerForm.get('phone').value;
    const phoneNumber = phoneNumberObject.internationalNumber;

    if (phoneNumber === this.phone0) {
      this.registerForm.value.phone = this.phone0;
    } else {
      this.registerForm.value.phone = phoneNumber;
    }

    this.userService.EditUser(this.id, this.registerForm.value).subscribe(data => {
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
      this.goToCategoriesList();
    });
  }
  goToCategoriesList() {
    this.router.navigate(['/Super-Admin/utilisateurs']);
  }

  onCountryChange(event: any) {

    this.countryCode = event.dialCode;
    this.registerForm.value.phone = "+" + this.countryCode + " " + this.numero
  }
  getSelectedCountryISO(): CountryISO {
    const phoneNumberObject = this.registerForm.get('phone').value;
    return phoneNumberObject ? phoneNumberObject.countryCode : CountryISO.Belgium;
  }
    setCountryISOAfterViewInit(): void {
    if (this.phoneInput) {
      const selectedCountryISO = this.getSelectedCountryISO();
      this.phoneInput.selectedCountryISO = selectedCountryISO;
    }
  }

}