import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField } from 'ngx-intl-tel-input';
import { Etablissement } from 'src/app/_models/etablissement';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { ModuleService } from 'src/app/_services/module.service';
import { RoleService } from 'src/app/_services/role.service';
import contries from 'src/app/List_countries.json';

@Component({
  selector: 'app-update-etablissement',
  templateUrl: './update-etablissement.component.html',
  styleUrls: ['./update-etablissement.component.scss']
})
export class UpdateEtablissementComponent implements OnInit {
  countryList = contries;
  etablissmentRole: any;
  etablissementsByRole: any;
  id: string;
  d;
  type
  etablissement: Etablissement = new Etablissement();
  registerForm: FormGroup;
  submitted = false;
  roles: any;
  modules: any;
  toutesroles: any = [];
  toutesmodules: any = [];
  contryIsoSelected:any =CountryISO.Belgium
  countryCode;
  @ViewChild('phoneInput') phoneInput: NgxIntlTelInputComponent;
  telephoneFixe
  phone0;
  codePays;
  numero;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;

  constructor(
    private cdRef: ChangeDetectorRef,
    private EtablissementService: EtablissementService,
    private roleService: RoleService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,
    private moduleService: ModuleService
  ) {}
  
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
  
    this.d = this.EtablissementService.getEtablissementsById(this.id).subscribe(async (data) => {
      this.etablissement = data;
      this.phone0 = data.telephoneFixe;
      this.roles = data.role;
      this.modules = data.module;
      this.phone0 = data.telephoneFixe;
  
      this.telephoneFixe = data.telephoneFixe.split(' ');
      this.codePays = this.telephoneFixe[0];
      this.numero = this.telephoneFixe[1];
  
      this.toutesroles = await this.roleService.getRoles().toPromise();
      this.etablissementsByRole = this.roles.map((role) => role.type);
  
      this.toutesroles.forEach((role) => {
        role.selected = this.roles.some((r) => r.type === role.type);
      });
  


      this.registerForm.patchValue({
        telephoneFixe: this.telephoneFixe.join(' '),
        role: this.roles.map((role) => ({ ...role, $ngOptionLabel: role.type || '' })),
      });
    });
  
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      pays: ['', Validators.required],
      adresse: ['', Validators.required],
      telephoneFixe: [
        '', // Initialize as an empty string
        [Validators.required],
      ],
      siteWeb: ['', Validators.required],
      type: ['', Validators.required],
      role: [[]],
    });
  
    this.getRoles();
  }
  

  get fval_2() {
    return this.registerForm.controls;
  }

  onChange() {}

  onSubmit() {
    this.submitted = true;
  
    if (this.registerForm.invalid) {
      return;
    }
  
    const selectedRoles = this.registerForm.value.role.map((role) => role._id);
    const selectedModules = this.registerForm.value.module;
  
    // Update the form values before making the API call
    this.registerForm.patchValue({
      role: selectedRoles,
      module: selectedModules
    });
  

    // Make the API call to update the establishment
    if(this.registerForm.value.telephoneFixe){
      this.registerForm.value.telephoneFixe =this.registerForm.value.telephoneFixe.number
    }
    this.EtablissementService.EditEtablissement(this.id, this.registerForm.value).subscribe(
      (data) => {
        this.iziToast.show({
          message: 'Modification avec succès',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          icon: 'bi-check-circle',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
        });
        this.goToEtablissementList();
      },
    );
  }
  

  goToEtablissementList() {
    this.router.navigate(['/Super-Admin/établissements']);
  }

  getRoles() {
    this.EtablissementService.getRolesEtablissmeentById(this.id).subscribe((e) => {
      this.etablissmentRole = e;
    });
  }

  getNewEtablissemenst() {
    const roleFormControl = this.registerForm.get('role');
    if (roleFormControl) {
      roleFormControl.setValue(roleFormControl.value); // Ensure the value is updated
    }
  }
  onCountryChange(event: any) {
    this.countryCode = event.dialCode;

    this.registerForm.get('numero').setValue("+" + this.countryCode + " " + this.numero);
  }
  
  getSelectedCountryISO(): CountryISO {
    const phoneNumberObject = this.registerForm.get('numero').value;
    return phoneNumberObject ? phoneNumberObject.countryCode : CountryISO.Belgium;
  }
    setCountryISOAfterViewInit(): void {
    if (this.phoneInput) {
      const selectedCountryISO = this.getSelectedCountryISO();
      this.phoneInput.selectedCountryISO = selectedCountryISO;
    }
  }
}
