import { Component, OnInit } from '@angular/core';
import { ChatService, Message } from 'src/app/_services/chat.service';
import { ClasseService } from 'src/app/_services/classe.service';
import { EnfantService } from 'src/app/_services/enfant.service';
import { EnseignantService } from 'src/app/_services/enseignant.service';
import { EtudiantService } from 'src/app/_services/etudiant.service';
import { MatiereService } from 'src/app/_services/matiere.service';

@Component({
  selector: 'app-directeur-home',
  templateUrl: './directeur-home.component.html',
  styleUrls: ['./directeur-home.component.scss']
})
export class DirecteurHomeComponent implements OnInit {

  enzel =false;
  nbreMatieres;
  nbreClasses;
  nbreEnseignants; 
  nbreEtudiants;
  // public messages : Message[];
  messages: Message[] = [];
  value: string;
  constructor(private matiereService:MatiereService,private classeSerrvice :ClasseService ,private enseignantservice :EnseignantService,private etudiantService : EtudiantService
    ,private enfantService :EnfantService,
    public chatService: ChatService) {
    // this.messages = [
    //   new Message('Welcome to chatbot universe', 'assets/images/bot.png', new Date())
    // ];
  }

  ngOnInit(): void {
   
    this.chatService.conversation.subscribe((val) => {
      this.messages = this.messages.concat(val);
    });
    this.totalMatieres();
    this.totalClasses();
    this.totaEnseignants();
    this.totalEtudiants();
  }

  totalMatieres()
  {
    this.matiereService.totalClasses().subscribe(data =>{
      this.nbreMatieres=data;
    })
  }
  totalClasses()
  {
    this.classeSerrvice.totalClasses().subscribe(data =>{
      this.nbreClasses=data;
    })
  }
  totaEnseignants()
  {
    this.enseignantservice.totalEnseignants().subscribe(data =>{
      this.nbreEnseignants=data;
    })
  }

  totalEtudiants()
  {
    this.enfantService.totalEnfants().subscribe(data =>{

      this.nbreEtudiants=data;
    })
  }
  sendMessage() {
    this.chatService.getBotAnswer(this.value);
    this.value = '';
  }
  }
