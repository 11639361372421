import { Component, OnInit, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;

import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenStorageService } from '../_services/token-storage.service';
import { ForgetPasswordService } from '../_services/forget-password.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  user:User;
  error :boolean = false;
  eroorMessage :string;
  
  private currentUserSubject_stay_active: BehaviorSubject<String>;
  public currentUser_stay_active: Observable<String>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private tokenStorage :TokenStorageService,
    private forgetPassword :ForgetPasswordService
  ) {
    this.currentUserSubject_stay_active = new BehaviorSubject(
      JSON.parse(localStorage.getItem('active_pwd_session'))
    );
    this.currentUser_stay_active =
      this.currentUserSubject_stay_active.asObservable();
  }

  public get currentUserValue_stay_active(): String {
    return this.currentUserSubject_stay_active.value;
  }
  

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
     
       
    });

    
    this.user=this.loginForm.value
  }

  get f() {
    return this.loginForm.controls;
  }

  onSignIn() {
    let r,t,status,id;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.forgetPassword
      .demande(this.loginForm.value)
      .subscribe(
        (data) => {
          this.toastr.success("Vérifiez votre e-mail pour un lien pour réinitialiser votre mot de passe. S'il n'apparaît pas dans quelques minutes, vérifiez votre dossier spam.");
          this.router.navigate(['/Connexion']);
        },
    
        (error) => {
          
          this.loading = false;
          this.error=true
          this.eroorMessage=error.error
          this.toastr.error(this.eroorMessage);
        }
      );

        
      }

}
