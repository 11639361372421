import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';;
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root'
})
export class SignInService {

  inscriptions :any []=[];
  private _signInUrl=`${environment.baseUrl}/signIn` 
  constructor(private http: HttpClient) { }

  public getInscriptions() :Observable <any> {
    

    return this.http.get<any[]>(this._signInUrl, { 'headers': headers }).pipe(response =>
      response)
      }

      DisableSingnIn(id:string):Observable<Object> {
        return this.http.get(`${this._signInUrl}/${id}/status`);
      }

      delete(id : string):Observable<Object>
 {
   return this.http.delete(`${this._signInUrl}/${id}`);

 } 

 accept(id : string):Observable<Object>
 {
   return this.http.get(`${this._signInUrl}/${id}/accepte`);

 } 
 refuse(id : string):Observable<Object>
 {
   return this.http.get(`${this._signInUrl}/${id}/rejet`);

 } 
    }
