import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Contact } from 'src/app/_models/contact';
import { ContactMarketing } from 'src/app/_models/contact-marketing';
import { SendContactsMarketing } from 'src/app/_models/send-contacts-marketing';
import { ContactMarketingService } from 'src/app/_services/contact-marketing.service';
import { SendContactsMarketingService } from 'src/app/_services/send-contacts-marketing.service';

@Component({
  selector: 'app-contacts-marketing',
  templateUrl: './contacts-marketing.component.html',
  styleUrls: ['./contacts-marketing.component.scss']
})
export class ContactsMarketingComponent implements OnInit {

  contacts: ContactMarketing [] = [];
  closeResult = '';
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  sendContact :SendContactsMarketing = new SendContactsMarketing();
  firstName:string
  lastName:string
  email:string
  
  constructor(private contactMarketingService :ContactMarketingService,private router: Router, 
    private route: ActivatedRoute,private modalService: NgbModal,private toastr: ToastrService,
    private sendContactMarketingService :SendContactsMarketingService)
   {
   
   }

  ngOnInit(): void {
  
    this.getContacts();
   
  }
  getContacts(){
   
    this.contactMarketingService.getContacts().subscribe(e=>{
     
        this.contacts=e;
       
          })
  }
  deleteContact(contact:Contact)
  
{
this.contactMarketingService.deleteContact(contact._id).subscribe(e=>
  {  
    this.getContacts()
  })
  this.modalService.dismissAll()
} 

open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
onTableDataChange(event: any) {
  this.page = event;
  this.getContacts();
}
onTableSizeChange(event: any): void {
  this.tableSize = event.target.value;
  this.page = 1;
  this.getContacts();
}

open3(content3) {
 
 
  
  this.modalService.open(content3, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
sendContactMarketing(id) {
  if( this.sendContact.objet=="" ||this.sendContact.objet==undefined
    
  || this.sendContact.message=="" ||this.sendContact.message==undefined )
  {
    this.toastr.error('objet te message sont obligatoires')
  }
  else{
  this.sendContactMarketingService.createRole(this.sendContact,id).subscribe(data=>{
    
    //this.goToListRoles();
    this.toastr.success('Ajout avec succés')
    this.sendContact=new SendContactsMarketing();
    this.modalService.dismissAll()
},
 );
 
}
}
search()
{
  if(this.firstName != "" || this.lastName != "" || this.email != "" )
   {
  this.contacts = this.contacts.filter(res=>{
    return res.firstName.toLocaleLowerCase().match(this.firstName.toLocaleLowerCase()) 
    || res.lastName.toLocaleLowerCase().match(this.lastName.toLocaleLowerCase()) 
    || res.email.toLocaleLowerCase().match(this.email.toLocaleLowerCase()
     ) 
  })
   }
   else if(this.firstName == "" && this.lastName == "" && this.email == "")
   {
     this.getContacts();
   }
}

}
