import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listes-photos-enfants-by-parent',
  templateUrl: './listes-photos-enfants-by-parent.component.html',
  styleUrls: ['./listes-photos-enfants-by-parent.component.scss']
})
export class ListesPhotosEnfantsByParentComponent implements OnInit {

  id
  ids
  datebe
  photosEnfants;
  page: number = 1;
  count: number = 0;
  tableSize: number = 9;
  tableSizes: any = [3, 6, 9, 12];
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/photosPicutresChildren/';

    constructor(private router: Router,private profilEnfantService :ProfilEnfantService,private sanitization: DomSanitizer) { }
  
    ngOnInit(): void {
      this.id=this.router.url.split('/');
      this.ids=this.id[4]      
      this.getPhotosAndDescriptionsEnfants()
    }
    onTableDataChange(event: any) {
      this.page = event;
      this.getPhotosAndDescriptionsEnfants();
    }
  
    getPhotosAndDescriptionsEnfants(){
      
      this.profilEnfantService.getProfils(this.ids).subscribe(e=>{
      
         this.photosEnfants=e
         
            })   
    }


    search()
    {
      if (this.datebe != "" )
       {
      this.photosEnfants = this.photosEnfants.filter(res=>{
        return  res.datebe.toLocaleLowerCase().match(this.datebe.toLocaleLowerCase())
        
      })
      
       }
       else if(this.datebe == "")
       {
         this.getPhotosAndDescriptionsEnfants();
       }
    }
  
}
