import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../_services/authentication.service';
import { ForgetPasswordService } from '../_services/forget-password.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
@Component({
  selector: 'app-update-password-globale',
  templateUrl: './update-password-globale.component.html',
  styleUrls: ['./update-password-globale.component.scss']
})
export class UpdatePasswordGlobaleComponent implements OnInit {

  loginForm: FormGroup;
  registerFormCode: FormGroup;
  loading = false;
  submitted = false;
  code =""
  submittedVerifCode = false
  returnUrl: string;
  user:User;
  error :boolean = false;
  eroorMessage :string;
  verifCode = false
  
  private currentUserSubject_stay_active: BehaviorSubject<String>;
  public currentUser_stay_active: Observable<String>;
  idPath: string[];
  ids: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private tokenStorage :TokenStorageService,
    private forgetPassword :ForgetPasswordService
  
  ) {
    this.currentUserSubject_stay_active = new BehaviorSubject(
      JSON.parse(localStorage.getItem('active_pwd_session'))
    );
    this.currentUser_stay_active =
      this.currentUserSubject_stay_active.asObservable();
  }

  public get currentUserValue_stay_active(): String {
    return this.currentUserSubject_stay_active.value;
  }
 

  ngOnInit() {
    this.idPath=this.router.url.split('/');
    this.ids=this.idPath[2]
    this.registerFormCode = this.formBuilder.group({
          
         
      code: ['', [Validators.required ,]],
    }, 
    );

    this.loginForm = this.formBuilder.group({
      newpassword: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
      confirmpassword: ['', [Validators.required , Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
     
       
    },
    {
      validator: MustMatch('newpassword', 'confirmpassword')
  }
    );

    
    this.user=this.loginForm.value
    
  }

  get f() {
    return this.loginForm.controls;
  }
  get f2() { return this.registerFormCode.controls; }


 

      onSignIn() {
        let r,t,status,id;
        this.submitted = true;
        if (this.loginForm.invalid) {
          return;
        }
        this.loading = true;
        this.forgetPassword
          .updatePassword(this.ids,this.loginForm.value)
          .subscribe(
            (data) => {
              this.toastr.success("Nouveau mot de passe défini avec succès.");
              this.router.navigate(['/Connexion']);
            },
        
            (error) => {
              
              this.loading = false;
              this.error=true
              this.eroorMessage=error.error
              this.toastr.error(this.eroorMessage);
              this.router.navigate(['/Connexion']);
            }
          ); 
          }
          go()
{
  this.loginForm.reset();
}
  onReset() {
      this.submitted = false;
      this.loginForm.reset();
  }    

  checkCode()
  {    this.submittedVerifCode = true;
        // stop here if form is invalid
        if (this.registerFormCode.invalid) {
            return;
        }
         this.forgetPassword.verifCodeSended(this.ids,this.registerFormCode.value).subscribe(
          (data) => { 
            if(data['message'] == 'ok')
            {
            this.verifCode = true
            }
            else
            {
              this.verifCode = false
              this.code = "00000"
              this.toastr.error("Veuillez vérifier bien le code envoyé à votre boite mail");
            }
             
           },
           (error) =>{
            this.toastr.error("Résiliatiser votre mot de passe de nouveau");
           }
           )

         
        // display form values on success
        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerFormCode.value, null, 4));
        //this.verifCode = true 
    }


}
