import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Actualite } from 'src/app/_models/actualite';
import { ActualiteService } from 'src/app/_services/actualite.service';

@Component({
  selector: 'app-update-actualite',
  templateUrl: './update-actualite.component.html',
  styleUrls: ['./update-actualite.component.scss']
})
export class UpdateActualiteComponent implements OnInit {

  @ViewChild('fileInput' , {static:false}) fileInput :ElementRef;

  actualites :Actualite =new Actualite();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres ;
  classes ;
  show;
  id
  d
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actualiteService: ActualiteService,
    private toastr: ToastrService
  ) { 
   
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
   
    this.d=this.actualiteService.getActualiteById(this.id).subscribe(async data=>{
      this.actualites=data;
   })

    this.registerForm = this.formBuilder.group({
      titre: ['', Validators.required],     
      description:  ['', Validators.required],
      image: [
        '',
        [
          Validators.pattern("[^\\s]+(.*?)\\.(png|PNG|gif|GIF|jpg|JPG|jpeg|JPEG )$"),
        ],
      ],
    });
   
  }

  
  get fval_2() {
    return this.registerForm.controls;
  }
  
 

  UpdateCours() {
    const imagBlob =this.fileInput.nativeElement.files[0];
  const form =new FormData();
  form.set('image',imagBlob);
  form.set('titre',this.registerForm.value.titre);
  form.set('description',this.registerForm.value.description);

  this.submitted = true;
 
  if (this.registerForm.invalid) {
    return;
  }
  
  this.actualiteService.EditActualite(this.id ,form).subscribe(
    (data) => {
      
      this.toastr.warning('Modification avec succés')
      this.goToList();
    },
   
  );
 
}
goToList(){
  this.router.navigate(['/Super-Admin/actualités' ] )
  }
  onSubmit(){
   
    this.UpdateCours();
  }
}
