import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models/user.model';
import { ParentService } from 'src/app/_services/parent.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-update-parent',
  templateUrl: './update-parent.component.html',
  styleUrls: ['./update-parent.component.scss']
})
export class UpdateParentComponent implements OnInit {

  id:string;
  parent:User=new User();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  matieres : any;
  d;
  toutesmatieres: any = [];
  Ttmatieres;
  tabs;
  countryCode
  @ViewChild('phoneInput') phoneInput: NgxIntlTelInputComponent;
  phone0;
  phone;
  codePays;
  numero;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  
  constructor(private cdRef:ChangeDetectorRef,private parentService :ParentService,
    private router: Router, 
    private route: ActivatedRoute,
    public iziToast: Ng2IzitoastService,
    private formBuilder: FormBuilder,

   ) 
   {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.d=this.parentService.getParentById(this.id).subscribe(async data=>{
      this.parent=data;
      this.parent.password="";
      this.phone0=data.phone;


      this.phone=data.phone.split(' ')
      this.codePays=this.phone[0]
      this.numero=this.phone[1]
      this.registerForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phone: [
          data.phone,
          [
            Validators.required,
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
          confirmpassword: ['', [Validators.pattern('((?=)(?=.*[a-z])(?=.*[A-Z]).{8,})'),]],
      
      },
      {
          validator: MustMatch('password', 'confirmpassword')
      }
      );
  
    })
    

    



      
    
    //this.getMatieres();
    
    //this.getEtablissements();
  }
  

  get fval_2() {
    return this.registerForm.controls;
  }
  onChange()
 {


 }
  onSubmit()
  {
    let results= [];
    this.submitted = true;
    // return for here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  
    const phoneNumberObject = this.registerForm.get('phone').value;
    const phoneNumber = phoneNumberObject.internationalNumber;
  
    if (phoneNumber === this.phone0) {
      this.registerForm.value.phone = this.phone0;
    } else {
      this.registerForm.value.phone = phoneNumber;
    }
    
    this.parentService.EditParent(this.id , this.registerForm.value).subscribe( data=>{
      this.iziToast.show({
        message: 'Modification avec succès',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
     this.goToParentsList();
      
    })
  }
  goToParentsList()
  {
    this.router.navigate(['/directeur/parents']);
  }

  onCountryChange(event: any) {

    this.countryCode = event.dialCode;
    this.registerForm.value.phone = "+" + this.countryCode + " " + this.numero
  }
  getSelectedCountryISO(): CountryISO {
    const phoneNumberObject = this.registerForm.get('phone').value;
    return phoneNumberObject ? phoneNumberObject.countryCode : CountryISO.Belgium;
  }
    setCountryISOAfterViewInit(): void {
    if (this.phoneInput) {
      const selectedCountryISO = this.getSelectedCountryISO();
      this.phoneInput.selectedCountryISO = selectedCountryISO;
    }
  }

}
