import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models/user.model';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';

const baseUrl = `${environment.baseUrl}/forgetPassword`;

const headerDict = {
  'Access-Control-Allow-Origin': '*',
};
const requestOptions = {
  headers: new HttpHeaders(headerDict),
};
@Injectable({
  providedIn: 'root'
})
export class ForgetPasswordService {
  matieres :any []=[];
  private _forgetPassword=`${environment.baseUrl}/forgetPassword` 
  constructor(private http: HttpClient) { }

  demande(email):Observable<Object> {
    return this.http.post(`${this._forgetPassword}`,email);
  }
  
  updatePassword(token:string,user:User):Observable<Object> {
    return this.http.put(`${this._forgetPassword}/forgetPassowrd/${token}`,user);
  }
  verifCodeSended(token:string,user):Observable<Object> {
    return this.http.post(`${this._forgetPassword}/verifCodeSended/${token}`,user);
  }


}
