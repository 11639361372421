<section class="content home">
  <div class="block-header">
    <div class="row">
      <div class="col-lg-7 col-md-6 col-sm-12">
        <h2 style="color: #5dc4be !important">Liste des photos</h2>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item">
            <a routerLink="/parent"><i class="zmdi zmdi-home"></i> Parent</a>
          </li>
          <li class="breadcrumb-item active">
            <a routerLink="/parent/liste-enfants">Liste des enfants</a>
          </li>
          <li class="breadcrumb-item active">Liste des photos</li>
        </ul>
      </div>
    </div>
  </div>
  <br />
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card product_item_list">
          <div class="body table-responsive">
            <div class="row">
              <div
                *ngFor="let photo of photosEnfants"
                class="col-lg-3 col-md-6 col-sm-12"
                style="margin-bottom: 1rem;"
              >
                <div
                  class="card product_item_list"
                  style="width: 100%; height: 100%"
                >
                  <div class="body" style="width: 100%; height: 100%">
                    <figure
                      class="grid__figure"
                      style="width: 100%; height: 100%"
                    >
                      <img
                        src="{{ BaseUrl }}/{{ photo.filename }}"
                        alt=""
                        style="width: auto; height: 100%; object-fit: fill"
                      />
                    </figure>
                  </div>
                  <div class="card-footer" style="text-align: center">
                    <p
                      style="
                        color: #5dc4be;
                        font-weight: 400;
                        font-family: Nunito sans-serif;
                        font-size: 1.45rem;
                        font-weight: 600;
                        text-transform: capitalize;
                      "
                    >
                    {{photo.date| date:'dd/MM/yyyy'}}
                  </p>
                  </div>
                </div>
              </div>
              <div
                *ngIf="photosEnfants && photosEnfants.length == 0"
                class="table-responsive"
                style="text-align: center"
              >
                <p style="padding-bottom: 45px; padding-top: 35px">
                  Aucune photo n'est trouvée
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
