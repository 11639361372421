<!-- Main Content -->
<section class="content home">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Accueil
                    <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a routerLink="/backoffice"><i class="zmdi zmdi-home"></i>
                            SuperAdmin</a>
                    </li>
                    <li class="breadcrumb-item active">Tableau de bord</li>
                </ul>
            </div>
        </div>
    </div>
    <main class="py-6 bg-surface-secondary">
        <div class="container-fluid">
            <!-- Card stats -->
            <div class="row g-6 mb-6">
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Rôles</span>
                                    <br>
                                    <span class="h3 font-bold mb-0">{{nbreRoles}}</span>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Établissements </span>
                                    <br>
                                    <span class="h3 font-bold mb-0">{{nbreEtablissements}}</span>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Directeurs</span>
                                    <br>
                                    <span class="h3 font-bold mb-0">{{nbreDirecteurs}}</span>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
           </div>
           </main>

</section>