<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        Ajouter une photo
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="onReset()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="selectedFiles.length == 0" class="d-flex align-items-center justify-content-center mb-4">
        <!-- Rectangle pour le téléchargement de photos -->
        <label for="fileInput" class="upload-box">
            <i class="bi bi-camera"></i>
            <input type="file" #fileInput id="fileInput" (change)="onFileSelected($event);onFileChanged($event)"  accept="image/*">
        </label>
        
    </div>
    <!-- Afficher les miniatures des photos sélectionnées -->
    <div class="mb-3">
        <div *ngFor="let file of selectedFiles">
            <div class="file-preview">
                <img *ngIf="file.type.startsWith('image')" [src]="getSafeUrl(file)" alt="Selected Image" class="upload-box">
                <!-- <span class="file-name">{{ file.name }}</span> -->
                <button type="button" class="btn btn-danger btn-sm" (click)="removeFile(file)">
                    <i class="bi bi-x"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="text-center">
        <button class="btn btn-primary mr-2" type="reset" (click)="onReset()">Annuler</button>
        <button class="btn btn-secondary" (click)="addPhotoPicture()">Ajouter</button>
    </div>
</div>