export class Actualite {

    id : string;
    _id:string;
    titre :string;
    description :string;
    date :Date;
    originalname:string ;
    filename:string ;
    path : string;
}
