import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// export class Message {
//   constructor(public author: string, public content: string) {}
// }
export class Message {
  constructor(public author: string, public content: string) {}
}
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor() {}
  conversation = new Subject<Message[]>();
  messageMap = {
    "Bonjour": "Bonjour je m'appele BrainNut comment je peux vous aider ?",
    "bonjour": "Bonjour je m'appele BrainNut comment je peux vous aider ?",
    "cava ?": "Oui , et vous ? ",
    "C'est quoi BrainNut ?": "c'est une nouvelle solution creé pour l'éducation en ligne .",
    "BrainNut ?": "c'est une nouvelle solution creé pour l'éducation en ligne .",
    "C'est quoi BrainNut ": "c'est une nouvelle solution creé pour l'éducation en ligne .",
    "c'est quoi BrainNut? ": "c'est une nouvelle solution creé pour l'éducation en ligne .",
    "c'est quoi BrainNut ": "c'est une nouvelle solution creé pour l'éducation en ligne .",
    "Comment utliser l'application BrainNut ?":"Il faut contacter le superviseur de la platforme pour vous aider .",
    "default": "Je n'ai pas compris votre question,vous pouvez répéter votre question et merci ."
  }
  getBotAnswer(msg: string) {
    const userMessage = new Message('user', msg);  
    this.conversation.next([userMessage]);
    const botMessage = new Message('bot', this.getBotMessage(msg));
    
    setTimeout(()=>{
      this.conversation.next([botMessage]);
    }, 1500);
  }

  getBotMessage(question: string){
    let answer = this.messageMap[question];
    return answer || this.messageMap['default'];
  }
}