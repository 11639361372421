<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>Liste des rôles
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right" style="background-color: transparent;"> 
                        <li class="breadcrumb-item"><a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i>
                            Accueil</a>
                        </li>
                        <li class="breadcrumb-item active">Rôles</li>
                        <li class="breadcrumb-item active">Liste des rôles</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card-deck">
                        <div *ngFor="let i of roles | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let k = index"
                            class="card product-item-card coral-card">
                            <div class="card-body">
                                <h5 *ngIf="i.type == 'Enseignant'" class="card-title">Puéricultrice </h5>
                                <h5 *ngIf="i.type != 'Enseignant'" class="card-title">{{ i.type }}</h5>
                                <p *ngIf="i.type == 'Directeur' " class="card-text">Le directeur de la crèche est le pilier central qui orchestre 
                                    toutes les activités au sein de l'établissement. Il est responsable de la gestion administrative, financière et organisationnelle de la crèche.</p>
                                <p *ngIf="i.type == 'Enseignant' " class="card-text">La puéricultrice  de la crèche incarne la bienveillance et la créativité au quotidien. 
                                    Sa mission principale est de créer un environnement stimulant et sécurisé pour les tout-petits. Avec une approche ludique et éducative.</p>
                                <p *ngIf="i.type == 'Parent' " class="card-text">En tant que parent d'un enfant à la crèche, 
                                    votre rôle est essentiel dans le parcours éducatif de votre tout-petit. Vous collaborez étroitement avec le personnel de la crèche.</p>
                            </div>
                            <div class="card-footer">
                                <small class="text-muted"></small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        
    </section>
   

    <!-- <div *ngIf="updating" id="update-product">
        <app-update-product></app-update-product>
    </div> -->
</body>
