<app-navbar></app-navbar>
<aside id="leftsidebar" class="sidebar fixed-top" [ngClass]="{'visible': isSidebarVisible}" style="background-color: #f9656b;">
    <div class="menu">
        <ul class="list">
            <li>
                <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin/chantiers/suppafacs">
                    <img src="../../assets/backoffice/images/logo BN white.png" alt="..." height="100%">
                </a>
      
            </li>
            <!-- <li class="header">MAIN</li> -->
            <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="/directeur" ><i class="bi bi-house-door-fill"></i><span>Tableau de bord</span></a>
                
            </li>

            <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="activites" ><i class="bi bi-pen-fill"></i><span>Activités</span></a>
                
            </li>

            <!-- <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="classes" ><i class="bi bi-folder-plus"></i><span>Classes</span></a>
                
            </li> -->

            <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="puericulteurs" ><i class="bi bi-person-video3"></i><span>Puéricultrices </span></a>
                
            </li>

            

            <li *ngIf="test != type" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}">
                 <a routerLink="étudiants" >
                <i class="bi bi-people-fill"></i> <span>Étudiants</span>
                </a>
                
            </li>

            <li *ngIf="test == type" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="enfants" ><i class="bi bi-people-fill"></i><span>Enfants</span></a>
                
            </li>

            <li *ngIf="test == type" routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="parents" ><i class="bi bi-person-standing"></i><span>Parents</span></a>
                
            </li>

            <!-- <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="forums" ><i
                        class="zmdi zmdi-border-color"></i><span>Forums</span></a>
                
            </li> -->

            <!-- <li routerLinkActive="active open" [routerLinkActiveOptions]="{exact: true}"> <a
                routerLink="/DirecteurHomeCrech" ><i
                        class="zmdi zmdi-home"></i><span>Tableau de bord</span></a>
                
            </li> -->

<!--                
            <li routerLinkActive="active open"> <a href="javascript:void(0);" class="menu-toggle">
                <i class="zmdi zmdi-border-color"></i>
           <span>Matières</span>
        </a>
        <ul class="ml-menu">
            <li routerLinkActive="active open" routerLink="matieres/addMatiere"
                [routerLinkActiveOptions]="{exact: true}"><a>Ajout d'une
                     matière</a></li> 
            <li routerLinkActive="active open" routerLink="matieres"
                [routerLinkActiveOptions]="{exact: true}"><a>Liste des
                    matières</a></li>
        </ul>
          </li>
             
            
    <li routerLinkActive="active open"> <a href="javascript:void(0);" class="menu-toggle">
                <i class="zmdi zmdi-calendar-note"></i>
       <span>Classes </span>
        </a>
          <ul class="ml-menu">
        <li routerLinkActive="active open" routerLink="classes/addClasse"
              [routerLinkActiveOptions]="{exact: true}"><a>Ajout d'une
                classe</a></li>
          <li routerLinkActive="active open" routerLink="classes"
              [routerLinkActiveOptions]="{exact: true}"><a>Liste des
                classes</a></li>
                   </ul>
                    </li>

    <li routerLinkActive="active open"> <a href="javascript:void(0);" class="menu-toggle"><i
            class="zmdi zmdi-case"></i><span>Enseignants</span>
    </a>
    <ul class="ml-menu">

        <li routerLinkActive="active open" routerLink="enseignants/addEnseignant" 
        [routerLinkActiveOptions]="{exact: true}"><a>Ajout d'un
            enseignant</a></li>
        <li routerLinkActive="active open" routerLink="enseignants" 
            [routerLinkActiveOptions]="{exact: true}"><a>Liste des
                enseignants</a></li>    
    </ul>
    
</li>

<li *ngIf="test != type" routerLinkActive="active open"> <a href="javascript:void(0);" class="menu-toggle"><i
    class="zmdi zmdi-accounts-outline"></i><span>Étudiants</span>
</a>
<ul class="ml-menu">

<li  routerLinkActive="active open" routerLink="etudiants/addEtudiant" 
[routerLinkActiveOptions]="{exact: true}"><a>Ajout d'un
    étudiant</a></li>
<li routerLinkActive="active open" routerLink="etudiants" 
    [routerLinkActiveOptions]="{exact: true}"><a>Liste des
        étudiants</a></li>    
</ul>

</li>

<li *ngIf="test == type" routerLinkActive="active open"> <a href="javascript:void(0);" class="menu-toggle"><i
class="zmdi zmdi-accounts-outline"></i><span>Enfants</span>
</a>
<ul class="ml-menu">

<li  routerLinkActive="active open" routerLink="enfants/addEnfant" 
[routerLinkActiveOptions]="{exact: true}"><a>Ajout d'un
enfant</a></li>
<li routerLinkActive="active open" routerLink="enfants" 
[routerLinkActiveOptions]="{exact: true}"><a>Liste des
    enfants</a></li>    
</ul>

</li>


<li *ngIf="test == type" routerLinkActive="active open"> <a href="javascript:void(0);" class="menu-toggle"><i
class="zmdi zmdi-accounts-outline"></i><span>Parents</span>
</a>
<ul class="ml-menu">

<li  routerLinkActive="active open" routerLink="parents/addParent" 
[routerLinkActiveOptions]="{exact: true}"><a>Ajout d'un
parent</a></li>
<li routerLinkActive="active open" routerLink="parents" 
[routerLinkActiveOptions]="{exact: true}"><a>Liste des
    parents</a></li>    
</ul> 

</li>-->


        </ul>
    </div>
</aside> 
<router-outlet></router-outlet>