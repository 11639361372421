import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CommunicationService } from 'src/app/_services/communication.service';
import { StatementService } from 'src/app/_services/statement.service';

@Component({
  selector: 'app-popup-add-children-statment',
  templateUrl: './popup-add-children-statment.component.html',
  styleUrls: ['./popup-add-children-statment.component.scss']
})
export class PopupAddChildrenStatmentComponent implements OnInit {
  registerForm: FormGroup;
    submitted = false;
    modalRef: NgbModalRef;
    childrenTOStameent: any;
    closeResult: string;
    currentDate: any;
    naps = ["1h","1h 30m","2h","2h 30m","3h","3h 30m","4h","4h 30m","5h"]
    temperatures = ["36","36.5","37","37.5","38","38.5","39","39.5","40","40.5","41"]
    events =["Fête des anniversaires","Spectacle de talents","Musique et animation"]
    meals =["Bulgur,Légumes et Poisson","P.D.T,Légumes","RIZ,Légumes et Boeuf","Bulgur,Légumes et Oeufs"]
    snacks =["Fruits du jour","Yaourt fruits","Tartine","Galette"]
    numbreSaltSaisi= "1"
    constructor(
      private router: Router,
      private statementService :StatementService,
      public iziToast: Ng2IzitoastService,
      private modalService: NgbModal,
     private formBuilder: FormBuilder,
     private communicationService: CommunicationService,
    ) { }

  ngOnInit(): void {
    this.currentDate = new Date(); 

    this.registerForm = this.formBuilder.group({
      arrivingTime : ['', Validators.required],
      departureTime : ['', Validators.required],
      meal: ['', Validators.required],
      snack : ['', Validators.required],
      salt: [false],
      nbreSalt : [],
      event: ['', Validators.required],
      temperature: ['',Validators.required],
      note: ['', ],
      nap: ['', Validators.required],
      enfant :['']
  },);
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.registerForm.value.enfant = this.childrenTOStameent
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    if (!this.registerForm.value.salt) {
        this.registerForm.value.salt = false;
    }
    if(this.registerForm.value.salt){
      this.registerForm.value.nbreSalt = this.numbreSaltSaisi
    }

    this.statementService.createStatement(this.registerForm.value).subscribe((data) => {
      this.iziToast.show({
        message: "Ajout d'un compte rendu avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      
       this.router.navigate(['puericulteur/liste-enfants/details', this.childrenTOStameent])
       this.communicationService.updateData();
      this.modalService.dismissAll();
      //location.reload();

    });   
}
onReset() {
  this.modalService.dismissAll();

}
}
