<body>
    <section class="content ecommerce-page">
        <div class="block-header">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-12">
                    <h2>
                        Liste des établissements
                        <!-- <small class="text-muted">Bienvenue à BrainNut </small> -->
                    </h2>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <ul class="breadcrumb float-md-right" style="background-color: transparent">
                        <li class="breadcrumb-item">
                            <a routerLink="/Super-Admin"><i class="zmdi zmdi-home"></i> Accueil</a>
                        </li>
                        <li class="breadcrumb-item active">Établissements</li>
                        <li class="breadcrumb-item active">Liste des établissements</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card product_item_list"  style="border: none;">
                        <div class="body table-responsive">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <input id="search-input" type="search" #myInput class="form-control"
                                            [(ngModel)]="firstName" [(ngModel)]="adress" [(ngModel)]="pays" [(ngModel)]="phone"
                                            (input)="search()" placeholder="Recherche"
                                            style="border-radius: 5rem 5rem 5rem 5rem; width: 70%" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" style="
                          border-radius: 5rem 5rem 5rem 5rem;
                          border-color: coral;
                        ">
                                                <i class="zmdi zmdi-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <button routerLink="ajout-établissement" class="btn btn-primary ajout"><i class="bi bi-building-fill"> Ajouter un établissement </i>
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="col-sm" *ngIf= "message"  class="alert alert-success" role="alert" >
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> {{message}}</strong>
                        </div>
                        <div class="col-sm" *ngIf= "message2"  class="alert alert-warning" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> {{message2}}</strong>
                        </div>
                        <div class="col-sm" *ngIf= "show == true"  class="alert alert-danger" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> {{message3}}</strong>
                        </div> -->
                            <!--
                        <div class="col-sm" *ngIf= "enabled == false"  class="alert alert-danger" role="alert">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">X</a>
                            <strong> Désactivation d'un établissement  </strong>
                        </div>
                    -->
                            <br />
                            <table class="table table-striped">
                                <tr>
                                    <th class="text-center">Nom</th>
                                    <!-- <th>Numéro de registre</th> -->
                                    <th class="text-center">Pays</th>
                                    <th class="text-center">Adresse</th>
                                    <th class="text-center">Téléphone</th>
                                    <!-- <th>Site web</th> -->
                                    <th class="text-center">Type</th>
                                    <!-- <th >Rôles</th> -->

                                    <th class="text-center">Actions</th>
                                </tr>

                                <tr *ngFor="
                    let i of etbalissements
                      | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                          };
                    let k = index
                  ">
                                    <td class="text-center">
                                        <span class="text-muted text-capitalize">{{ i.firstName }}</span>
                                    </td>
                                    <!-- <td><span class="text-muted">{{i.numeroRegister}}</span> </td> -->
                                    <td class="text-center">
                                        <span class="text-muted text-capitalize">{{ i.pays }}</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="text-muted text-capitalize">{{ i.adresse }}</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="text-muted">{{i.telephoneFixe}}</span>
                                    </td>
                                    <!-- <td ><a [href]="i.url" target="_blank">{{i.siteWeb}}</a></td> -->
                                    <!-- <td ><a></a><span class="text-muted" target="_blank">{{i.type}}</span> </td>-->
                                    <td class="text-center">
                                        <span  [ngClass]="getBadgeClass(i.type)">
                                          {{ i.type }}
                                        </span>
                                    </td>
                                    <!-- <td (click)="see_details()"><span
                                                        [ngClass]="{ 'col-green': product.article_status === 'Publish', 'col-red': product.article_status === 'Draft', 'col-amber': product.article_status === 'Pending' }">
                                                        article_status</span></td> -->
                                    <td class="text-center">
                                        <button (click)="updateEtablissement(i._id)"
                                            class="btn btn-default waves-effect waves-float waves-green">
                                            <i class="zmdi zmdi-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                            <div *ngIf="etbalissements && etbalissements.length > 7" class="card-footer border-0 py-5"
                                style="background-color: white;">
                                <div class="d-flex justify-content-center">
                                    <pagination-controls class="my-pagination" previousLabel="Précédent"
                                        nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                            <div *ngIf="etbalissements && etbalissements.length == 0" class="table-responsive" style="text-align: center">
                                <p style="padding-bottom: 45px; padding-top: 35px ">
                                    Aucun établissement n'est trouvé
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</body>