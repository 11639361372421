import { User } from "./user.model";

export class Profil {
    id : string;
    _id:string;
   
    user : User;
   
    originalname:string ;
    filename:string ;
    path : string;
    
}
