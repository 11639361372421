import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Classe } from 'src/app/_models/classe';
import { Enfant } from 'src/app/_models/enfant';
import { ClasseService } from 'src/app/_services/classe.service';
import { EnfantService } from 'src/app/_services/enfant.service';
import { ProfilEnfantService } from 'src/app/_services/profil-enfant.service';
import { StatementService } from 'src/app/_services/statement.service';
import { UserService } from 'src/app/_services/user.service';
import { PopupAddChildrenStatmentComponent } from 'src/app/admin/popup/popup-add-children-statment/popup-add-children-statment.component';
import { PopupAddPhotoChildrenComponent } from 'src/app/admin/popup/popup-add-photo-children/popup-add-photo-children.component';

@Component({
  selector: 'app-list-enfants',
  templateUrl: './list-enfants.component.html',
  styleUrls: ['./list-enfants.component.scss']
})
export class ListEnfantsComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  selectedFile: File;


  id: string;
  classe: Classe = new Classe();
  enfant: Enfant = new Enfant();
  firstName: string
  lastName: string
  adresse: string
  //registerForm: FormGroup;
  //loading = false;
  //submitted = false;
  returnUrl: string;
  enseignants: any;
  d;
  enfants: any = [];
  page: number = 1;
  copyListEnfant: any;
  id4
  idAnimateurCurent

  registerForm: FormGroup;
    submitted = false;
    modalRef: NgbModalRef;
  childrenTOStameent: any;
  childrenphoto:any;
  closeResult: string;

  constructor(private classeService: ClasseService,
    private router: Router,
    private route: ActivatedRoute,
    private enfantService: EnfantService,
    private userService : UserService,
    private formBuilder: FormBuilder,
    private statementService :StatementService,
    private profilEnfantService :ProfilEnfantService,
    public iziToast: Ng2IzitoastService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal

    //private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      meal: ['', Validators.required],
      salt: [false],
      event: ['', Validators.required],
      temperature: ['',Validators.required],
      note: ['', Validators.required],
      nap: ['', Validators.required],
      enfant :['']
  },);
    this.id = this.route.snapshot.params['id'];
    
    this.getEtudiants();

  }

  getEtudiants() {

    this.idAnimateurCurent = localStorage.getItem("user")
    this.userService.getUserById(this.idAnimateurCurent).subscribe(e => {
     let diroctor = e
   
    this.enfantService.getEnfantsByDirector(diroctor.directeur._id).subscribe(e => {
      this.enfants = e
      this.copyListEnfant = this.enfants;
    })
  })
  }
  addPhotoEnfants(idEnf: string) {
    this.router.navigate(['Enseignant/Liste-enfants/Ajout-photo', idEnf])
  }

  viewListesPhotosEnfants(idEnf: string) {
    this.router.navigate(['Enseignant/Liste-enfants/Liste-photos', idEnf])
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getEtudiants();
  }
  search() {
    this.enfants = this.copyListEnfant;
    let filteredEnfant = [...this.enfants];
  
    if (this.firstName !== "" || this.lastName !== "" || this.adresse !== "") {
      filteredEnfant = this.enfants.filter(res => {
        return (res.nom && res.nom.toLocaleLowerCase().includes(this.firstName.toLocaleLowerCase())) ||
               (res.prenom && res.prenom.toLocaleLowerCase().includes(this.lastName.toLocaleLowerCase())) ||
               (res.adresse && res.adresse.toLocaleLowerCase().includes(this.adresse.toLocaleLowerCase()));
      });
  
      this.enfants = filteredEnfant;
    } else if (this.firstName === "" && this.lastName === "" && this.adresse === "") {
      this.getEtudiants();
    }
  }


  //
  get f() { return this.registerForm.controls; }

  getChildren(children) {
    this.childrenTOStameent = children
  }
  getChildrenphoto(children){
    this.childrenphoto = children
  }
  
  selectedFiles: File[] = [];

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
        this.selectedFiles = [];
        for (let i = 0; i < files.length; i++) {
            this.selectedFiles.push(files[i]);
        }
        // Add this line to manually set the file input
        this.fileInput = { nativeElement: event.target };
    }
}



  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }
// compte rendue
  onSubmit() {
    // this.registerForm.value.enfant = this.childrenTOStameent._id
    this.registerForm.value.enfant = this.childrenTOStameent._id
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    if (!this.registerForm.value.salt) {
        this.registerForm.value.salt = false;
    }

    this.statementService.createStatement(this.registerForm.value).subscribe((data) => {
      
      this.router.navigate(['puericulteur/liste-enfants/details', this.childrenTOStameent._id])
      this.modalService.dismissAll();
    });   
}
/// add photo enfant 


addPictureChildren(id){
  let modalRef
  modalRef =this.modalService.open(PopupAddPhotoChildrenComponent);
  modalRef.componentInstance.childrenPhoto= id;
  localStorage.setItem("idChildToAddPhoto",id)
  }


goToDetailsEnfant(id){
  this.router.navigate(['puericulteur/liste-enfants/details', id])

}
reset(){
  this.submitted = false;
        this.registerForm.reset();
}
open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
open2(content2) {
  this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
goToList(){
  this.router.navigate(['/puericulteur/liste-enfants/Liste-photos/'+  this.id4])
  }
  getSafeUrl(file: File): any {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }




  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
  }


  addChildrenStatment(id){
    let modalRef
    modalRef =this.modalService.open(PopupAddChildrenStatmentComponent);
    modalRef.componentInstance.childrenTOStameent= id;
    }
  getLustStatement(id){
    let currentDate = new Date().toISOString().split('T')[0];
    this.statementService.getLastStatementChildrenn(id).subscribe(
      (data) => {
      if(data){
      var dateStatementActual = new Date(data.action.date).toISOString().split('T')[0]
      }
      if (currentDate != dateStatementActual || !data) {
          this.addChildrenStatment(id)
          //this.router.navigate(['/Enseignant/Liste-enfants/details/'+  id])
      } else {
          this.iziToast.show({
            message:"Vous avez ajouté un compte rendu pour cette date.",
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
      }
      
      })
  }
}



